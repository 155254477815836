import '../index.css'
import * as React from 'react'
import { useContext } from 'react'
import { Button, Drawer, Image, Space, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { CloseOutlined } from '@ant-design/icons'
import { InfoCxt } from '../../../App'
import type { TakenDataType } from './taken'
import { KNOW_ALLERGIES } from './taken'

const { Text } = Typography

/* -------------------------------- drawer fc ------------------------------- */

type TakenDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  info: TakenDataType | null
}

const TakenDrawer: React.FC<TakenDrawerProps> = ({ isOpen, closeDrawer, info }) => {
  // console.log(11, info)
  const { showComingSoon } = useContext(InfoCxt)

  return (
    <>
      <Drawer
        title='View'
        placement='right'
        closable={false}
        styles={{body: { padding: 0 }}}
        onClose={closeDrawer}
        open={isOpen}
        extra={<CloseOutlined onClick={closeDrawer} className='icon_style' />}
        footer={
          <Button
            block
            onClick={() => {
              console.log(11, 'drawer', info?.id)
              showComingSoon()
            }}>
            Edit
          </Button>
        }>
        {info ? (
          <>
            <div className='drawer_container'>
              <div className='draw_body_card'>
                <div className='dosage_card_medication_name'>
                  <Text>{info.medicationName}</Text>
                  <Image preview={false} width='48px' src='/assets/icon/medication-icon.png' />
                </div>
              </div>
              <div className='draw_body_card'>
                <div className='dosage_grid'>
                  <Text strong>Date</Text>
                  <Text strong>Time</Text>
                  <Text>{dayjs(info.recordDateTime.slice(0, 10), 'YYYY-MM-DD').format('DD MMM YYYY') || '-'}</Text>
                  <Text>{dayjs(info.recordDateTime.slice(11), 'HH:mm:ss').format('h:mm a') || '-'}</Text>
                </div>
              </div>
              <div className='draw_body_card'>
                <div className='dosage_grid'>
                  <Text strong>Dosage</Text>
                  <Text strong>Quantity</Text>
                  <Text>{info.dose || '-'}</Text>
                  <Text>{info.takenDosage || '-'}</Text>
                </div>
              </div>
              <div className='draw_body_card'>
                <div className='dosage_flex'>
                  <Text strong>Allergies</Text>
                  <Space size={[0, 8]} wrap>
                    {info.reportedSideEffects.length === 0 && <Tag color='green'>None</Tag>}
                    {info.reportedSideEffects.map((item) => {
                      return (
                        <Tag color={KNOW_ALLERGIES.indexOf(item) > -1 ? 'orange' : 'blue'} key={item}>
                          {item}
                        </Tag>
                      )
                    })}
                  </Space>
                </div>
              </div>
              <div className='draw_body_card'>
                <div className='dosage_grid'>
                  <Text strong>Medication Plan</Text>
                  <Text strong>Creator</Text>
                  <div>
                    <Tag color={Math.random() < 0.5 ? 'purple' : 'red'}>{Math.random() < 0.5 ? 'Yes' : 'No'}</Tag>
                  </div>
                  <Text>Patient</Text>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </Drawer>
    </>
  )
}

export default TakenDrawer
