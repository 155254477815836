import '../index.css'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { Button, Pagination, Space, Table, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { PageProps } from '../'
import { Loading } from '../../../components/Loading/defaultLoading'
import {
  PaddingBox,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'
import PlanDrawer from './planDrawer'

const QUERY_PLAN = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        medicationPlan {
          id
          drugName
          dose
          dosage
          type
          creationDate
          perDay
          schedules
          reminder
          reminderTime
        }
      }
    }
  }
`

interface PlanDataType {
  id: string
  drugName: string
  dose: string
  dosage: number
  type: string
  creationDate: string
  perDay: number
  schedules: string[]
  reminder: boolean
  reminderTime: string[]
  //
  creator?: string
}

interface SpecialPlanDataType {
  drugName: string
  dose: string
  type: string
  allSchedules: string[]
  creator?: string
  //
  plans: {
    id: string
    creationDate: string
    dosage: number
    perDay: number
    schedules: string[]
    reminder: boolean
    reminderTime: string[]
  }[]
}

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<SpecialPlanDataType> = [
  {
    title: 'Medication Name',
    dataIndex: 'drugName',
    width: '300px',
    sorter: (a, b) => a.drugName.localeCompare(b.drugName),
  },
  {
    title: 'Dosage',
    dataIndex: 'dose',
    // width: '300px',
    sorter: (a, b) => a.dose.localeCompare(b.dose),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    // width: '300px',
    sorter: (a, b) => a.type.localeCompare(b.type),
  },

  {
    title: 'Creator',
    render: () => 'Patient',
  },
  // {
  //   title: 'Custom',
  //   render: () => 'Yes',
  // },
]

/* -------------------- warning: special api data process ------------------- */

const specialProcessPlanData = (data: PlanDataType[]): Map<string, SpecialPlanDataType> => {
  const cup = new Map<string, SpecialPlanDataType>()

  data.map((item) => {
    if (!cup.has(item.drugName)) {
      cup.set(item.drugName, {
        drugName: item.drugName,
        dose: item.dose,
        type: item.type,
        // creator?: string,
        allSchedules: [],
        plans: [],
      })
    }

    cup.get(item.drugName)?.plans.push({
      id: item.id,
      creationDate: item.creationDate,
      dosage: item.dosage,
      perDay: item.perDay,
      schedules: item.schedules,
      reminder: item.reminder,
      reminderTime: item.reminderTime,
    })

    const sc = item.schedules.length === 7 ? ['Daily'] : item.schedules
    const all: string[] = cup.get(item.drugName)?.allSchedules || []
    cup.get(item.drugName)?.allSchedules.push(...sc.filter((item) => all.indexOf(item) === -1))
  })

  return cup
}

/* -------------------------------- react fc -------------------------------- */

const Plan: React.FC<PageProps> = ({ userName, loginToken }) => {
  const navigate = useNavigate()
  const { loading, data: queryData } = useQuery(QUERY_PLAN, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })

  console.log('queryData:', queryData)
  const [drawer, setDrawer] = useState(false)
  const [medicationName, setMedicationName] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const planData = queryData?.physician?.queryPatient?.medicationPlan || []
  console.log('planData:', planData)
  const specialPlanData: Map<string, SpecialPlanDataType> = planData ? specialProcessPlanData(planData) : new Map()
  console.log('specialPlanData:', specialPlanData)

  const closeDrawer = useCallback(() => {
    setDrawer(false)
  }, [setDrawer])

  const onClickView = useCallback(
    (name: string) => {
      setDrawer(true)
      setMedicationName(name)
    },
    [setDrawer, setMedicationName]
  )

  return (
    <>
      <PlanDrawer isOpen={drawer} closeDrawer={closeDrawer} medicationName={medicationName} info={specialPlanData} />
      {loading ? (
        <Loading />
      ) : (
        <>
          {planData && (
            <>
              <TabHeader border>
                <PageTitle text='Medications' />
                {isMobile ? (
                  <Button
                    type='primary'
                    ghost
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('./select/plan')
                    }}
                  />
                ) : (
                  <Button
                    type='primary'
                    ghost
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('./select/plan')
                    }}>
                    Add
                  </Button>
                )}
              </TabHeader>
              <PaddingBox>
                <PageSubtitle text='Medication Plan' />
                <TableBox>
                  <Table
                    columns={[
                      ...columns,
                      {
                        title: 'Action',
                        width: '116px',
                        render: (_, { drugName }) => (
                          <Space size='middle'>
                            <Tooltip title='View'>
                              <Button
                                type='primary'
                                ghost
                                icon={<EyeOutlined />}
                                onClick={() => onClickView(drugName)}
                              />
                            </Tooltip>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={Array.from(specialPlanData.values())}
                    rowKey={(record) => record.drugName}
                    pagination={{
                      position: ['none'],
                      current: currentPage,
                    }}
                  />
                </TableBox>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <TablePaginationBox>
                    <Pagination
                      current={currentPage}
                      total={Array.from(specialPlanData.values()).length}
                      pageSize={10}
                      showLessItems={isMobile}
                      showSizeChanger={true}
                      onChange={(page) => setCurrentPage(page)}
                    />
                  </TablePaginationBox>
                </div>
              </PaddingBox>
            </>
          )}
        </>
      )}
    </>
  )
}

export type { SpecialPlanDataType }
export default Plan
