import './index.css'
import { FC, useContext, useMemo, useState } from 'react'
import { Avatar, Badge, ConfigProvider, Divider, Layout, Menu, Typography } from 'antd'
import { BrowserView, MobileView } from 'react-device-detect'
import { Link } from 'react-router-dom'
import {
  BellOutlined,
  HomeOutlined,
  LockOutlined,
  LogoutOutlined,
  MessageOutlined,
  RightOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { InfoCxt } from '../../App'
import SearchBar from '../Header/components/searchBar'

const { Sider } = Layout
const { Text } = Typography

export const MENU_KEYS = ['dashboard', 'patient', 'chat']

interface PageSiderProps {
  selectedKey: string
  showProfile?: boolean
  removeCookie?: (name: 'loginToken') => void
  collapsed: boolean
}

export const PageSider: FC<PageSiderProps> = ({ showProfile, selectedKey, removeCookie }: PageSiderProps) => {
  const infoContext = useContext(InfoCxt)
  const { showComingSoon, firstName, avatar } = infoContext
  const notificationCount = 21
  const [collapsed, setCollapsed] = useState(true)

  const handleMouseEnter = () => setCollapsed(false)
  const handleMouseLeave = () => setCollapsed(true)

  const menuItems = useMemo(
    () => [
      {
        key: 'dashboard',
        icon: <HomeOutlined />,
        label: (
          <Link style={{ display: 'inline-block' }} to='/'>
            <BrowserView>
              <span style={{ fontSize: '12px' }}>Home</span>
            </BrowserView>
            <MobileView>
              <span style={{ fontSize: '12px' }}>Home</span>
              <RightOutlined style={{ position: 'absolute', right: 16, top: 12 }} />
            </MobileView>
          </Link>
        ),
      },
      {
        key: 'patient',
        icon: <UserOutlined />,
        label: (
          <Link style={{ display: 'inline-block' }} to='/patient'>
            <BrowserView>
              <span style={{ fontSize: '12px' }}>View Patient</span>
            </BrowserView>
            <MobileView>
              <span style={{ fontSize: '12px' }}>View Patient</span>
              <RightOutlined style={{ position: 'absolute', right: 16, top: 12 }} />
            </MobileView>
          </Link>
        ),
      },
      {
        key: 'chat',
        icon: <MessageOutlined />,
        label: (
          <Link style={{ display: 'inline-block' }} to='/chat'>
            <BrowserView>
              <span style={{ fontSize: '12px' }}>Chat</span>
            </BrowserView>
            <MobileView>
              <span style={{ fontSize: '12px' }}>Chat</span>
              <RightOutlined style={{ position: 'absolute', right: 16, top: 12 }} />
            </MobileView>
          </Link>
        ),
      },
    ],
    []
  )

  const profileMenuItems = useMemo(
    () => [
      {
        key: 'profile',
        icon: <UserOutlined />,
        label: (
          <Link style={{ display: 'inline-block' }} to='/settings/profile'>
            <span>Profile</span>
          </Link>
        ),
      },
      {
        key: 'notification',
        icon: <BellOutlined />,
        label: (
          <span>
            <span>Notification</span>
            <div style={{ display: 'inline-block' }}>
              <div className='notification-badge'>
                <Badge count={notificationCount} />
              </div>
            </div>
          </span>
        ),
      },
      {
        key: 'security',
        icon: <LockOutlined />,
        label: (
          <Link style={{ display: 'inline-block' }} to='/settings/security'>
            <span>Security</span>
          </Link>
        ),
      },
    ],
    []
  )

  return (
    <>
      {/* PC version */}
      <BrowserView>
        <Sider
          collapsed={collapsed}
          style={{ width: collapsed ? 80 : 264, height: '100vh', transition: 'width 0.3s' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div className='page-sider'>
            <a
              href='/'
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              <img src='/assets/icon/empower-icon.png' alt='logo' width={28} height={28} />
              {!collapsed && (
                <Text
                  style={{
                    color: '#fff',
                    fontSize: 14,
                    fontWeight: 600,
                    padding: '0 8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  className='sider-title'>
                  Empower
                </Text>
              )}
            </a>
            <Menu selectedKeys={selectedKey ? [selectedKey] : ['dashboard']} items={menuItems} />
          </div>
        </Sider>
      </BrowserView>
      {/* Mobile version */}
      <MobileView>
        {!collapsed && (
          <>
            <div
              style={{
                width: '100%',
                padding: '12px 16px',
                backgroundColor: '#FFFFFF',
              }}>
              <SearchBar />
            </div>
            <Divider style={{ margin: 0 }} />
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    colorPrimary: '#3C4350',
                    colorText: '#3C4350',
                    colorTextLightSolid: '#3C4350',
                    colorBgContainer: '#FFFFFF',
                    itemBg: '#FFFFFF',
                    groupTitleColor: '#3C4350',
                    itemSelectedBg: '#FAFEFB',
                    itemSelectedColor: '#64BE79',
                  },
                },
              }}>
              <Menu selectedKeys={selectedKey ? [selectedKey] : ['dashboard']} items={menuItems} />
            </ConfigProvider>
          </>
        )}
        {showProfile && (
          <>
            <div
              style={{
                width: '100%',
                padding: '12px 16px',
                backgroundColor: '#FFFFFF',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <div>
                <Text
                  style={{
                    fontWeight: 600,
                  }}>
                  {`Hello, ${firstName}!`}
                </Text>
              </div>
              <div>
                <Avatar size={24} draggable={false} src={avatar} style={{ cursor: 'pointer' }} />
              </div>
            </div>
            <Divider style={{ margin: 0 }} />
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    colorPrimary: '#3C4350',
                    colorText: '#3C4350',
                    colorTextLightSolid: '#3C4350',
                    colorBgContainer: '#FFFFFF',
                    itemBg: '#FFFFFF',
                    groupTitleColor: '#3C4350',
                    itemSelectedBg: '#FAFEFB',
                    itemSelectedColor: '#64BE79',
                  },
                },
              }}>
              <Menu
                selectedKeys={[]}
                items={profileMenuItems}
                onClick={({ key }) => {
                  if (key === 'notification') {
                    showComingSoon()
                  }
                }}
              />
              <Divider style={{ margin: 0 }} />
              <div
                style={{
                  width: '100%',
                  padding: '12px 20px',
                  backgroundColor: '#FFFFFF',
                  display: 'flex',
                  gap: '10px',
                }}>
                <div>
                  <LogoutOutlined />
                </div>
                <div
                  onClick={() => {
                    removeCookie?.('loginToken')
                    setTimeout(() => {
                      window.location.reload()
                    }, 1)
                  }}>
                  Logout
                </div>
              </div>
            </ConfigProvider>
          </>
        )}
      </MobileView>
    </>
  )
}
