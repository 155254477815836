import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Space, Tabs, TabsProps, Typography } from 'antd'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { InfoCxt } from '../../App'
import { Profile } from './Profile'
import { Security } from './Security'
import styles from './Settings.module.css'

const { Title, Text } = Typography

const Settings: React.FC = () => {
  const location = useLocation()
  const { showComingSoon } = useContext(InfoCxt)
  const navigate = useNavigate()
  const params = useParams()
  const tab = params.tab ? params.tab : 'profile'
  const [activeKey, setActiveKey] = useState(tab)
  console.log('tab:', tab)
  const [currentPage, setCurrentPage] = useState(1)
  const [cookies, , removeCookie] = useCookies(['loginToken'])

  const onClick = (key: string, e: any) => {
    setActiveKey(key)
    navigate(`/settings/${key}`)
  }

  useEffect(() => {
    if (tab === 'profile') {
      setActiveKey('profile')
    } else if (tab === 'security') {
      setActiveKey('security')
    }
  }, [tab])

  // use custom children outside of <Tabs> component
  const items: TabsProps['items'] = [
    {
      key: 'profile',
      label: `Profile`,
      children: null,
    },
    {
      key: 'security',
      label: `Security`,
      children: null,
    },
  ]

  return (
    <div className='dash-board-page'>
      <div className='dash-board-page-home' style={{ border: 'none' }}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: 'Settings',
              },
            ]}
          />
          <Title style={{ marginTop: 0, fontSize: 30, marginBottom: 0 }}>Settings</Title>
        </Space>
      </div>
      <div className={styles.settingsPageTabs}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Tabs activeKey={activeKey} items={items} onTabClick={onClick} type='card' />
        </Space>
      </div>
      {activeKey === 'profile' && <Profile />}
      {activeKey === 'security' && <Security />}
    </div>
  )
}

export default Settings
