import React, { useContext, useState } from 'react'
import { Button, Pagination, Space, Table, Tag, Tooltip, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { InfoCxt } from '../../App'
import { Loading } from '../../components/Loading/defaultLoading'
import {
  ContentHeader,
  PaddingBox,
  PageBreadcrumb,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../components/Wrapper'

const { Text } = Typography

const GET_PATIENTS = gql`
  query GetPatients {
    physician {
      patients {
        id
        firstName
        userName
        lastActive
        patientDetail {
          priority
          diabetesType
          steroid
          blevelRisk
          pregnancy
          medicalConditions {
            title
          }
        }
      }
    }
  }
`

export interface PatientItemType {
  id: string
  firstName: string
  userName: string // can be treated as uid
  patientDetail: {
    priority: boolean
    diabetesType: 'TypeI' | 'TypeII' | 'Gestational' | 'Nil'
    steroid: number
    blevelRisk: number
    pregnancy: number
    medicalConditions: {
      title: string
    }[]
  }
  lastActive: string
}

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<PatientItemType> = [
  {
    title: 'Name',
    dataIndex: 'firstName',
    width: '200px',
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Patient UID',
    dataIndex: 'userName',
    sorter: (a, b) => a.userName.localeCompare(b.userName),
  },
  {
    title: 'Priority Queue',
    dataIndex: ['patientDetail', 'priority'],
    width: '180px',
    sorter: (a, b) => Number(a.patientDetail.priority) - Number(b.patientDetail.priority),
    render: (value) => (
      <Tag
        style={{
          fontSize: '12px',
          fontWeight: 400,
          border: `1px solid ${value ? '#7966FF' : '#FF0844'}`,
          background: `${value ? '#FAF9FF' : '#FFFBFC'}`,
        }}>
        <Text
          style={{
            color: value ? '#7966FF' : '#FF0844',
            fontSize: '12px',
            lineHeight: '20px',
          }}>
          {value ? 'Yes' : 'No'}
        </Text>
      </Tag>
    ),
  },
  {
    title: 'Diabetes Type',
    dataIndex: ['patientDetail', 'diabetesType'],
    sorter: (a, b) => String(a.patientDetail.diabetesType).localeCompare(String(b.patientDetail.diabetesType)),
  },
  {
    title: 'Medical Condition',
    dataIndex: ['patientDetail', 'medicalConditions'],
    width: '180px',
    sorter: (a, b) => a.patientDetail.medicalConditions.length - b.patientDetail.medicalConditions.length,
    render: (value) => value.length,
  },
  {
    title: 'Last Active',
    dataIndex: 'lastActive',
    sorter: (a, b) => a.lastActive.localeCompare(b.lastActive),
    render: (value) => {
      if (value) return dayjs(value.slice(0, 10), 'YYYY-MM-DD').format('DD MMM YYYY')
      return '-'
    },
  },
]

/* -------------------------------- react fc -------------------------------- */

const Patient: React.FC = () => {
  const navigate = useNavigate()
  const { token: loginToken } = useContext(InfoCxt)
  const { loading, data: queryData } = useQuery(GET_PATIENTS, {
    context: { headers: { Authorization: loginToken } },
  })

  const patientsQueryData: PatientItemType[] = queryData?.physician?.patients || []
  const patientData = patientsQueryData

  const [currentPage, setCurrentPage] = useState(1)

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='dash-board-page'>
          <ContentHeader white={false} border>
            <PageBreadcrumb path={['Home', 'Select Patient']} />
            <TabHeader padding={false}>
              <PageTitle text='Select Patient' />
            </TabHeader>
          </ContentHeader>
          <PaddingBox>
            <PageSubtitle text='Patient List' />
            <TableBox>
              <Table
                columns={[
                  ...columns,
                  {
                    title: 'Action',
                    render: (_, { userName }) => (
                      <Space size='middle'>
                        <Tooltip title='View'>
                          <Button
                            type='primary'
                            ghost
                            icon={<EyeOutlined />}
                            onClick={() => navigate(`/patient/${userName}`)}
                          />
                        </Tooltip>
                      </Space>
                    ),
                  },
                ]}
                dataSource={patientData}
                rowKey={({ id, firstName, userName }) => `${id}-${firstName}-${userName}`}
                pagination={{
                  position: ['none'],
                  current: currentPage,
                }}
              />
            </TableBox>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <TablePaginationBox>
                <Pagination
                  current={currentPage}
                  total={patientData.length}
                  pageSize={10}
                  showLessItems={isMobile}
                  showSizeChanger={true}
                  onChange={(page) => setCurrentPage(page)}
                />
              </TablePaginationBox>
            </div>
          </PaddingBox>
        </div>
      )}
    </>
  )
}

export default Patient
