import '../index.css'
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { Button, Drawer, Image, message, Popconfirm, Tag, Typography } from 'antd'
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { PatientCtx } from '../'
import { InfoCxt } from '../../../App'
import type { ResourcesProcessedDataType } from './resource'

const { Text } = Typography

const MUTATION_CANCEL_RESOURCE = gql`
  mutation ($patientID: String!, $resourceID: String!) {
    resourceMutations {
      cancelRecommendResource(patientId: $patientID, resourceId: $resourceID) {
        status
        message {
          title
          icon
          content
        }
      }
    }
  }
`

/* -------------------------------- drawer fc ------------------------------- */

type ResourceDrawerProps = {
  isOpen: boolean
  closeDrawer: (doRefetch?: boolean) => void
  resourceID: string
  info: Map<string, ResourcesProcessedDataType>
}

const ResourceDrawer: React.FC<ResourceDrawerProps> = ({ isOpen, closeDrawer, resourceID, info }) => {
  const { token: loginToken } = useContext(InfoCxt)
  const { id: patientID } = useContext(PatientCtx)
  const [messageApi, contextHolder] = message.useMessage()

  // cancel one resource
  const [cancelResource, { loading: cancelLoading, data: callbackData }] = useMutation(MUTATION_CANCEL_RESOURCE, {
    variables: { patientID: patientID, resourceID: resourceID },
    context: { headers: { Authorization: loginToken } },
  })

  useEffect(() => {
    const st = callbackData?.resourceMutations?.cancelRecommendResource
    if (st?.status) {
      messageApi.success('Resource successfully deleted!')
      setTimeout(() => {
        closeDrawer(true)
      }, 1000)
    } else if (st?.status === false) {
      messageApi.error(`${st?.message?.title}. ${st?.message?.content}`)
    }
  }, [callbackData, messageApi, closeDrawer])

  const item = info.get(resourceID)

  return (
    <>
      {contextHolder}
      <Drawer
        title='View'
        placement='right'
        closable={false}
        styles={{ body: { padding: 0 } }}
        onClose={() => closeDrawer()}
        open={isOpen}
        extra={<CloseOutlined onClick={() => closeDrawer()} className='icon_style' />}
        footer={
          <div className='dual_button_grid'>
            <Popconfirm
              disabled={item?.markType === 'Saved'}
              style={{ width: '224px' }}
              icon={<ExclamationCircleFilled style={{ color: '#FF5500' }} />}
              placement='bottomLeft'
              title={<div>{`Are you sure you want to delete this item?`}</div>}
              onConfirm={() => {
                cancelResource()
              }}
              okText='Yes'
              cancelText='No'>
              <Button block danger loading={cancelLoading} disabled={item?.markType === 'Saved'}>
                Delete
              </Button>
            </Popconfirm>
            <Button block type='primary' href={item?.externalUrl || ''} target='_blank' rel='noopener noreferrer'>
              Open URL
            </Button>
          </div>
        }>
        <div className='drawer_container'>
          <div className='draw_body_card'>
            <div className='dosage_flex'>
              <div className='dosage_flex' style={{ gap: '8px' }}>
                <Tag color='blue'>{item?.type || '-'}</Tag>
                <Text type='secondary'>{item?.source || '-'}</Text>
                <Text strong style={{ fontSize: '16px' }}>
                  {item?.title || '-'}
                </Text>
              </div>
              <Image style={{ borderRadius: '5px' }} src={`data:image/jpeg;base64,${item?.image}`} />
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_grid'>
              <Text strong>Category</Text>
              <Text strong>Sub Category</Text>
              <Text>{item?.category || '-'}</Text>
              <Text>{item?.subCategory || '-'}</Text>
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_flex'>
              <Text strong>Assigned / Saved</Text>
              <Text>{item?.markType || '-'}</Text>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default ResourceDrawer
