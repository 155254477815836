import React, { PropsWithChildren } from 'react'
import { Breadcrumb, Typography } from 'antd'
import { isMobile } from 'react-device-detect'

const { Title } = Typography

/* ------------------------------- components ------------------------------- */
interface PageBreadcrumbProps {
  path: string[]
}
export const PageBreadcrumb: React.FC<PageBreadcrumbProps> = ({ path }) => {
  return (
    <>
      {isMobile ? null : (
        <div>
          <Breadcrumb
            items={path.map((v) => ({
              title: v,
            }))}
          />
        </div>
      )}
    </>
  )
}

interface PageTitleProps {
  text: string
}
export const PageTitle: React.FC<PageTitleProps> = ({ text }) => {
  return <Title level={isMobile ? 4 : 2} style={{ margin: 0, padding: 0, fontWeight: 600 }}>{`${text}`}</Title>
}

interface PageSubtitleProps {
  text: string
  padding?: boolean
}
export const PageSubtitle: React.FC<PageSubtitleProps> = ({ text, padding = true }) => {
  return (
    <Title
      level={5}
      type='success'
      style={{ margin: 0, padding: padding ? '0 0 8px 0' : '0', fontWeight: 600 }}>{`${text}`}</Title>
  )
}

/* ----------------------------------- fc ----------------------------------- */

export const ContentHeader: React.FC<
  PropsWithChildren & {
    white?: boolean
    border?: boolean
  }
> = ({ children, white = true, border = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '16px' : '24px',
        gap: 12,
        backgroundColor: white ? '#FFFFFF' : '#F5F5F5',
        borderBottom: border ? '1px solid var(--colorSplit, rgba(33, 41, 58, 0.06))' : '0px',
      }}>
      {children}
    </div>
  )
}

export const TabHeader: React.FC<
  PropsWithChildren & {
    border?: boolean
    padding?: boolean
  }
> = ({ children, border = false, padding = true }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: padding ? (isMobile ? '16px' : '24px') : '0',
        borderBottom: border ? '1px solid rgba(33, 41, 58, 0.06)' : '0px',
      }}>
      {children}
    </div>
  )
}

export const PaddingBox: React.FC<
  PropsWithChildren & {
    white?: boolean
  }
> = ({ children, white = false }) => {
  return (
    <div
      style={{
        padding: isMobile ? '20px 16px 30px 16px' : '20px 24px 30px 24px',
        backgroundColor: white ? '#FFFFFF' : '#F5F5F5',
      }}>
      {children}
    </div>
  )
}

export const TableBox: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      style={{
        width: '100%',
        borderRadius: '10px',
        overflowX: 'scroll',
        overflowY: 'hidden',
      }}>
      <div
        style={{
          width: '100%',
          minWidth: '1200px',
          backgroundColor: '#ffffff',
        }}>
        {children}
      </div>
    </div>
  )
}

export const TablePaginationBox: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px 0',
      }}>
      {children}
    </div>
  )
}
