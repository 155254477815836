import './index.css'
import * as React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import { InfoCxt } from '../../App'
import { Loading } from '../../components/Loading/defaultLoading'
import { ContentHeader, PaddingBox, PageBreadcrumb, PageTitle, TabHeader } from '../../components/Wrapper'
import TabMenu from './components/menu'
import Panel from './components/panel'
import PanelEditAllergies from './components/panelEditAllergies'
import PanelEditMedical from './components/panelEditMedical'
import PlanSelect from './components/planSelect'
import ResourceAssignContainer from './components/resourceSelect'
import TakenAddContainer from './components/takenSelect'

const QUERY_PATIENT_ID = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        id
        userName
      }
    }
  }
`

interface PatientInfo {
  id: string
  userName: string
}

const PatientCtx = createContext<PatientInfo>({
  id: '',
  userName: '',
})

// Note: default show last 7 days
export const defaultStart = dayjs().subtract(6, 'day').format('YYYY-MM-DD')
export const defaultEnd = dayjs().format('YYYY-MM-DD')

export interface PageProps {
  userName: string
  loginToken: string
}

const ViewPatient: React.FC = () => {
  const { token: loginToken } = useContext(InfoCxt)
  const { patientId: userName, controlName, controlType } = useParams()

  const [panelReady, setPanelReady] = useState(false)
  const [tabReady, setTabReady] = useState(false)

  // query patient ID
  const [patientCtxInfo, setPatientCtxInfo] = useState<PatientInfo>({
    id: '',
    userName: '',
  })
  const [getPatientInfo, { data: queryData }] = useLazyQuery(QUERY_PATIENT_ID)

  useEffect(() => {
    if (userName && loginToken) {
      // console.log(11, userName, loginToken)
      getPatientInfo({
        variables: { username: userName },
        context: { headers: { Authorization: loginToken } },
      })
    }
  }, [userName, loginToken, getPatientInfo])

  useEffect(() => {
    const info = queryData?.physician?.queryPatient
    if (info) {
      // console.log(11, info)
      setPatientCtxInfo({
        id: info?.id || 'null',
        userName: info?.userName || 'null',
      })
    }
  }, [queryData])

  return (
    <>
      <PatientCtx.Provider value={patientCtxInfo}>
        {userName && loginToken && (
          <>
            <div>
              {controlName ? (
                <>
                  {controlName === 'select' && (
                    <>
                      {controlType === 'plan' && (
                        <div style={{ backgroundColor: '#f5f5f5' }}>
                          <PlanSelect />
                        </div>
                      )}
                      {controlType === 'taken' && (
                        <div style={{ backgroundColor: '#f5f5f5' }}>
                          <TakenAddContainer />
                        </div>
                      )}
                      {controlType === 'resource' && (
                        <div style={{ backgroundColor: '#f5f5f5' }}>
                          <ResourceAssignContainer />
                        </div>
                      )}
                    </>
                  )}
                  {controlName === 'edit' && (
                    <>
                      {controlType === 'allergies' && (
                        <div style={{ backgroundColor: '#f5f5f5' }}>
                          <PanelEditAllergies />
                        </div>
                      )}
                      {controlType === 'medical_conditions' && (
                        <div style={{ backgroundColor: '#f5f5f5' }}>
                          <PanelEditMedical />
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div style={{ display: panelReady && tabReady ? 'none' : 'block' }}>
                    <Loading />
                  </div>
                  <div style={{ display: panelReady && tabReady ? 'block' : 'none' }}>
                    <ContentHeader>
                      <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient']} />
                      <TabHeader padding={false}>
                        <PageTitle text='View Patient' />
                      </TabHeader>
                    </ContentHeader>
                    <PaddingBox white>
                      <Panel userName={userName} loginToken={loginToken} setReady={setPanelReady} />
                    </PaddingBox>
                    <TabMenu userName={userName} loginToken={loginToken} setReady={setTabReady} />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </PatientCtx.Provider>
    </>
  )
}

export default ViewPatient
export { PatientCtx }
