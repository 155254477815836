import '../index.css'
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { Button, Divider, Form, message, Select, Typography } from 'antd'
import { isMobile } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { InfoCxt } from '../../../App'
import { ContentHeader, PageBreadcrumb, PageSubtitle, PageTitle, TabHeader } from '../../../components/Wrapper'

const { Text } = Typography

const QUERY_MEDICAL_CONDITIONS = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        patientDetail {
          diabetesType
          insulinUsage
          steroid
          blevelRisk
          bloodPressure
          pregnancy
        }
      }
    }
  }
`
const MUTATION_MEDICAL_CONDITIONS_UPDATE = gql`
  mutation (
    $username: String!
    $blevelRisk: BLevelRiskType
    $bloodPressure: BloodPressureMC
    $diabetesType: DiabeticType
    $insulinUsage: InsulinUsage
    $pregnancy: Boolean
    $steroid: SteroidType
  ) {
    profileMutations {
      updateMedicalConditions(
        patientUsername: $username
        blevelRisk: $blevelRisk
        bloodPressure: $bloodPressure
        diabetesType: $diabetesType
        insulinUsage: $insulinUsage
        pregnancy: $pregnancy
        steroid: $steroid
      ) {
        status
        message {
          title
          icon
          content
        }
      }
    }
  }
`

type MedicationDataType = {
  diabetesType: string
  insulinUsage: string
  steroid: string
  blevelRisk: string
  bloodPressure: string
  pregnancy: boolean
}

/* --------------------------------- form fc -------------------------------- */

type MedicationFormDataType = MedicationDataType

const PanelEditMedical: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<MedicationFormDataType>()

  const { token: loginToken } = useContext(InfoCxt)
  const { patientId: userName } = useParams()
  const navigate = useNavigate()

  const { loading, data: queryData } = useQuery(QUERY_MEDICAL_CONDITIONS, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })

  useEffect(() => {
    const data: MedicationDataType = queryData?.physician?.queryPatient?.patientDetail || {}
    if (data) {
      form.setFieldsValue(data)
    }
  }, [queryData, form])

  const [updateMedicalConditions, { data: updateData, loading: updateLoading }] = useMutation(
    MUTATION_MEDICAL_CONDITIONS_UPDATE,
    {
      context: { headers: { Authorization: loginToken } },
    }
  )

  useEffect(() => {
    if (updateData?.profileMutations?.updateMedicalConditions?.status) {
      messageApi.success('Medical condition successfully updated!')
      setTimeout(() => {
        navigate(`/patient/${userName}`)
      }, 1500)
    }
  }, [updateData, navigate, userName, messageApi])

  const onSave = () => {
    form
      .validateFields()
      .then(() => {
        const queryData = form.getFieldsValue()
        updateMedicalConditions({
          variables: {
            username: userName,
            ...queryData,
          },
        })
      })
      .catch(() => {
        // console.log(44, e)
      })
  }

  return (
    <>
      {contextHolder}
      {!loading && (
        <>
          <ContentHeader white={false}>
            <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient', 'Edit Medical Condition']} />
            <TabHeader padding={false}>
              <PageTitle text='Edit Medical Condition' />
            </TabHeader>
          </ContentHeader>
          <div className='full_page_form_container'>
            <Form name='allergies_add' autoComplete='off' form={form} component={false}>
              {/* part */}
              <PageSubtitle text='Medical Condition List' padding={false} />
              {/* item */}
              <div className='form_item'>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isMobile ? 16 : 24,
                  }}>
                  <div
                    className='vertical_flex'
                    style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 12px)' }}>
                    <Text strong>Diabetes Condition</Text>
                    <Text type='secondary'>Specify the type of diabetes the patient has.</Text>
                  </div>
                  <Form.Item
                    name='diabetesType'
                    rules={[{ required: true, message: 'Please select' }]}
                    hasFeedback
                    style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 12px)' }}>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      options={[
                        {
                          label: 'None',
                          value: 'Nil',
                        },
                        {
                          label: 'Type 1',
                          value: 'TypeI',
                        },
                        {
                          label: 'Type 2',
                          value: 'TypeII',
                        },
                        {
                          label: 'Gestational',
                          value: 'Gestational',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <Divider style={{ margin: 0 }} />
              </div>
              {/* item */}
              <div className='form_item'>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isMobile ? 16 : 24,
                  }}>
                  <div
                    className='vertical_flex'
                    style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 12px)' }}>
                    <Text strong>Insulin Usage</Text>
                    <Text type='secondary'>Select if the patient is using insulin therapy.</Text>
                  </div>
                  <Form.Item
                    name='insulinUsage'
                    rules={[{ required: true, message: 'Please select' }]}
                    hasFeedback
                    style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 12px)' }}>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      options={[
                        {
                          label: 'None',
                          value: 'Nil',
                        },
                        {
                          label: 'Insulin – Newly Started',
                          value: 'NewlyStarted',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <Divider style={{ margin: 0 }} />
              </div>
              {/* item */}
              <div className='form_item'>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isMobile ? 16 : 24,
                  }}>
                  <div
                    className='vertical_flex'
                    style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 12px)' }}>
                    <Text strong>Blood Glucose Condition</Text>
                    <Text type='secondary'>{`Select the patient's blood glucose condition.`}</Text>
                  </div>
                  <Form.Item
                    name='blevelRisk'
                    rules={[{ required: true, message: 'Please select' }]}
                    hasFeedback
                    style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 12px)' }}>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      options={[
                        {
                          label: 'None',
                          value: 'Nil',
                        },
                        {
                          label: 'Hyperglycaemia',
                          value: 'Hyperglycemia',
                        },
                        {
                          label: 'Hypoglycaemia',
                          value: 'Hypoglycemia',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <Divider style={{ margin: 0 }} />
              </div>
              {/* item */}
              <div className='form_item'>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isMobile ? 16 : 24,
                  }}>
                  <div
                    className='vertical_flex'
                    style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 12px)' }}>
                    <Text strong>Blood Pressure Condition</Text>
                    <Text type='secondary'>{`Select the patient's blood pressure condition.`}</Text>
                  </div>
                  <Form.Item
                    name='bloodPressure'
                    rules={[{ required: true, message: 'Please select' }]}
                    hasFeedback
                    style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 12px)' }}>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      options={[
                        {
                          label: 'Normal',
                          value: 'Normal',
                        },
                        {
                          label: 'High, Medicated',
                          value: 'HighAndMedicated',
                        },
                        {
                          label: 'High, Non-Medicated',
                          value: 'HighAndNonMedicated',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <Divider style={{ margin: 0 }} />
              </div>
              {/* item */}
              <div className='form_item'>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isMobile ? 16 : 24,
                  }}>
                  <div
                    className='vertical_flex'
                    style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 12px)' }}>
                    <Text strong>Steroid Usage</Text>
                    <Text type='secondary'>{`Select the patient's steroid usage.`}</Text>
                  </div>
                  <Form.Item
                    name='steroid'
                    rules={[{ required: true, message: 'Please select' }]}
                    hasFeedback
                    style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 12px)' }}>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      options={[
                        {
                          label: 'None',
                          value: 'Nil',
                        },
                        {
                          label: 'Induced',
                          value: 'SteroidInduced',
                        },
                        {
                          label: 'With Chemotherapy',
                          value: 'SteroidWithChemo',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <Divider style={{ margin: 0 }} />
              </div>
              {/* item */}
              <div className='form_item'>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isMobile ? 16 : 24,
                  }}>
                  <div
                    className='vertical_flex'
                    style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 12px)' }}>
                    <Text strong>Pregnancy Status</Text>
                    <Text type='secondary'>{`Select the patient's pregnancy status.`}</Text>
                  </div>
                  <Form.Item
                    name='pregnancy'
                    rules={[{ required: true, message: 'Please select' }]}
                    hasFeedback
                    style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 12px)' }}>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      options={[false, true].map((item) => ({
                        label: item ? 'Yes' : 'Not Applicable',
                        value: item,
                      }))}
                    />
                  </Form.Item>
                </div>
                <Divider style={{ margin: 0 }} />
              </div>
              {/* footer */}
              <div className='form_footer_container'>
                <Text strong>Ready to Submit?</Text>
                <Text type='secondary' style={{ marginTop: '4px' }}>
                  {`Review your filled form details and make sure everything is accurate. Once you're ready, click the 'Save' button to update the medical condition list.`}
                </Text>
                <Divider style={{ margin: '16px 0' }} />
                <div className='right_flex'>
                  <Button
                    style={{ flexBasis: isMobile ? '100%' : 'content' }}
                    type='primary'
                    onClick={onSave}
                    loading={updateLoading}>
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </>
      )}
    </>
  )
}

export default PanelEditMedical
