export function isValidPassword(password: string) {
  // Length check
  if (password.length < 8) {
    return false
  }

  // Uppercase check
  if (!/[A-Z]/.test(password)) {
    return false
  }

  // Lowercase check
  if (!/[a-z]/.test(password)) {
    return false
  }

  // Number check
  if (!/[0-9]/.test(password)) {
    return false
  }

  // Symbol check (checks for any non-alphanumeric character)
  if (!/\W|_/.test(password)) {
    return false
  }

  // If all checks pass, return true
  return true
}
