import '../index.css'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { Button, Pagination, Space, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { CalendarOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { defaultEnd, defaultStart, PageProps } from '../'
import PageDrawer from '../../../components/Drawer/DatePickerDrawer'
import { Loading } from '../../../components/Loading/defaultLoading'
import {
  PaddingBox,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'
import TakenDrawer from './takenDrawer'

const KNOW_ALLERGIES = [
  'Constipation',
  'Skin Rash',
  'Diarrhea',
  'Dizziness',
  'Dry Mouth',
  'Headache',
  'Insomnia',
  'Body Aches',
  'Fever',
  'Others',
]

const QUERY_TAKEN = gql`
  query ($username: String!, $start: String!, $end: String!) {
    physician {
      queryPatient(username: $username) {
        medicationTaken(start: $start, end: $end) {
          dosageType
          dose
          medicationName
          originalDosage
          recordDateTime
          reportedSideEffects
          takenDosage
        }
      }
    }
  }
`

interface TakenDataType {
  id: string
  dosageType: string
  dose: string | null
  medicationName: string
  originalDosage: number
  recordDateTime: string
  reportedSideEffects: string[]
  takenDosage: number
}

/* --------------------------- table column config -------------------------- */

// const columns: ColumnsType<TakenDataType> = [
//   {
//     title: 'Medication Name',
//     dataIndex: 'medicationName',
//     key: 'medicationName',
//     // width: '310px',
//     sorter: (a, b) => a.medicationName.localeCompare(b.medicationName),
//   },
//   {
//     title: 'Date Taken',
//     dataIndex: 'recordDateTime',
//     key: 'medicationName',
//     // width: '130px',
//     sorter: (a, b) => a.recordDateTime.localeCompare(b.recordDateTime),
//     render: (value) => dayjs(value.slice(0, 10), 'YYYY-MM-DD').format('DD MMM YYYY'),
//   },
//   {
//     title: 'Time',
//     dataIndex: 'recordDateTime',
//     key: 'medicationName',
//     // width: '130px',
//     sorter: (a, b) => a.recordDateTime.localeCompare(b.recordDateTime),
//     render: (value) => dayjs(value.slice(11), 'HH:mm:ss').format('h:mm a'),
//   },
//   // {
//   //   title: 'Original Dosage',
//   //   dataIndex: 'originalDosage',
//   //   key: 'medicationInfo',
//   //   width: '250px',
//   //   sorter: (a, b) => a.originalDosage - b.originalDosage,
//   //   render: (_, { dose, originalDosage, dosageType }) => `${dose ? dose + ', ' : ''}${originalDosage} ${dosageType}(s)`,
//   // },
//   // {
//   //   title: 'Taken Dosage',
//   //   dataIndex: 'takenDosage',
//   //   key: 'medicationInfo',
//   //   width: '250px',
//   //   sorter: (a, b) => a.takenDosage - b.takenDosage,
//   //   render: (_, { dose, takenDosage, dosageType }) => `${dose ? dose + ', ' : ''}${takenDosage} ${dosageType}(s)`,
//   // },
//   {
//     title: 'Dosage',
//     dataIndex: 'dose',
//     key: 'dose',
//     // width: '300px',
//     sorter: (a, b) => a.dose?.localeCompare(b.dose || '') || 0,
//     render: (value) => value || '-',
//   },
//   {
//     title: 'Quantity',
//     dataIndex: 'takenDosage',
//     key: 'takenDosage',
//     // width: '300px',
//     sorter: (a, b) => a.takenDosage - b.takenDosage,
//     render: (value) => `${value} Unit(s)`,
//   },
//   {
//     title: 'Allergies',
//     dataIndex: 'reportedSideEffects',
//     key: 'medicationInfo',
//     sorter: (a, b) => a.reportedSideEffects.length - b.reportedSideEffects.length,
//     // render: (_, { reportedSideEffects }) => {
//     //   if (reportedSideEffects.length === 0) return <Tag color='green'>None</Tag>
//     //   return (
//     //     <Space size={[0, 8]} wrap>
//     //       {reportedSideEffects.map((item) => {
//     //         return (
//     //           <Tag color={KNOW_ALLERGIES.indexOf(item) > -1 ? 'orange' : 'blue'} key={item}>
//     //             {item}
//     //           </Tag>
//     //         )
//     //       })}
//     //     </Space>
//     //   )
//     // },
//     render: (value) => (value as string[]).length,
//   },
//   {
//     title: 'Med Plan',
//     // dataIndex: '',
//     // key: '',
//     // width: '300px',
//     // sorter: (a, b) => a.takenDosage - b.takenDosage,
//     render: () => {
//       const v = Math.random() < 0.5 ? 'Yes' : 'No'
//       return <Tag color={v === 'Yes' ? 'purple' : 'red'}>{v}</Tag>
//     },
//   },
//   {
//     title: 'Creator',
//     // dataIndex: '',
//     // key: '',
//     // width: '300px',
//     // sorter: (a, b) => a.takenDosage - b.takenDosage,
//     render: () => 'Patient',
//   },
// ]

const columns: ColumnsType<TakenDataType> = [
  // {
  //   title: 'Medication Name',
  //   dataIndex: 'medicationName',
  //   key: 'medicationName',
  //   // width: '310px',
  //   sorter: (a, b) => a.medicationName.localeCompare(b.medicationName),
  // },
  {
    title: 'Date',
    dataIndex: 'recordDateTime',
    key: 'medicationName',
    // width: '130px',
    sorter: (a, b) => a.recordDateTime.localeCompare(b.recordDateTime),
    render: (value) => dayjs(value.slice(0, 10), 'YYYY-MM-DD').format('DD MMM YYYY'),
  },
  {
    title: 'Time',
    dataIndex: 'recordDateTime',
    key: 'medicationName',
    // width: '130px',
    sorter: (a, b) => a.recordDateTime.localeCompare(b.recordDateTime),
    render: (value) => dayjs(value.slice(11), 'HH:mm:ss').format('h:mm a'),
  },
  // {
  //   title: 'Original Dosage',
  //   dataIndex: 'originalDosage',
  //   key: 'medicationInfo',
  //   width: '250px',
  //   sorter: (a, b) => a.originalDosage - b.originalDosage,
  //   render: (_, { dose, originalDosage, dosageType }) => `${dose ? dose + ', ' : ''}${originalDosage} ${dosageType}(s)`,
  // },
  // {
  //   title: 'Taken Dosage',
  //   dataIndex: 'takenDosage',
  //   key: 'medicationInfo',
  //   width: '250px',
  //   sorter: (a, b) => a.takenDosage - b.takenDosage,
  //   render: (_, { dose, takenDosage, dosageType }) => `${dose ? dose + ', ' : ''}${takenDosage} ${dosageType}(s)`,
  // },
  // {
  //   title: 'Dosage',
  //   dataIndex: 'dose',
  //   key: 'dose',
  //   // width: '300px',
  //   sorter: (a, b) => a.dose?.localeCompare(b.dose || '') || 0,
  //   render: (value) => value || '-',
  // },
  {
    title: 'Medication Taken',
    dataIndex: 'takenDosage',
    key: 'takenDosage',
    // width: '300px',
    sorter: (a, b) => a.takenDosage - b.takenDosage,
    // render: (value) => `${value} Unit(s)`,
  },
  {
    title: 'Allergies',
    dataIndex: 'reportedSideEffects',
    key: 'medicationInfo',
    sorter: (a, b) => a.reportedSideEffects.length - b.reportedSideEffects.length,
    // render: (_, { reportedSideEffects }) => {
    //   if (reportedSideEffects.length === 0) return <Tag color='green'>None</Tag>
    //   return (
    //     <Space size={[0, 8]} wrap>
    //       {reportedSideEffects.map((item) => {
    //         return (
    //           <Tag color={KNOW_ALLERGIES.indexOf(item) > -1 ? 'orange' : 'blue'} key={item}>
    //             {item}
    //           </Tag>
    //         )
    //       })}
    //     </Space>
    //   )
    // },
    render: (value) => (value as string[]).length,
  },
  {
    title: 'Medication Plan',
    // dataIndex: '',
    // key: '',
    // width: '300px',
    // sorter: (a, b) => a.takenDosage - b.takenDosage,
    render: () => {
      const v = Math.random() < 0.5 ? 'Yes' : 'No'
      return <Tag color={v === 'Yes' ? 'purple' : 'red'}>{v}</Tag>
    },
  },
  {
    title: 'Creator',
    // dataIndex: '',
    // key: '',
    // width: '300px',
    // sorter: (a, b) => a.takenDosage - b.takenDosage,
    render: () => 'Patient',
  },
]

/* -------------------------------- react fc -------------------------------- */

const Taken: React.FC<PageProps> = ({ userName, loginToken }) => {
  const navigate = useNavigate()
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs(defaultStart, 'YYYY-MM-DD'),
    dayjs(defaultEnd, 'YYYY-MM-DD'),
  ])
  const [drawer, setDrawer] = useState(false)
  const [infoDrawer, setInfoDrawer] = useState(false)
  const [medicationInfo, setMedicationInfo] = useState<TakenDataType | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const { loading, data: queryData } = useQuery(QUERY_TAKEN, {
    variables: { username: userName, start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD') },
    context: { headers: { Authorization: loginToken } },
  })

  const takenData = queryData?.physician?.queryPatient?.medicationTaken || null

  const showDrawer = useCallback(() => {
    setDrawer(true)
  }, [setDrawer])

  const closeDrawer = useCallback(() => {
    setDrawer(false)
  }, [setDrawer])

  const onClickView = useCallback( 
    (value: TakenDataType) => {
      setInfoDrawer(true)
      setMedicationInfo(value)
    },
    [setInfoDrawer, setMedicationInfo]
  )
  const closeInfoDrawer = useCallback(() => {
    setInfoDrawer(false)
  }, [setInfoDrawer])

  return (
    <>
      <TakenDrawer isOpen={infoDrawer} closeDrawer={closeInfoDrawer} info={medicationInfo} />
      <PageDrawer
        isOpen={drawer}
        closeDrawer={closeDrawer}
        _defaultStart={defaultStart}
        _defaultEnd={defaultEnd}
        _start={dateRange[0]}
        _end={dateRange[1]}
        setDateRange={setDateRange}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {takenData && (
            <>
              <TabHeader border>
                <PageTitle text='Medications' />
                {isMobile ? (
                  <Space>
                    <Button type='primary' onClick={showDrawer} icon={<CalendarOutlined />} />
                    <Button
                      type='primary'
                      ghost
                      icon={<PlusOutlined />}
                      onClick={() => {
                        navigate('./select/taken')
                      }}
                    />
                  </Space>
                ) : (
                  <Space>
                    <Button type='primary' onClick={showDrawer} icon={<CalendarOutlined />}>
                      {`${dateRange[0].format('DD MMM YYYY')} - ${dateRange[1].format('DD MMM YYYY')}`}
                    </Button>
                    <Button
                      type='primary'
                      ghost
                      icon={<PlusOutlined />}
                      onClick={() => {
                        navigate('./select/taken')
                      }}>
                      Add
                    </Button>
                  </Space>
                )}
              </TabHeader>
              <PaddingBox>
                <PageSubtitle text='Medication Taken' />
                <TableBox>
                  <Table
                    columns={[
                      ...columns,
                      {
                        title: 'Action',
                        width: '116px',
                        render: (_, info) => (
                          <Space size='middle'>
                            <Button type='primary' ghost icon={<EyeOutlined />} onClick={() => onClickView(info)}>
                              View
                            </Button>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={takenData}
                    rowKey={(record) => `${JSON.stringify(record)}`}
                    pagination={{
                      position: ['none'],
                      current: currentPage,
                    }}
                  />
                </TableBox>
                <TablePaginationBox>
                  <Pagination
                    current={currentPage}
                    total={takenData.length}
                    pageSize={10}
                    showLessItems={isMobile}
                    showSizeChanger={false}
                    onChange={(page) => setCurrentPage(page)}
                  />
                </TablePaginationBox>
              </PaddingBox>
            </>
          )}
        </>
      )}
    </>
  )
}

export type { TakenDataType }
export { KNOW_ALLERGIES }
export default Taken
