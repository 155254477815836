import './App.css'
import * as React from 'react'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { App as AntdApp, Button, ConfigProvider, Layout, Space } from 'antd'
import { NotificationInstance } from 'antd/es/notification/interface'
import { CookiesProvider, useCookies } from 'react-cookie'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Talk from 'talkjs'
import { MessageOutlined } from '@ant-design/icons'
import { ApolloClient, ApolloError, ApolloProvider, gql, InMemoryCache, useQuery } from '@apollo/client'
import TableEmpty from './components/Empty/TableEmpty'
import { PageFooter } from './components/Footer'
import { PageHeader } from './components/Header'
import { MENU_KEYS, PageSider } from './components/Sider'
import { PageTheme } from './constants/themeConfig'
import { Chat } from './pages/Chat'
import DashBoard from './pages/DashBoard'
import Patient from './pages/Patient/Patient'
import ViewPatient from './pages/ViewPatient'
import Settings from './pages/Settings'
import { ModalStaticFunctions } from 'antd/es/modal/confirm'
import { MessageInstance } from 'antd/es/message/interface'
import { io, Socket } from 'socket.io-client'
import Login from './pages/Login'
import DeleteAccount from './pages/DeleteAccount'
import ForgetPassword from './pages/ForgetPassword'
import Verification from './pages/Verification'
import ChangePassword from './pages/ChangePassword'
import SecurityQuestion from './pages/SecurityQuestion'

const { Content } = Layout

interface UserInfo {
  id?: string
  firstName?: string
  title?: string
  avatar?: string
}

interface SiteInfo {
  token?: string
}

interface StaticMethods {
  notification?: NotificationInstance
  modal?: Omit<ModalStaticFunctions, 'warn'>
  message?: MessageInstance
  showComingSoon: () => void
}

interface CtxInfo extends UserInfo, SiteInfo, StaticMethods {}

const InfoCxt = createContext<CtxInfo>({
  notification: undefined,
  showComingSoon: () => {
    // placeholder
  },
})

export const ChatSocketIOContext = createContext<Socket | null>(null)

const CURRENT_USER = gql`
  query {
    currentUser {
      id
      avatar
      firstName
      securityQuestionSetupOrNot
      physician {
        title
      }
    }
  }
`

const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
})

// usage: const userInfo = useContext(UserInfoCxt)

/* -------------------------------- react fc -------------------------------- */

/**
 * Always redirect back to login page.
 */
const ToLogin: React.FC<{ token: string; error: ApolloError | undefined }> = ({ token, error }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname !== 'login' || !token || error) {
      navigate('/login')
    }
  }, [navigate, pathname, token, error])

  return null
}

const ToSecurityQuestion: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname !== 'security-question-setup') {
      navigate('/security-question-setup')
    }
  }, [navigate, pathname])

  return null
}

/**
 * Always redirect back to /.
 */
const ToHome: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [navigate])

  return null
}

/**
 * Top level FC for login and provide ctxInfo.
 */
const AppLogin: React.FC = () => {
  const [cookies] = useCookies(['loginToken'])
  const [ctxInfo, setCtxInfo] = useState<CtxInfo>({
    showComingSoon: () => {
      // placeholder
    },
  })
  const [chatSocketClient, setChatSocketClient] = useState<Socket | null>(null)

  const { notification, modal, message } = AntdApp.useApp()

  // Note: weather token or not, the query will alway be send to check token status.
  // Once query error, which means invalid token, always redirect back to /login.
  const {
    loading,
    data: infoData,
    error,
  } = useQuery(CURRENT_USER, { context: { headers: { Authorization: cookies.loginToken } } })

  const IsSecurityQuestionSetup = infoData?.currentUser?.securityQuestionSetupOrNot

  const showComingSoon = useCallback(() => {
    modal.info({
      title: 'Coming Soon',
      content: 'Exciting updates are on the horizon! Stay tuned as we prepare to unveil these exciting additions.',
      okText: 'Dismiss',
      maskClosable: true,
    })
  }, [modal])

  // Note: then with valid data, fill the ctxInfo.
  useEffect(() => {
    if (!loading && !error && infoData) {
      setCtxInfo({
        id: infoData.currentUser.id,
        firstName: infoData.currentUser.firstName,
        title: infoData.currentUser.physician?.title || '',
        avatar: infoData.currentUser.avatar || '/assets/icon/profile_photo_placeholder.png',
        token: cookies.loginToken,
        showComingSoon,
        notification,
        modal,
        message,
      })
    } else {
      setCtxInfo({
        showComingSoon,
        notification,
        modal,
        message,
      })
    }
  }, [loading, infoData, error, cookies, message, modal, notification, showComingSoon])

  useEffect(() => {
    console.log('I am gettin gshit work ' + cookies.loginToken)
    chatSocketClient?.off()
    chatSocketClient?.close()

    const sio = io("/", {
      auth: {
        Authorization: cookies.loginToken,
      },
      autoConnect: false,
    })

      // // push notification on message received
      // session.onMessage((message) => {
      //   // don't notify if I am the sender or already in chat
      //   if (message.isByMe || window.location.pathname == '/chat') return
      //
      //   const key = `Noti${Date.now()}`
      //   notification.open({
      //     message: message.sender?.name,
      //     description: message.body,
      //     icon: <MessageOutlined style={{ color: PageTheme.token?.colorPrimary, fontSize: 20 }} />,
      //     btn: (
      //       <Space>
      //         <Button type='link' size='small' onClick={() => notification.destroy(key)}>
      //           Dismiss
      //         </Button>
      //         <Button type='primary' size='small' onClick={() => notification.destroy(key)}>
      //           Open Chat
      //         </Button>
      //       </Space>
      //     ),
      //     key,
      //   })
      // })

    console.log(sio)
    setChatSocketClient(sio)
    return () => {
      sio.off()
      sio.close()
    }
  }, [cookies])

  // loading
  if (loading) return null

  // not logged in
  if (error) {
    return (
      <InfoCxt.Provider value={ctxInfo}>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/delete-account' element={<DeleteAccount />} />
          <Route path='/forget-password' element={<ForgetPassword />} />
          <Route path='/verification' element={<Verification />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/*' element={<ToLogin token={cookies.loginToken as string} error={error} />} />
        </Routes>
      </InfoCxt.Provider>
    )
  }

  // not setup security question
  if (!IsSecurityQuestionSetup) {
    return (
      <InfoCxt.Provider value={ctxInfo}>
        <Routes>
          <Route path='/security-question-setup' element={<SecurityQuestion />} />
          <Route path='/*' element={<ToSecurityQuestion />} />
        </Routes>
      </InfoCxt.Provider>
    )
  }

  return (
    <>
      <InfoCxt.Provider value={ctxInfo}>
        <ChatSocketIOContext.Provider value={chatSocketClient}>
          <AppLogic />
        </ChatSocketIOContext.Provider>
      </InfoCxt.Provider>
    </>
  )
}

/**
 * AppContent
 */
const AppContent: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path='/' element={<DashBoard />} />
        <Route path='/patient/:patientId/:controlName/:controlType' element={<ViewPatient />} />
        <Route path='/patient/:patientId/:controlName' element={<ViewPatient />} />
        <Route path='/patient/:patientId' element={<ViewPatient />} />
        <Route path='/patient' element={<Patient />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/settings/:tab' element={<Settings />} />
        <Route path='/chat' element={<Chat />} />
        <Route path='/*' element={<ToHome />} />
      </Routes>
    </div>
  )
}

/**
 * AppLogic-Login ready, render main app components.
 */
const AppLogic: React.FC = () => {
  const location = useLocation()
  const [, , removeCookie] = useCookies(['loginToken'])

  const [collapsed, setCollapsed] = useState(isMobile)
  const [showProfile, setShowProfile] = useState(false)
  const [selectedKey, setSelectedKey] = useState('dashboard')
  const contentRef = useRef<HTMLDivElement>(null)

  // effect: setup page menu key
  useEffect(() => {
    const selectedKey = MENU_KEYS.find((key) => location.pathname.includes(key))
    setSelectedKey(selectedKey || MENU_KEYS[0])
  }, [location])

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 })
  }, [])

  return (
    <>
      {/* ----------------------------- pc layout ---------------------------- */}
      <BrowserView>
        {/* root */}
        <Layout className='layout-root'>
          {/* sider */}
          <PageSider collapsed={collapsed} selectedKey={selectedKey} />
          {/* content */}
          <Layout style={{ overflowY: 'scroll', overflowX: 'hidden', backgroundColor: '#fff' }}>
            {/* header */}
            <PageHeader collapsed={collapsed} setCollapsed={setCollapsed} removeCookie={removeCookie} />
            {/* content */}
            <div className='layout-content'>
              {/* page */}
              <AppContent />
              {/* footer */}
              <PageFooter />
            </div>
          </Layout>
        </Layout>
      </BrowserView>
      {/* --------------------------- mobile layout -------------------------- */}
      <MobileView>
        {/* root */}
        <Layout>
          <div
            ref={contentRef}
            className='layout-root hide-scrollbar'
            style={{ overflowY: 'scroll', overflowX: 'hidden', backgroundColor: '#fff' }}>
            {/* header */}
            <PageHeader
              collapsed={collapsed}
              setCollapsed={(flag: boolean) => {
                setCollapsed(flag)
                contentRef.current?.scrollTo({ top: 0 })
              }}
              showProfile={showProfile}
              setShowProfile={(flag: boolean) => {
                setShowProfile(flag)
                contentRef.current?.scrollTo({ top: 0 })
              }}
              removeCookie={removeCookie}
            />
            {/* content */}
            <div className='layout-content-mobile'>
              {/* menu */}
              <PageSider
                collapsed={collapsed}
                selectedKey={selectedKey}
                showProfile={showProfile}
                removeCookie={removeCookie}
              />
              {/* page */}
              <AppContent />
              {/* footer */}
              <PageFooter />
            </div>
          </div>
        </Layout>
      </MobileView>
    </>
  )
}

const App = () => {
  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ConfigProvider theme={PageTheme} renderEmpty={TableEmpty}>
            <AntdApp>
              <AppLogin />
            </AntdApp>
          </ConfigProvider>
        </BrowserRouter>
      </ApolloProvider>
    </CookiesProvider>
  )
}

export default App
export { InfoCxt }
