import './index.css'
import * as React from 'react'
import { Image, Typography } from 'antd'

const { Text } = Typography

const SearchEmpty = () => {
  return (
    <div className='empty_container'>
      <Image preview={false} width='150px' src='/assets/icon/search-empty.png' />
      <Text type='secondary' style={{ fontSize: '12px' }}>
        {'Results took a vacation, search surprised!'}
      </Text>
    </div>
  )
}

export default SearchEmpty
