import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Card, Checkbox, Col, Form, Image, Input, Layout, Row, Space, Typography } from 'antd'
import { useCookies } from 'react-cookie'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { InfoCxt } from '../../App'
import { isValidPassword } from '../../utils/isValidPassword'

const { Title, Text } = Typography

const { Footer, Content } = Layout

const RESET_PASSWORD = gql`
  mutation ResetPassword($code: String!, $username: String!, $password: String!) {
    authMutations {
      resetPassword(code: $code, username: $username, password: $password) {
        status
        message {
          title
          icon
          content
        }
      }
    }
  }
`

const ChangePassword = () => {
  const { notification } = useContext(InfoCxt)
  const navigate = useNavigate()

  const [fnResetPassword, { data, loading }] = useMutation(RESET_PASSWORD)
  const [matchedPassword, setMatchedPassword] = useState(true)
  const [validPassword, setValidPassword] = useState(true)

  const submit = useCallback(
    (values: { password: string; confirmPassword: string }) => {
      if (values.password !== values.confirmPassword) {
        setMatchedPassword(false)
        return
      } else if (isValidPassword(values.password) === false) {
        setMatchedPassword(true)
        setValidPassword(false)
        return
      } else {
        setMatchedPassword(true)
        setValidPassword(true)
        fnResetPassword({ variables: { code: code, username: username, password: values.password } }).then((res) => {
          if (res?.data?.authMutations?.resetPassword?.status === true) {
            notification?.success({
              message: 'Password Changed',
              description: 'Your password has been changed successfully. Use your new password to log in.',
              btn: (
                <Button type='link' size='small' onClick={() => notification?.destroy()}>
                  Dismiss
                </Button>
              ),
              closeIcon: null,
            })
            navigate('/login')
          } else {
            notification?.error({
              message: res?.data?.authMutations?.resetPassword?.message?.title,
              description: res?.data?.authMutations?.resetPassword?.message?.content,
            })
          }
        })
      }
    },
    [fnResetPassword]
  )

  const { state } = useLocation()

  useEffect(() => {
    if (!state || !state.code || !state.username) {
      navigate('/forget-password')
      return () => {
        // do nothing
      }
    }
  }, [state])

  if (!state || !state.code || !state.username) {
    return null
  }

  const { code, username } = state

  let errorElements = null
  if (data) {
    if (data?.authMutations?.verifySecurityAnswers?.status === true) {
      errorElements = null
    } else if (data?.authMutations?.verifySecurityAnswers?.message?.title === 'Incorrect Answer') {
      errorElements = (
        <Col span={24}>
          <Alert
            showIcon
            message={`${data?.authMutations?.verifySecurityAnswers?.message?.title}. ${data?.authMutations?.verifySecurityAnswers?.message?.content}`}
            type='error'
          />
        </Col>
      )
    } else if (data?.authMutations?.verifySecurityAnswers?.message?.title === 'Account Temporarily Locked') {
      errorElements = null
    } else {
      errorElements = (
        <Col span={24}>
          <Alert showIcon message={data?.authMutations?.verifySecurityAnswers?.message?.content} type='error' />
        </Col>
      )
    }
  } else if (matchedPassword === false) {
    errorElements = (
      <Col span={24}>
        <Alert showIcon message='Password do not match, try again.' type='error' />
      </Col>
    )
  } else if (validPassword === false) {
    errorElements = (
      <Col span={24}>
        <Alert
          showIcon
          message='Passwords must have at least 8 characters and contain uppercase letters, lowercase letters, numbers, and symbols (e.g. @, #, $, %, ^).'
          type='error'
        />
      </Col>
    )
  }

  return (
    <Layout className='login_container'>
      <Content>
        <Row>
          <Col className='row_center' span={24}>
            <Card className='card_container' styles={{body: { padding: 0 }}}>
              <Row gutter={[0, 24]} style={{ width: '100%' }}>
                <Col span={24} className='row_center'>
                  <Image preview={false} width='106px' src='/assets/icon/login-title.png' />
                </Col>

                <Col span={24} className='row_center'>
                  <Image preview={false} width='150px' src='/assets/icon/change-password.png' />
                </Col>

                <Col span={24}>
                  <Title level={4} style={{ marginTop: 0 }}>
                    Change password
                  </Title>
                  <Text>Verification was a success, please enter the password that you wish to change to.</Text>
                </Col>

                {errorElements}

                <Col span={24}>
                  <Form
                    name='basic'
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxWidth: 600 }}
                    layout='vertical'
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    onFinish={submit}
                    // onFinishFailed={() => void}
                    autoComplete='off'>
                    <Form.Item name='password' rules={[{ required: true, message: 'Please input password.' }]}>
                      <Input.Password prefix={<LockOutlined />} placeholder='Password' />
                    </Form.Item>
                    <Form.Item name='confirmPassword' rules={[{ required: true, message: 'Please confirm password.' }]}>
                      <Input.Password prefix={<LockOutlined />} placeholder='Confirm Password' />
                    </Form.Item>
                    <Form.Item>
                      <Button block type='primary' htmlType='submit' loading={loading}>
                        Submit
                      </Button>
                    </Form.Item>

                    <div className='row_center'>
                      <Link style={{ color: '#38C172' }} to='/login'>
                        Cancel
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer className='footer'>© Empower Plus. All Rights Reserved.</Footer>
    </Layout>
  )
}

export default ChangePassword
