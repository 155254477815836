import './index.css'
import * as React from 'react'
import { useState } from 'react'
import { Button, DatePicker, Drawer, Typography } from 'antd'
import dayjs from 'dayjs'
import { CloseOutlined, MinusOutlined } from '@ant-design/icons'

const { Text } = Typography

/* -------------------------------- drawer fc ------------------------------- */

type PageDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  _defaultStart: string
  _defaultEnd: string
  _start: dayjs.Dayjs
  _end: dayjs.Dayjs
  setDateRange: React.Dispatch<React.SetStateAction<[dayjs.Dayjs, dayjs.Dayjs]>>
}

const PageDrawer: React.FC<PageDrawerProps> = ({
  isOpen,
  closeDrawer,
  _defaultStart,
  _defaultEnd,
  _start,
  _end,
  setDateRange,
}) => {
  const [start, setStart] = useState<dayjs.Dayjs>(_start)
  const [end, setEnd] = useState<dayjs.Dayjs>(_end)

  return (
    <>
      <Drawer
        title='Select date range'
        placement='right'
        closable={false}
        styles={{body: { padding: 0 }}}
        onClose={closeDrawer}
        open={isOpen}
        extra={<CloseOutlined onClick={closeDrawer} className='icon_style' />}
        footer={
          <div className='drawer_footer_container'>
            <Button
              block
              onClick={() => {
                setStart(dayjs(_defaultStart, 'YYYY-MM-DD'))
                setEnd(dayjs(_defaultEnd, 'YYYY-MM-DD'))
              }}>
              Reset
            </Button>
            <Button
              type='primary'
              block
              onClick={() => {
                closeDrawer()
                setDateRange([start, end])
              }}>
              Confirm
            </Button>
          </div>
        }>
        <div className='drawer_container'>
          <div className='draw_body_card'>
            <div className='card_header'>
              <Text strong>From</Text>
              <MinusOutlined className='icon_style' />
            </div>
            <DatePicker
              inputReadOnly
              allowClear={false}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              value={start}
              disabledDate={(current) => current > end}
              onChange={(d) => {
                if (d) setStart(d)
              }}
            />
          </div>
          <div className='draw_body_card'>
            <div className='card_header'>
              <Text strong>To</Text>
              <MinusOutlined className='icon_style' />
            </div>
            <DatePicker
              inputReadOnly
              allowClear={false}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              value={end}
              disabledDate={(current) => current < start}
              onChange={(d) => {
                if (d) setEnd(d)
              }}
            />
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default PageDrawer
