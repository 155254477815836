import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Dropdown, Input, MenuProps, Tabs, theme, Typography } from 'antd'
import debounce from 'lodash/debounce'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useLazyQuery } from '@apollo/client'
import { InfoCxt } from '../../../../App'
import SearchEmpty from '../../../Empty/SearchEmpty'
import Loading from '../../../Loading/GeneralLoading'

const { Text } = Typography
const { useToken } = theme

const DEBOUNCE_TIME = 500

type SearchCategoryEnum = 'ALL' | 'PATIENT'

type SearchItemData = {
  uid: string
  firstName: string
}

const QUERY_FUZZY_SEARCH = gql`
  query ($blurText: String!, $searchType: SearchCategoryEnum!) {
    generalSearch(blurText: $blurText, searchType: $searchType) {
      patientSearchResults {
        uid
        firstName
      }
    }
  }
`

const SearchBar: React.FC = () => {
  const navigate = useNavigate()

  const { token: themeToken } = useToken()
  const { token: loginToken } = useContext(InfoCxt)

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState('')
  const [mode, setMode] = useState<SearchCategoryEnum>('ALL')
  const [options, setOptions] = useState<MenuProps['items']>([])

  // debounce text input, must use useMemo instead of useCallback
  const debounceSetText = useMemo(() => debounce(setText, DEBOUNCE_TIME), [setText])

  const [getSearchResults, { data: queryData }] = useLazyQuery(QUERY_FUZZY_SEARCH, {
    context: { headers: { Authorization: loginToken } },
    onCompleted: () => setIsLoading(false),
  })

  // handler for click the menu item
  const handleClick = useCallback(
    ({ key }: { key: string }) => {
      // console.log(12, 'jump', key)
      navigate(`/patient/${key}`)
      setIsOpen(false)
      setOptions([])
      setText('')
    },
    [navigate]
  )

  // effect for search API call
  useEffect(() => {
    if (text && mode) {
      getSearchResults({ variables: { blurText: text, searchType: mode } })
    } else {
      setOptions([])
    }
  }, [text, mode, getSearchResults])

  // effect for API callback
  useEffect(() => {
    if (text !== '' && queryData) {
      const items: SearchItemData[] = queryData.generalSearch.patientSearchResults
      // console.log(11, items)
      if (items) {
        const opts: MenuProps['items'] = items.map(({ uid, firstName }) => {
          return {
            key: uid,
            label: firstName,
            icon: <UserOutlined />,
            children: [],
            popupClassName: 'none',
            onTitleClick: handleClick,
          }
        })
        setOptions(opts)
      }
    } else if (!queryData) {
      setOptions([])
    }
  }, [text, queryData, handleClick])

  const contentStyle: React.CSSProperties = {
    backgroundColor: themeToken.colorBgElevated,
    borderRadius: themeToken.borderRadiusLG,
    boxShadow: themeToken.boxShadowSecondary,
    padding: '12px',
  }

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  }

  const LocalEmpty = React.memo(() => <SearchEmpty />)
  LocalEmpty.displayName = ''

  return (
    <>
      <Dropdown
        trigger={['click']}
        open={isOpen}
        onOpenChange={(flag: boolean) => {
          setIsOpen(flag)
        }}
        destroyPopupOnHide={false}
        menu={{ items: options, mode: 'vertical' }}
        dropdownRender={(menu) => (
          <div style={contentStyle}>
            <Tabs
              defaultActiveKey='1'
              tabBarStyle={{ padding: '0 12px' }}
              onChange={(key) => {
                setMode(key as SearchCategoryEnum)
              }}
              items={[
                {
                  key: 'ALL',
                  label: `All`,
                },
                {
                  key: 'PATIENT',
                  label: `Patient`,
                },
              ]}
            />
            <div style={{ padding: '16px 12px 8px 12px' }}>
              <Text strong>Search Results</Text>
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {options?.length ? (
                  <>{React.cloneElement(menu as React.ReactElement, { style: menuStyle })}</>
                ) : (
                  <LocalEmpty />
                )}
              </>
            )}
          </div>
        )}>
        <Input
          allowClear
          placeholder='Search Patient UID'
          prefix={<SearchOutlined style={{ color: '#9B9FA6' }} />}
          style={{ width: isMobile ? '100%' : '520px' }}
          onChange={(e) => {
            setIsOpen(true)
            const str = e.target.value as string
            if (str !== '') {
              setIsLoading(true)
              debounceSetText(str)
            } else {
              setIsLoading(false)
              setText('')
            }
          }}
        />
      </Dropdown>
    </>
  )
}

export default SearchBar
