import '../index.css'
import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import { Button, Pagination, Space, Table, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { isMobile } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'
import { EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { InfoCxt } from '../../../App'
import { Loading } from '../../../components/Loading/defaultLoading'
import {
  PaddingBox,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'
import ResourceDrawer from './resourceDrawer'

const QUERY_RESOURCE = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        resourceMark {
          id
          resourcesMarkList {
            id
            type
            source
            image
            category
            subCategory
            title
            externalUrl
          }
          resourcesRecommendList {
            id
            type
            source
            image
            category
            subCategory
            title
            externalUrl
          }
        }
      }
    }
  }
`

interface ResourcesDataType {
  id: string
  type: string
  source: string
  category: string
  subCategory: string
  image?: string
  title: string
  externalUrl: string
}

interface RawDataType {
  resourcesMarkList?: ResourcesDataType[]
  resourcesRecommendList?: ResourcesDataType[]
}

interface ResourcesProcessedDataType extends ResourcesDataType {
  markType: 'Assigned' | 'Saved'
}

const processRawData = (queryData: RawDataType): Map<string, ResourcesProcessedDataType> => {
  const res = new Map<string, ResourcesProcessedDataType>()
  queryData.resourcesMarkList?.map((item) => {
    res.set(item.id, { ...item, markType: 'Saved' })
  })
  queryData.resourcesRecommendList?.map((item) => {
    res.set(item.id, { ...item, markType: 'Assigned' })
  })
  return res
}

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<ResourcesProcessedDataType> = [
  {
    title: 'Type',
    dataIndex: 'type',
    // width: '300px',
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    // width: '300px',
    sorter: (a, b) => a.source.localeCompare(b.source),
  },
  {
    title: 'Title',
    dataIndex: 'title',
    // width: '300px',
    sorter: (a, b) => a.title.localeCompare(b.title),
  },
  {
    title: 'Assigned / Saved',
    dataIndex: 'markType',
    // width: '300px',
    sorter: (a, b) => a.markType.localeCompare(b.markType),
  },
]

/* -------------------------------- react fc -------------------------------- */

const Resource: React.FC = () => {
  const navigate = useNavigate()
  const { token: loginToken } = useContext(InfoCxt)
  const { patientId: userName } = useParams()
  const [drawer, setDrawer] = useState(false)
  const [resourceID, setResourceID] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const {
    loading,
    data: queryData,
    refetch,
  } = useQuery(QUERY_RESOURCE, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })

  const closeDrawer = useCallback(
    (doRefetch?: boolean) => {
      setDrawer(false)
      if (doRefetch) refetch()
    },
    [setDrawer, refetch]
  )

  const onClickView = useCallback(
    (name: string) => {
      setDrawer(true)
      setResourceID(name)
    },
    [setDrawer, setResourceID]
  )

  const resourceQueryData: RawDataType = queryData?.physician?.queryPatient?.resourceMark || {}
  const resourceData: Map<string, ResourcesProcessedDataType> = resourceQueryData
    ? processRawData(resourceQueryData)
    : new Map()

  return (
    <>
      <ResourceDrawer isOpen={drawer} closeDrawer={closeDrawer} resourceID={resourceID} info={resourceData} />
      {loading ? (
        <Loading />
      ) : (
        <>
          {resourceQueryData && (
            <>
              <TabHeader border>
                <PageTitle text='Resources' />
                {isMobile ? (
                  <Button
                    type='primary'
                    ghost
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('./select/resource')
                    }}
                  />
                ) : (
                  <Button
                    type='primary'
                    ghost
                    icon={<PlusOutlined />}
                    onClick={() => {
                      navigate('./select/resource')
                    }}>
                    Add
                  </Button>
                )}
              </TabHeader>
              <PaddingBox>
                <PageSubtitle text='Assigned Resources' />
                <TableBox>
                  <Table
                    columns={[
                      ...columns,
                      {
                        title: 'Action',
                        width: '116px',
                        render: (_, { id }) => (
                          <Space size='middle'>
                            <Tooltip title='View'>
                              <Button type='primary' ghost icon={<EyeOutlined />} onClick={() => onClickView(id)} />
                            </Tooltip>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={Array.from(resourceData.values())}
                    rowKey={(record) => record.id}
                    pagination={{
                      position: ['none'],
                      current: currentPage,
                    }}
                  />
                </TableBox>
                <TablePaginationBox>
                  <Pagination
                    current={currentPage}
                    total={Array.from(resourceData.values()).length}
                    pageSize={10}
                    showLessItems={isMobile}
                    showSizeChanger={false}
                    onChange={(page) => setCurrentPage(page)}
                  />
                </TablePaginationBox>
              </PaddingBox>
            </>
          )}
        </>
      )}
    </>
  )
}

export type { ResourcesProcessedDataType }
export default Resource
