import './index.css'
import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'

const { Footer, Content } = Layout

const GET_SECURITY_QUESTIONS = gql`
  query GetSecurityQuestions {
    allSecurityQuestions {
      id
      content
    }
  }
`

const MESSAGE_FIELDS = gql`
  fragment MessageFields on MessageType {
    title
    icon
    content
  }
`

const UPLOAD_SECURITY_ANSWERS = gql`
  ${MESSAGE_FIELDS}
  mutation UploadSecurityAnswers($securityQuestions: [SecurityQuestionInputType]!) {
    authMutations {
      uploadSecurityAnswers(securityQuestions: $securityQuestions) {
        status
        message {
          ...MessageFields
        }
      }
    }
  }
`

type QuestionOptionType = { label: string; value: string }
type SecurityQuestionType = { id: string; content: string }

const SecurityQuestion = () => {
  const [cookies] = useCookies(['loginToken'])
  const [question1, setQuestion1] = useState('')
  const [answer1, setAnswer1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [answer2, setAnswer2] = useState('')
  const [question3, setQuestion3] = useState('')
  const [answer3, setAnswer3] = useState('')
  const [step, setStep] = useState(1)
  const [isBackStep, setIsBackStep] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const isValidAnswer = (answer: string) => {
    return answer !== ''
  }

  const enableNext =
    (step === 1 && question1 !== '' && isValidAnswer(answer1)) ||
    (step === 2 && question2 !== '' && isValidAnswer(answer2)) ||
    (step === 3 && question3 !== '' && isValidAnswer(answer3))

  const { data: securityQuestions } = useQuery(GET_SECURITY_QUESTIONS, {
    context: {
      headers: {
        Authorization: cookies.loginToken ? `Bearer ${cookies.loginToken}` : '',
      },
    },
  })

  const [uploadAnswers, { loading: uploadAnswersLoading }] = useMutation(UPLOAD_SECURITY_ANSWERS, {
    context: {
      headers: {
        Authorization: cookies.loginToken ? `Bearer ${cookies.loginToken}` : '',
      },
    },
    onCompleted: (data) => {
      setTimeout(() => {
        setLoading(false)
        // force reload to clear state
        window.location.href = '/'
      }, 1000)
    },
    onError: (error) => {
      setLoading(false)
    },
  })

  const getQuestionList = (
    securityQuestions: { allSecurityQuestions: SecurityQuestionType[] },
    step: number,
    question1: string,
    question2: string,
    question3: string
  ) => {
    const questions: QuestionOptionType[] = []
    securityQuestions?.allSecurityQuestions?.forEach((item: SecurityQuestionType) => {
      if (
        (step === 1 && question2 !== item.content && question3 !== item.content) ||
        (step === 2 && question1 !== item.content && question3 !== item.content) ||
        (step === 3 && question1 !== item.content && question2 !== item.content)
      ) {
        questions.push({ label: item.content, value: item.content })
      }
    })
    return questions
  }

  const questionList = useMemo(
    () => getQuestionList(securityQuestions, step, question1, question2, question3),
    [securityQuestions, step, question1, question2, question3]
  )

  const question = step === 1 ? question1 : step === 2 ? question2 : question3

  const setQuestion = (value: string) => {
    switch (step) {
      case 1:
        setQuestion1(value)
        break
      case 2:
        setQuestion2(value)
        break
      case 3:
        setQuestion3(value)
        break
    }
  }

  const answer = step === 1 ? answer1 : step === 2 ? answer2 : answer3

  const setAnswer = (value: string) => {
    switch (step) {
      case 1:
        setAnswer1(value)
        break
      case 2:
        setAnswer2(value)
        break
      case 3:
        setAnswer3(value)
        break
    }
  }

  const nextFunc = () => {
    if (!enableNext) {
      setError('To continue, select a question and input the answer.')
      return
    }
    setError('')
    setIsBackStep(false)
    if (step <= 2) {
      const newStep = step + 1
      setStep(newStep)
    } else {
      const requestBundle = {
        securityQuestions: [
          { question: question1, answer: answer1 },
          { question: question2, answer: answer2 },
          { question: question3, answer: answer3 },
        ],
      }
      setLoading(true)
      uploadAnswers({ variables: requestBundle }).then()
    }
  }

  return (
    <Layout className='login_container'>
      <Content>
        <Row>
          <Col className='row_center' span={24}>
            <Card className='card_container' styles={{body: { padding: 0 }}}>
              <Row gutter={[0, 24]} style={{ width: '100%' }}>
                <Col span={24} className='row_center'>
                  <Image preview={false} width='106px' src='/assets/icon/login-title.png' />
                </Col>
                <Col span={24} className='row_center'>
                  <Image src={`/assets/icon/security/step${step}.png`} width={150} height={150} preview={false} />
                </Col>
                <Row justify='center'>
                  <Col>
                    <Typography.Title level={4} style={{ marginTop: 0 }}>
                      Set Up Security Question {step}
                    </Typography.Title>
                    <Typography.Text type='secondary'>
                      This will help to verify your identity and help recover your password if you ever forget it.
                    </Typography.Text>
                  </Col>
                </Row>
                {error !== '' && (
                  <Row justify='center'>
                    <Col>
                      <Alert showIcon message={error} type='error' />
                    </Col>
                  </Row>
                )}
                <Select value={question} onChange={setQuestion} options={questionList} style={{ width: '100%' }} />
                <Input value={answer} onChange={(e) => setAnswer(e.target.value)} placeholder='Enter Answer' />
                <Button type='primary' onClick={nextFunc} loading={uploadAnswersLoading} style={{ width: '100%' }}>
                  Next
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Modal
        className='security-modal'
        open={loading || uploadAnswersLoading}
        maskClosable={false}
        footer={null}
        closable={false}
        width={157}
        centered={true}>
        <Spin tip='Setting up account'>
          <div style={{ minHeight: 62 }}></div>
        </Spin>
      </Modal>
      <Footer className='footer'>© Empower Plus. All Rights Reserved.</Footer>
    </Layout>
  )
}

export default SecurityQuestion
