import '../index.css'
import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import { Button, Pagination, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import { CalendarOutlined, PlusOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { defaultEnd, defaultStart, PageProps } from '../'
import { InfoCxt } from '../../../App'
import PageDrawer from '../../../components/Drawer/DatePickerDrawer'
import { Loading } from '../../../components/Loading/defaultLoading'
import {
  PaddingBox,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'

const QUERY_ADHERENCE = gql`
  query ($username: String!, $start: String!, $end: String!) {
    physician {
      queryPatient(username: $username) {
        report {
          medicationReport(start: $start, end: $end) {
            medicationAdherence {
              id
              medicationPlan {
                drugName
                dose
                type
                dosage
                perDay
              }
              latestRecordDate
              logWithinAdherencePercentage
            }
          }
        }
      }
    }
  }
`

interface DataType {
  id: string
  medicationPlan: {
    drugName: string
    dose: string
    type: string
    dosage: number
    perDay: number
  }
  latestRecordDate: string
  logWithinAdherencePercentage: number
}

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<DataType> = [
  {
    title: 'Medication Name',
    dataIndex: ['medicationPlan', 'drugName'],
    key: 'medicationPlan',
    sorter: (a, b) => a.medicationPlan.drugName.localeCompare(b.medicationPlan.drugName),
  },
  {
    title: 'Adherence',
    dataIndex: 'logWithinAdherencePercentage',
    key: 'logWithinAdherencePercentage',
    sorter: (a, b) => a.logWithinAdherencePercentage - b.logWithinAdherencePercentage,
    render: (_, { logWithinAdherencePercentage }) => {
      return (
        <>
          <Tag
            color={
              logWithinAdherencePercentage >= 91 ? 'green' : logWithinAdherencePercentage >= 50 ? 'yellow' : 'red'
            }>
            {logWithinAdherencePercentage + ' %'}
          </Tag>
        </>
      )
    },
  },
  {
    title: 'Dosage',
    dataIndex: 'dosage',
    key: 'dosage',
    sorter: (a, b) => (a.medicationPlan.dosage > b.medicationPlan.dosage ? 1 : -1),
    render: (_, { medicationPlan: { dose, dosage, type } }) => `${dose ? dose + ', ' : ''}${dosage} ${type}(s)`,
  },
  // {
  //   title: 'Frequency',
  //   dataIndex: 'frequency',
  //   key: 'frequency',
  //   sorter: (a, b) => a.medicationPlan.perDay - b.medicationPlan.perDay,
  //   render: (_, { medicationPlan: { perDay } }) => `${perDay} Per Day`,
  // },
  {
    title: 'Last Logged',
    dataIndex: 'latestRecordDate',
    key: 'latestRecordDate',
    sorter: (a, b) => a.latestRecordDate.localeCompare(b.latestRecordDate),
    render: (value) => {
      if (value) return dayjs(value.slice(0, 10), 'YYYY-MM-DD').format('DD MMM YYYY')
      return '-'
    },
  },
]

/* -------------------------------- react fc -------------------------------- */

const Adherence: React.FC<PageProps> = ({ userName, loginToken }) => {
  const { showComingSoon } = useContext(InfoCxt)
  const [drawer, setDrawer] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs(defaultStart, 'YYYY-MM-DD'),
    dayjs(defaultEnd, 'YYYY-MM-DD'),
  ])
  const { loading, data: queryData } = useQuery(QUERY_ADHERENCE, {
    variables: { username: userName, start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD') },
    context: { headers: { Authorization: loginToken } },
  })

  const adherenceData: DataType[] =
    queryData?.physician?.queryPatient?.report?.medicationReport?.medicationAdherence || null

  const showDrawer = useCallback(() => {
    setDrawer(true)
  }, [setDrawer])

  const closeDrawer = useCallback(() => {
    setDrawer(false)
  }, [setDrawer])

  return (
    <>
      <PageDrawer
        isOpen={drawer}
        closeDrawer={closeDrawer}
        _defaultStart={defaultStart}
        _defaultEnd={defaultEnd}
        _start={dateRange[0]}
        _end={dateRange[1]}
        setDateRange={setDateRange}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {adherenceData && (
            <>
              <TabHeader border>
                <PageTitle text='Medications' />
                {isMobile ? (
                  <Space>
                    <Button type='primary' onClick={showDrawer} icon={<CalendarOutlined />} />
                    <Button type='primary' ghost icon={<PlusOutlined />} onClick={showComingSoon} />
                  </Space>
                ) : (
                  <Space>
                    <Button type='primary' onClick={showDrawer} icon={<CalendarOutlined />}>
                      {`${dateRange[0].format('DD MMM YYYY')} - ${dateRange[1].format('DD MMM YYYY')}`}
                    </Button>
                    {/* <Button type='primary' ghost icon={<PlusOutlined />} onClick={showComingSoon}>
                      Add
                    </Button> */}
                  </Space>
                )}
              </TabHeader>
              <PaddingBox>
                <PageSubtitle text='Medication Adherence' />
                <TableBox>
                  <Table
                    columns={columns}
                    dataSource={adherenceData}
                    rowKey={(record) => `${JSON.stringify(record)}-${Date.now()}-${Math.random()}`}
                    pagination={{
                      position: ['none'],
                      current: currentPage,
                    }}
                  />
                </TableBox>
                <TablePaginationBox>
                  <Pagination
                    current={currentPage}
                    total={adherenceData.length}
                    pageSize={10}
                    showLessItems={isMobile}
                    showSizeChanger={false}
                    onChange={(page) => setCurrentPage(page)}
                  />
                </TablePaginationBox>
              </PaddingBox>
            </>
          )}
        </>
      )}
    </>
  )
}

export default Adherence
