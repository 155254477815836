import '../index.css'
import * as React from 'react'
import { useState } from 'react'
import { Drawer, Image, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { CheckOutlined, CloseOutlined, MinusOutlined } from '@ant-design/icons'
import { getColor, getStatusName, GoalDataType } from './goals'

const { Text, Paragraph } = Typography

/* ----------------------------- checklist icons ---------------------------- */

const getTextOrIcon = (type: string, index: number) => {
  switch (type) {
    case 'Green':
    case 'Purple':
      return <CheckOutlined style={{ color: '#FFFFFF' }} width={16} height={16} />
    case 'Grey':
      return <MinusOutlined style={{ color: '#FFFFFF' }} width={16} height={16} />
    case 'Number':
      return (
        <div
          style={{
            color: '#DEDFE1',
          }}>
          {`${index}`}
        </div>
      )
    default:
      return <div />
  }
}

const getBg = (type: string) => {
  switch (type) {
    case 'Green':
      return '#38C172'
    case 'Purple':
      return '#7966FF'
    case 'Grey':
      return '#DEDFE1'
    default:
      return '#FFFFFF'
  }
}

const getIcons = (startTime: string, checkList: string[]) => {
  if (!startTime) return <></>

  let _startDate = dayjs(startTime.slice(0, 10), 'YYYY-MM-DD')
  const _itemsCount = dayjs().diff(_startDate, 'day')
  const N = 7

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      {new Array(N).fill(0).map((_, index) => {
        _startDate = _startDate.add(checkList[index] === 'Purple' ? 2 : 1, 'day')

        return (
          <Tooltip
            placement='top'
            title={_startDate.add(-1, 'day').format('DD MMM')}
            mouseEnterDelay={checkList[index] ? 0.1 : 9999}
            key={`index_${index}`}>
            <div
              style={{
                width: 32,
                height: 32,
                backgroundColor: getBg(checkList[index] || ''),
                borderRadius: 16,
                borderWidth: checkList[index] ? 0 : 1,
                borderColor: _itemsCount - index > 0 ? '#DEDFE1' : '#FFFFFF',
                borderStyle: 'solid',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {getTextOrIcon(_itemsCount - index > 0 ? checkList[index] || 'Number' : '', index + 1)}
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}

/* -------------------------------- drawer fc ------------------------------- */

type GoalDrawerProps = {
  isOpen: boolean
  closeDrawer: (doRefetch?: boolean) => void
  goalID: string
  info: Map<string, GoalDataType>
}

const GoalsDrawer: React.FC<GoalDrawerProps> = ({ isOpen, closeDrawer, goalID, info }) => {
  const item = info.get(goalID)
  const [showMoreDesc, setShowMoreDesc] = useState(false)
  const [showMoreWhy, setShowMoreWhy] = useState(false)

  const onClose = () => {
    setShowMoreDesc(false)
    setShowMoreWhy(false)
    closeDrawer()
  }

  return (
    <>
      <Drawer
        title='View'
        placement='right'
        closable={false}
        styles={{body: { padding: 0 }}}
        onClose={onClose}
        open={isOpen}
        extra={<CloseOutlined onClick={onClose} className='icon_style' />}>
        <div className='drawer_container'>
          <div className='draw_body_card'>
            <div className='dosage_flex'>
              <div className='dosage_flex' style={{ gap: '8px' }}>
                <Tag color={getColor(item?.statusColor || '')}>{getStatusName(item?.statusColor || '')}</Tag>
                <Text type='secondary'>{item?.goalCategory || '-'}</Text>
                <Text strong style={{ fontSize: '16px' }}>
                  {item?.goalTitle || '-'}
                </Text>
              </div>
              <Image
                style={{ borderRadius: '5px', width: '330px', height: '206px', objectFit: 'cover' }}
                src={`data:image/jpeg;base64,${item?.goalImage}`}
              />
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_flex'>
              <Text strong>Completion Counts</Text>
              {/* checklist icons */}
              <div
                style={{
                  width: '100%',
                }}>
                {getIcons(item?.startTime || '', item?.checkList || [])}
              </div>
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_grid'>
              <Text strong>Duration</Text>
              <Text strong>Type</Text>
              <Text>{item?.completionType || '-'}</Text>
              <Text>{item?.goalType || '-'}</Text>
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_grid'>
              <Text strong>Start Date</Text>
              <Text strong>Assignee</Text>
              <Text>
                {item?.startTime ? dayjs(item.startTime.slice(0, 10), 'YYYY-MM-DD').format('DD MMM YYYY') : '-'}
              </Text>
              <Text>{item?.assignee || '-'}</Text>
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_flex'>
              <Text strong>Description</Text>
              {showMoreDesc ? (
                <Text>{item?.description || '-'}</Text>
              ) : (
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    rows: 3,
                    expandable: true,
                    symbol: 'more',
                    expanded: showMoreDesc,
                    onExpand: () => setShowMoreDesc(true),
                  }}>
                  {item?.description || '-'}
                </Paragraph>
              )}
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_flex'>
              <Text strong>Why It’s Important?</Text>
              {showMoreWhy ? (
                <Text>{item?.whyItIsImportant || '-'}</Text>
              ) : (
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    rows: 3,
                    expandable: true,
                    symbol: 'more',
                    expanded: showMoreWhy,
                    onExpand: () => setShowMoreWhy(true),
                  }}>
                  {item?.whyItIsImportant || '-'}
                </Paragraph>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default GoalsDrawer
