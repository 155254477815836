import './index.css'
import * as React from 'react'
import { useContext, useEffect, useState, useRef } from 'react'
import { Avatar, Button, Col, Input, List, Row, Switch, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { InfoCxt, ChatSocketIOContext } from '../../App'
import { PageFooter } from '../../components/Footer'
import { SendOutlined } from '@ant-design/icons'
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils'

type Inbox = {
  id: string
  owner: {
    id: string
    first_name: string
    avatar: string
  }
  attended_by: {
    id: string
  }
}

type Message = {
  id: string
  sender: {
    id: string
    first_name: string
    model: 'patient' | 'physician' | 'other'
  }
  emergency: boolean
  reply_to: {
    id: string
  }
  text: string
  created_at: string
}

function wsStreamHandler(items: Array<Inbox | Message>, newItems: Array<string> | string) {
  if (Array.isArray(newItems)) {
    return newItems.map((item) => JSON.parse(item))
  } else {
    const newItemObj: Inbox | Message = JSON.parse(newItems)
    if (items.filter((i) => i.id == newItemObj.id).length == 0) {
      return [newItemObj, ...items]
    } else {
      return [...items]
    }
  }
}

function simpleReducer(item: Inbox | null, newItem: { item: Inbox | null; action: 'update' | 'select' }) {
  // update = update from event socket (when phys connect, etc.)
  if (newItem.action === 'update') {
    if (item == null || newItem.item?.id === item.id) {
      return newItem.item
    } else {
      return { ...item }
    }
  } else {
    return newItem.item
  }
}

const events = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  INITIATE: 'initiate_inbox',
  SEND: 'send',
  POPULATE_MSG: 'populate',
  POPULATE_CHATLIST: 'populate_chatlist',
  CONNECT_PHYS: 'connect_physician',
  DISCONNECT_PHYS: 'disconnect_physician',
  JOIN: 'join_room',
  LEAVE: 'leave_room',
  CHECKIN: 'online_checkin',
}

export function Chat() {
  const [chatOn, setChatOn] = useState<boolean>(true)
  const [chatList, dispatchChatList] = React.useReducer(wsStreamHandler, [])
  const [chatMessages, dispatchChatMessages] = React.useReducer(wsStreamHandler, [])
  const [currentInbox, updateCurrentInbox] = React.useReducer(simpleReducer, null)
  const [text, setText] = useState<string>('')
  const currentUser = useContext(InfoCxt)
  const chatBoxScrollRef = useRef<HTMLDivElement>(null)

  const sio = useContext(ChatSocketIOContext)

  useEffect(() => {
    let intervalID: ReturnType<typeof setInterval>
    updateCurrentInbox({ item: null, action: 'select' })

    function onConnect() {
      console.log('connected')
      sio?.emit(events.POPULATE_CHATLIST)
      sio?.emit(events.CHECKIN)
      intervalID = setInterval(() => sio?.emit(events.CHECKIN), 45000)
      console.log(intervalID)
    }

    function onPopulateChatList(inboxes: string[]) {
      console.log('populate chat list ', inboxes)
      dispatchChatList(inboxes)
    }

    function connectPhysician(inbox: string) {
      dispatchChatList(inbox)
      updateCurrentInbox({ item: JSON.parse(inbox), action: 'update' })
      const inboxObj = JSON.parse(inbox)
      sio?.emit(events.JOIN, inboxObj.id)
    }

    function disconnectPhysician(inbox: string) {
      const inboxObj: Inbox = JSON.parse(inbox)
      updateCurrentInbox({ item: inboxObj, action: 'update' })
      sio?.emit(events.LEAVE, inboxObj.id)
    }

    function onDisconnect() {
      sio?.off()
      clearInterval(intervalID)
      console.log('disconnect...' + intervalID)
    }

    function onSend(message: string) {
      dispatchChatMessages(message)
    }

    function onPopulateMsg(messageList: Array<string>) {
      console.log(messageList)
      dispatchChatMessages(messageList)
    }

    if (chatOn) {
      sio?.off()

      sio?.on(events.CONNECT, onConnect)
      sio?.on(events.DISCONNECT, onDisconnect)
      sio?.on(events.POPULATE_CHATLIST, onPopulateChatList)
      sio?.on(events.POPULATE_MSG, onPopulateMsg)
      sio?.on(events.SEND, onSend)
      sio?.on(events.CONNECT_PHYS, connectPhysician)
      sio?.on(events.DISCONNECT_PHYS, disconnectPhysician)

      if (sio?.connected) {
        console.log('connected, no need to reconnect')
      } else {
        console.log('attempting to connect')
        sio?.connect()
      }
    } else {
      sio?.close()
      sio?.off()
      dispatchChatList([])
      dispatchChatMessages([])
    }

    return () => {
      sio?.disconnect()
      sio?.off()
    }
  }, [chatOn])

  const selectInbox = (inbox: Inbox) => {
    console.log(inbox)
    const { id: inboxId } = inbox
    sio?.emit(events.POPULATE_MSG, { inboxId })
    updateCurrentInbox({ item: inbox, action: 'select' })
  }

  const sendMessage = () => {
    console.log('send msg in the current inbox')
    const messageObj = {
      inboxId: currentInbox?.id,
      emergency: true,
      text: text,
      requireChatBot: false,
    }
    console.log(messageObj)
    sio?.emit(events.SEND, messageObj)
    setText('')
  }

  useEffect(() => {
    chatBoxScrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
  }, [chatMessages])

  const attending = currentInbox?.attended_by.id === currentUser.id || false

  return (
    <div className='container'>
      <div style={{ display: 'flex' }}>
        <div className='chat-ui-container'>
          <div className='inbox-container'>
            <div className='inbox-header'>
              <span>Chat</span>
              <Switch defaultChecked checked={chatOn} onChange={setChatOn} />
            </div>
            <div className='inbox-list'>
              <List
                dataSource={chatList}
                renderItem={(item, index) => (
                  <InboxItem item={item} index={index} onSelect={selectInbox} selectedItemId={currentInbox?.id} />
                )}
              />
            </div>
          </div>
        </div>
        <div className='chat-ui-container' style={{ flex: 1 }}>
          <div className='yet-another-container'>
            <div className='chatbox-header-ribbon'>
              <div className='chatbox-header'>
                <div className='info'>
                  <img src={currentInbox?.owner.avatar || '/assets/icon/patient-default-avatar-blue.png'} />
                  <div className='title'>
                    <span className='name'>{currentInbox?.owner.first_name || '...'}</span>
                    <span className='sub'>Patient</span>
                  </div>
                </div>
                <SearchOutlined className='search' />
              </div>
              <div className='chatbox-ribbon'>
                <Switch checked={attending} size='small' className='ribbon-switch' disabled={true} />
                <span className='ribbon-text'>Connect Care Crew</span>
              </div>
            </div>
            <div className='chatbox-messages'>
              <List
                dataSource={chatMessages.slice().reverse()}
                renderItem={(item, index) => <ChatBubble item={item} index={index} currentUserId={currentUser.id} />}
              />
              <div style={{ width: 0, height: 0 }} ref={chatBoxScrollRef}></div>
            </div>
            <div className='input-container'>
              <Input
                value={text}
                onChange={(event) => setText(event.target.value)}
                placeholder={
                  currentInbox == null
                    ? 'Select an inbox'
                    : attending
                      ? 'Enter a message'
                      : 'You are not attending this inbox'
                }
                className='inputbox'
                onPressEnter={sendMessage}
                disabled={!attending}
              />
              <Button onClick={sendMessage} icon={<SendOutlined />} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function InboxItem({
  item,
  index,
  onSelect,
  selectedItemId,
}: {
  item: Inbox
  index: number
  onSelect: (arg0: Inbox) => void
  selectedItemId: string | undefined
}) {
  const style =
    item.id === selectedItemId
      ? { padding: '0.8rem', borderStyle: 'none', backgroundColor: '#e3fcec' }
      : { padding: '0.8rem', borderStyle: 'none' }
  return (
    <List.Item style={style} className='chat-item' onClick={() => onSelect(item)}>
      <List.Item.Meta
        avatar={
          <Avatar src={item.owner.avatar ? item.owner.avatar : '/assets/icon/patient-default-avatar-blue.png'} />
        }
        title={item.owner.first_name}
        description={<Typography.Text>{/* add the last message received */}</Typography.Text>}
      />
    </List.Item>
  )
}

function ChatBubble({ item, index, currentUserId }: { item: Message; index: number; currentUserId?: string }) {
  const classUser = item.sender.id == currentUserId ? 'user' : 'other'
  const classUrgent = item.emergency ? 'emergency' : 'normal'
  return (
    <div className={`chat-bubble-container ${classUser}`}>
      <div className={`chat-bubble ${classUser} ${classUrgent}`}>
        <div className='bubble-header'>
          <span className='sender'>{item.sender.first_name}</span>
        </div>
        <div className='bubble-body'>{item.text}</div>
        <div className='bubble-footer'>
          <span className='datetime'>{item.created_at}</span>
        </div>
      </div>
    </div>
  )
}
