import './RecentPatients.css'
import React, { useEffect, useState } from 'react'
import { Button, Table, Tooltip } from 'antd'
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'

interface DataType {
  key: string
  firstName: string
  userName: string
}

const GET_RECENT_PATIENTS = gql`
  query GetRecentPatients {
    physician {
      patients {
        firstName
        userName
      }
    }
  }
`

export default function RecentPatients() {
  const navigate = useNavigate()
  const [cookies] = useCookies(['loginToken'])

  const paginationConfig: TablePaginationConfig = {
    position: ['bottomCenter'],
  }

  const { loading, error, data } = useQuery(GET_RECENT_PATIENTS, {
    context: {
      headers: {
        Authorization: cookies.loginToken,
      },
    },
  })

  const [patients, setPatients] = useState<DataType[]>([])

  useEffect(() => {
    if (!loading && data && data.physician && Array.isArray(data.physician.patients)) {
      const patientsData: DataType[] = data.physician.patients.map((patient: any, index: number) => ({
        key: index.toString(),
        firstName: patient.firstName,
        userName: patient.userName,
      }))
      setPatients(patientsData)
    }
  }, [loading, data])

  const handleAction = (userName: number) => {
    //handle the action of view
    navigate(`/patient/${userName}`)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Patient UID',
      dataIndex: 'userName',
      key: 'userName',
      responsive: ['md'],
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: any) => (
        <Tooltip title='View' placement='top'>
          <Button
            type='text'
            onClick={() => handleAction(record.userName)}
            icon={<EyeOutlined />}
            style={{
              width: '32px',
              height: '32px',
              padding: '0px 16px',
              borderRadius: '5px',
              border: '1px solid #38C172',
              gap: '8px',
              color: '#38C172',
            }}></Button>
        </Tooltip>
      ),
    },
  ]

  if (error) {
    console.error(error)
    return null
  }

  return (
    <Table
      columns={columns}
      dataSource={Array.isArray(patients) ? patients : []}
      scroll={{ y: 300 }}
      pagination={paginationConfig}
      rowClassName={() => 'custom-row-class'}
      components={{
        body: {
          row: ({ children, ...restProps }: { children: React.ReactNode; [key: string]: any }) => (
            <tr className='custom-row-class' {...restProps}>
              {children}
            </tr>
          ),
        },
      }}
    />
  )
}
