import '../index.css'
import * as React from 'react'
import { useContext, useState } from 'react'
import { Button, Pagination, Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { isMobile } from 'react-device-detect'
import { CheckOutlined } from '@ant-design/icons'
import { InfoCxt } from '../../../App'
import {
  ContentHeader,
  PaddingBox,
  PageBreadcrumb,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'
import mockData from './mockData.json'

interface SelectMedicationListDataType {
  id: string
  drugName: string
  dose: string
  type: string
}

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<SelectMedicationListDataType> = [
  {
    title: 'Medication Name',
    dataIndex: 'drugName',
    // width: '300px',
    sorter: (a, b) => a.drugName.localeCompare(b.drugName),
  },
  {
    title: 'Dosage',
    dataIndex: 'dose',
    // width: '300px',
    sorter: (a, b) => a.dose.localeCompare(b.dose),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    // width: '300px',
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
]

/* --------------------------------- main fc -------------------------------- */

const PlanSelect: React.FC = () => {
  const { showComingSoon } = useContext(InfoCxt)
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <>
      <ContentHeader white={false}>
        <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient', 'Select Medication']} />
        <TabHeader padding={false}>
          <PageTitle text='Select Medication' />
        </TabHeader>
      </ContentHeader>
      <PaddingBox>
        <PageSubtitle text='Medication List' />
        <TableBox>
          <Table
            columns={[
              ...columns,
              {
                title: 'Action',
                width: '116px',
                render: () => (
                  <Space size='middle'>
                    <Button type='primary' ghost icon={<CheckOutlined />} onClick={showComingSoon} />
                  </Space>
                ),
              },
            ]}
            dataSource={mockData.medication_select_data}
            rowKey={(record) => record.id}
            pagination={{
              position: ['none'],
              current: currentPage,
            }}
          />
        </TableBox>
        <TablePaginationBox>
          <Pagination
            current={currentPage}
            total={mockData.medication_select_data.length}
            pageSize={10}
            showLessItems={isMobile}
            showSizeChanger={false}
            onChange={(page) => setCurrentPage(page)}
          />
        </TablePaginationBox>
      </PaddingBox>
    </>
  )
}

export default PlanSelect
