import dayjs from 'dayjs'

/* ------------------------- original api data types ------------------------ */

type BasicData = {
  recordDateList: string[]
}

type BloodGlucoseData = BasicData & {
  readingList: number[]
}

type BloodPressureData = BasicData & {
  heartRateReadingList: number[]
  bloodPressureReadingLowerList: number[]
  bloodPressureReadingUpperList: number[]
}

type WeightData = BasicData & {
  readingList: number[]
}

type FoodIntakeData = BasicData & {
  carbsPercent: number
  proteinPercent: number
  fatPercent: number
  caloriesList: number[]
}

type ActivityReportData = BasicData & {
  stepReadingList: number[]
  activeMinutesReadingList: number[]
  caloriesReadingList: number[]
}

/* ------------------------------- chart types ------------------------------ */

type BasicChartData = {
  recordDate: string
  value: number
}

type ChartDataWithType = BasicChartData & {
  type: string
}

/* --------------------- parser for general report data --------------------- */

type AllData = Partial<ActivityReportData & FoodIntakeData & BloodGlucoseData & BloodPressureData & WeightData>
type KA = keyof AllData

const parseReportData = (data: AllData, valueName: KA, start: dayjs.Dayjs, end: dayjs.Dayjs): BasicChartData[] => {
  const n = end.diff(start, 'day') + 1
  let i = 0
  const res: BasicChartData[] = new Array(n).fill(0).map((_, idx) => {
    const d = start.add(idx, 'day').format('YYYY-MM-DD')
    let value = 0
    if (data.recordDateList?.length) {
      if (i < data.recordDateList?.length && dayjs(data.recordDateList[i]).format('YYYY-MM-DD') === d) {
        value = (data[valueName] as number[])[i]
        i += 1
      }
    }
    return {
      recordDate: d,
      value: value,
    }
  })
  return res
}

/* -------------------- parser for report data with type -------------------- */

const parseReportDataWithType = (
  data: AllData,
  valueNames: KA[],
  typeNames: string[],
  start: dayjs.Dayjs,
  end: dayjs.Dayjs
): ChartDataWithType[] => {
  const n = end.diff(start, 'day') + 1
  const count = valueNames.length
  const res: ChartDataWithType[] = []
  for (let j = 0; j < count; j += 1) {
    let i = 0
    const one: ChartDataWithType[] = new Array(n).fill(0).map((_, idx) => {
      const d = start.add(idx, 'day').format('YYYY-MM-DD')
      let value = 0
      if (data.recordDateList?.length) {
        if (i < data.recordDateList?.length && dayjs(data.recordDateList[i]).format('YYYY-MM-DD') === d) {
          value = (data[valueNames[j]] as number[])[i]
          i += 1
        }
      }
      return {
        recordDate: d,
        value: value,
        type: typeNames[j],
      }
    })
    res.push(...one)
  }
  return res
}

export type { KA }
export { parseReportData, parseReportDataWithType }
