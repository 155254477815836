import '../index.css'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Avatar, Button, Divider, Statistic, Switch, Typography } from 'antd'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import { EyeOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'
import { PageProps } from '../'
import PanelDrawerAllergies from './panelDrawerAllergies'
import PanelDrawerMedical from './panelDrawerMedical'

const { Text } = Typography

const QUERY_PATIENT = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        firstName
        age
        birthday
        userName
        gender
        patientDetail {
          priority
          medicineAllergies {
            medicine
            allergies {
              symptom
            }
          }
          medicalConditions {
            title
            content
          }
        }
        lastActive
      }
    }
  }
`

const MUTATION_PRIORITY = gql`
  mutation ($username: String!, $toggle: Boolean!) {
    profileMutations {
      updatePatientPriority(priorityInput: { setPriority: $toggle, patientUsername: $username }) {
        status
        message {
          title
          icon
          content
        }
      }
    }
  }
`

const fontStyle: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: 600,
}

/* ----------------------------------- fc ----------------------------------- */

const PanelDivider = () => {
  if (isMobile) return <Divider type='horizontal' style={{ flexBasis: '100%', margin: '8px 0' }} />
  return <Divider type='vertical' style={{ height: '48px', margin: '0 20px' }} />
}

const Panel: React.FC<PageProps & { setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  userName,
  loginToken,
  setReady,
}) => {
  const { loading, data: queryData } = useQuery(QUERY_PATIENT, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })

  useEffect(() => {
    if (!loading) {
      setReady(true)
    }
  }, [loading, setReady])

  const patientInfo = queryData?.physician?.queryPatient || null
  // if (patientInfo) console.log(11, patientInfo)
  const [isPriority, setIsPriority] = useState<boolean>(patientInfo?.patientDetail?.priority || false)

  const [togglePriority, { data: toggleData, loading: toggleLoading }] = useMutation(MUTATION_PRIORITY, {
    variables: { username: userName, toggle: !isPriority },
    context: { headers: { Authorization: loginToken } },
  })

  useEffect(() => {
    if (patientInfo?.patientDetail?.priority) {
      setIsPriority(true)
    }
  }, [patientInfo])

  useEffect(() => {
    if (toggleData?.profileMutations?.updatePatientPriority?.status) {
      setIsPriority((value) => !value)
    }
  }, [toggleData])

  const [allergiesDrawer, setAllergiesDrawer] = useState(false)
  const [medicalDrawer, setMedicalDrawer] = useState(false)

  const showAllergiesDrawer = useCallback(() => {
    setAllergiesDrawer(true)
  }, [setAllergiesDrawer])

  const closeAllergiesDrawer = useCallback(() => {
    setAllergiesDrawer(false)
  }, [setAllergiesDrawer])

  const showMedicalDrawer = useCallback(() => {
    setMedicalDrawer(true)
  }, [setMedicalDrawer])

  const closeMedicalDrawer = useCallback(() => {
    setMedicalDrawer(false)
  }, [setMedicalDrawer])

  return (
    <>
      {/* drawers */}
      <PanelDrawerAllergies isOpen={allergiesDrawer} closeDrawer={closeAllergiesDrawer} />
      <PanelDrawerMedical isOpen={medicalDrawer} closeDrawer={closeMedicalDrawer} />
      {/* main */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'flex-start' : 'space-between',
          alignItems: isMobile ? 'flex-start' : 'center',
          gap: 16,
          backgroundColor: '#fff',
        }}>
        {/* avatar */}
        <div className='avatar_grid'>
          <Avatar
            size={64}
            className='avatar'
            src={
              patientInfo?.gender
                ? '/assets/icon/patient-default-avatar-' + (patientInfo?.gender === 'male' ? 'blue' : 'red') + '.png'
                : ''
            }
          />
          <div className='row_flex_bottom'>
            <Text>{patientInfo?.firstName || '-'}</Text>
          </div>
          <Text type='secondary' style={{ fontSize: '12px' }}>
            {patientInfo ? 'Patient' : '-'}
          </Text>
        </div>
        {/* data */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}>
          <Statistic
            title='Age'
            value={patientInfo?.age || '-'}
            valueStyle={fontStyle}
            style={{ flexBasis: isMobile ? '50%' : 'content' }}
          />
          {!isMobile && <PanelDivider />}
          <Statistic
            title='DoB'
            value={patientInfo?.birthday ? dayjs(patientInfo.birthday).format('MM / DD / YYYY') : '-'}
            valueStyle={fontStyle}
            style={{ flexBasis: isMobile ? '50%' : 'content' }}
          />
          <PanelDivider />
          <Statistic
            title='Gender'
            value={patientInfo?.gender ? (patientInfo?.gender === 'male' ? 'M' : 'F') : '-'}
            valueStyle={fontStyle}
            style={{ flexBasis: isMobile ? '100%' : 'content' }}
          />
          <PanelDivider />
          <Statistic
            title='Allergies'
            value={patientInfo?.patientDetail?.medicineAllergies?.length || 0}
            valueStyle={fontStyle}
            style={{ flexBasis: isMobile ? '100%' : 'content' }}
            formatter={(value) => {
              return (
                <div
                  className='row_flex_v_center'
                  style={{ ...fontStyle, width: isMobile ? 'calc(100vw - 32px)' : 'auto' }}>
                  {value}
                  <Button type='primary' ghost size='small' icon={<EyeOutlined />} onClick={showAllergiesDrawer}>
                    View
                  </Button>
                </div>
              )
            }}
          />
          <PanelDivider />
          <Statistic
            title='Medical Condition'
            value={patientInfo?.patientDetail?.medicalConditions?.length || 0}
            valueStyle={fontStyle}
            style={{ flexBasis: isMobile ? '100%' : 'content' }}
            formatter={(value) => {
              return (
                <div
                  className='row_flex_v_center'
                  style={{ ...fontStyle, width: isMobile ? 'calc(100vw - 32px)' : 'auto' }}>
                  {value}
                  <Button type='primary' ghost size='small' icon={<EyeOutlined />} onClick={showMedicalDrawer}>
                    View
                  </Button>
                </div>
              )
            }}
          />
          <PanelDivider />
          <Statistic
            title='Priority Queue'
            value={isPriority ? 'Yes' : 'No'}
            valueStyle={fontStyle}
            style={{ flexBasis: isMobile ? '100%' : 'content' }}
            formatter={(value) => {
              return (
                <div
                  className='row_flex_v_center'
                  style={{ ...fontStyle, width: isMobile ? 'calc(100vw - 32px)' : 'auto' }}>
                  <div>{value}</div>
                  <Switch
                    disabled={toggleLoading}
                    size='small'
                    checked={isPriority}
                    onClick={() => {
                      togglePriority()
                    }}
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Panel
