import * as React from 'react'
import { useCallback, useContext, useEffect } from 'react'
import { Alert, Button, Card, Checkbox, Col, Form, Image, Input, Layout, Row, Typography } from 'antd'
import { useCookies } from 'react-cookie'
import { useNavigate, Link } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { InfoCxt } from '../../App'

const { Title, Text } = Typography

const { Footer, Content } = Layout

const LOGIN = gql`
  mutation ($username: String!, $password: String!) {
    authMutations {
      login(model: PHYSICIAN, username: $username, password: $password) {
        status
        message {
          title
          icon
          content
        }
        token
      }
    }
  }
`

const Login = () => {
  const { showComingSoon } = useContext(InfoCxt)
  const navigate = useNavigate()
  const [, setCookie] = useCookies(['loginToken'])

  const [fnLogin, { data, loading }] = useMutation(LOGIN)

  const submit = useCallback(
    (values: { username: string; password: string; remember: boolean }) => {
      fnLogin({ variables: { username: values.username, password: values.password } })
    },
    [fnLogin]
  )

  useEffect(() => {
    if (data && data.authMutations.login.status) {
      const token = data.authMutations.login.token
      setCookie('loginToken', token)
      setTimeout(() => {
        // Note: always redirect to / after successful login.
        navigate('/')
      }, 1000)
    }
  }, [data, setCookie, navigate])

  return (
    <Layout className='login_container'>
      <Content>
        <Row>
          <Col className='row_center' span={24}>
            <Card className='card_container' styles={{body: { padding: 0 }}}>
              <Row gutter={[0, 24]} style={{ width: '100%' }}>
                <Col span={24} className='row_center'>
                  <Image preview={false} width='106px' src='/assets/icon/login-title.png' />
                </Col>

                <Col span={24} className='row_center'>
                  <Image preview={false} width='150px' src='/assets/icon/login-identity-card.png' />
                </Col>

                <Col span={24}>
                  <Title level={4} style={{ marginTop: 0 }}>
                    Sign in to Empower+
                  </Title>
                  <Text>Not your device? Use a private or incognito window to sign in.</Text>
                </Col>

                {data ? (
                  !(data.authMutations.login.status === true) ? (
                    <Col span={24}>
                      <Alert showIcon message='Incorrect username or password.' type='error' />
                    </Col>
                  ) : (
                    <Col span={24}>
                      <Alert showIcon message='Login success!' type='success' />
                    </Col>
                  )
                ) : null}

                <Col span={24}>
                  <Form
                    name='basic'
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={submit}
                    // onFinishFailed={() => void}
                    autoComplete='off'>
                    <Form.Item name='username' rules={[{ required: true, message: 'Please input your username.' }]}>
                      <Input prefix={<UserOutlined />} placeholder='Username' />
                    </Form.Item>

                    <Form.Item name='password' rules={[{ required: true, message: 'Please input your password.' }]}>
                      <Input.Password prefix={<LockOutlined />} placeholder='Password' />
                    </Form.Item>

                    <Form.Item>
                      <div className='row_space_between'>
                        <Form.Item name='remember' valuePropName='checked' noStyle>
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <Link style={{ color: '#38C172' }} to='/forget-password'>
                          Forgot password
                        </Link>
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <Button block type='primary' htmlType='submit' loading={loading}>
                        Submit
                      </Button>
                    </Form.Item>

                    <div className='row_center'>
                      <Link style={{ color: '#38C172' }} onClick={showComingSoon} to='#'>
                        Create account
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer className='footer'>© Empower Plus. All Rights Reserved.</Footer>
    </Layout>
  )
}

export default Login
