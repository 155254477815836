import '../index.css'
import * as React from 'react'
import { useContext, useState } from 'react'
import {
  App as AntdApp,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Pagination,
  Select,
  Statistic,
  Table,
  TimePicker,
  Typography,
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { isMobile } from 'react-device-detect'
import { CheckOutlined, DeleteOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { InfoCxt } from '../../../App'
import CartEmpty from '../../../components/Empty/CartEmpty'
import { Loading } from '../../../components/Loading/defaultLoading'
import {
  ContentHeader,
  PaddingBox,
  PageBreadcrumb,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'
import { KNOW_ALLERGIES } from './taken'

const { Text } = Typography

const valueStyle: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: 600,
}

type PageType = 'select_list' | 'add_form' | 'edit_form'

const QUERY_MED_LIST = gql`
  query {
    allMedications {
      id
      dose
      drugName
      type
    }
  }
`

interface TakenSelectDataType {
  id: string
  drugName: string
  dose: string
  type: string
  // quantity?: number
  // allergies?: string[]
}

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<TakenSelectDataType> = [
  {
    title: 'Medication Name',
    dataIndex: 'drugName',
    // width: '300px',
    sorter: (a, b) => a.drugName.localeCompare(b.drugName),
  },
  {
    title: 'Dosage',
    dataIndex: 'dose',
    // width: '300px',
    sorter: (a, b) => a.dose.localeCompare(b.dose),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    // width: '300px',
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
]

const PanelDivider: React.FC<{ last?: boolean }> = ({ last }) => {
  if (isMobile)
    return <Divider type='horizontal' style={{ flexBasis: '100%', margin: `${last ? '8px 0 0 0' : '8px 0'}` }} />
  return <Divider type='vertical' style={{ height: '48px', margin: '0 20px' }} />
}

/* ----------------------------- select list fc ----------------------------- */

type TakenSelectProps = {
  data: TakenSelectDataType[]
  selectedDrugs: React.Key[]
  setSelectedDrugs: React.Dispatch<React.SetStateAction<React.Key[]>>
  setPage: React.Dispatch<React.SetStateAction<PageType>>
}

const TakenSelect: React.FC<TakenSelectProps> = ({ data, selectedDrugs, setSelectedDrugs, setPage }) => {
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <>
      <ContentHeader white={false}>
        <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient', 'Select Medication']} />
        <TabHeader padding={false}>
          <PageTitle text='Select Medication' />
          {isMobile ? (
            <Button
              type='primary'
              icon={<CheckOutlined />}
              onClick={() => {
                setPage('add_form')
              }}
            />
          ) : (
            <Button
              type='primary'
              icon={<CheckOutlined />}
              onClick={() => {
                setPage('add_form')
              }}>
              Confirm Selection
            </Button>
          )}
        </TabHeader>
      </ContentHeader>
      <PaddingBox>
        <PageSubtitle text='Medication List' />
        <TableBox>
          <Table
            rowSelection={{
              selectedRowKeys: selectedDrugs,
              onChange: setSelectedDrugs,
              fixed: true,
            }}
            columns={[...columns]}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={{
              position: ['none'],
              current: currentPage,
            }}
          />
        </TableBox>
        <TablePaginationBox>
          <Pagination
            current={currentPage}
            total={data.length}
            pageSize={10}
            showLessItems={isMobile}
            showSizeChanger={false}
            onChange={(page) => setCurrentPage(page)}
          />
        </TablePaginationBox>
      </PaddingBox>
    </>
  )
}

/* --------------------------------- form fc -------------------------------- */

type TakenAddProps = TakenSelectProps & {
  page?: PageType
}

const TakenAdd: React.FC<TakenAddProps> = ({ data, selectedDrugs, setSelectedDrugs, setPage, page }) => {
  const { modal } = AntdApp.useApp()
  const [form] = Form.useForm()

  const statisticData = new Map<string, TakenSelectDataType>()
  data.map((item) => {
    statisticData.set(item.id, item)
  })

  const { showComingSoon } = useContext(InfoCxt)

  const showDeleteConfirm = (id: string) => {
    modal.confirm({
      title: 'Confirm Deletion',
      icon: <ExclamationCircleFilled style={{ color: '#FF5500' }} />,
      content: <div style={{ paddingBottom: '16px' }}>Are you sure you want to delete this item?</div>,
      okText: 'Confirm',
      okButtonProps: { type: 'primary' },
      cancelText: 'Cancel',
      onOk() {
        setSelectedDrugs((value) => value.filter((a) => a !== id))
      },
    })
  }

  const onAdd = () => {
    form
      .validateFields()
      .then(() => {
        console.log(11, form.getFieldsValue())
        showComingSoon()
      })
      .catch(() => {
        // console.log(44, e)
      })
  }

  const onDelete = () => {
    showComingSoon()
  }

  const onSave = onAdd

  const _header = `${page !== 'edit_form' ? 'Add' : 'Edit'} Medication Log`

  return (
    <>
      <ContentHeader white={false}>
        <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient', 'Select Medication', _header]} />
        <TabHeader padding={false}>
          <PageTitle text={_header} />
        </TabHeader>
      </ContentHeader>
      <PaddingBox white>
        <div className='full_page_form_container'>
          <Form name='taken_add' autoComplete='off' form={form} component={false}>
            {/* part */}
            <PageSubtitle padding={false} text='Date & Time' />
            {/* item */}
            <div className='form_item'>
              <div className={isMobile ? 'form_item_grid_container_mobile' : 'form_item_grid_container'}>
                <div className='vertical_flex' style={{ gap: '4px' }}>
                  <Text strong>Date</Text>
                  <Text type='secondary'>Input the date at which the medication was taken.</Text>
                </div>
                <Form.Item
                  name='log_date'
                  rules={[{ required: true, message: 'Please input date' }]}
                  style={{ marginBottom: 0 }}>
                  <DatePicker
                    inputReadOnly
                    allowClear={false}
                    format={'DD/MM/YYYY'}
                    style={{ width: '100%' }}
                    placeholder='Select Date'
                  />
                </Form.Item>
              </div>
              <Divider style={{ margin: 0 }} />
            </div>
            {/* item */}
            <div className='form_item'>
              <div className={isMobile ? 'form_item_grid_container_mobile' : 'form_item_grid_container'}>
                <div className='vertical_flex' style={{ gap: '4px' }}>
                  <Text strong>Time</Text>
                  <Text type='secondary'>Input the time at which the medication was taken.</Text>
                </div>
                <Form.Item
                  name='log_time'
                  rules={[{ required: true, message: 'Please input time' }]}
                  style={{ marginBottom: 0 }}>
                  <TimePicker
                    inputReadOnly
                    allowClear={false}
                    format={'hh:mm a'}
                    style={{ width: '100%' }}
                    placeholder='Select Time'
                  />
                </Form.Item>
              </div>
              <Divider style={{ margin: 0 }} />
            </div>
            {/* part */}
            <div className='stretch_flex'>
              <PageSubtitle padding={false} text='Selected Medication' />
              {page !== 'edit_form' &&
                (isMobile ? (
                  <Button
                    type='primary'
                    ghost
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setPage('select_list')
                    }}
                  />
                ) : (
                  <Button
                    type='primary'
                    ghost
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setPage('select_list')
                    }}>
                    Select Medication
                  </Button>
                ))}
            </div>
            {/* item */}
            {selectedDrugs.length === 0 ? (
              <div style={{ width: '100%' }}>
                <CartEmpty />
              </div>
            ) : (
              <>
                {selectedDrugs.map((item) => {
                  return (
                    // item
                    <div className='form_item' key={item}>
                      <div className='from_left_flex'>
                        <div
                          className='from_left_flex'
                          style={{ gap: '8px', flexBasis: isMobile ? '100%' : 'content' }}>
                          <Form.Item
                            name={`log_quantity_${item}`}
                            initialValue={1}
                            rules={[{ required: true, message: 'Please input quantity' }]}
                            hasFeedback
                            style={{ marginBottom: 0, flexGrow: 1 }}>
                            <InputNumber min={1} style={{ width: '100%' }} />
                          </Form.Item>
                          <Button
                            danger
                            ghost
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              showDeleteConfirm(item as string)
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexBasis: isMobile ? '100%' : 'content',
                          }}>
                          <Statistic
                            title='Medication Name'
                            valueStyle={valueStyle}
                            value={statisticData.get(item as string)?.drugName || '-'}
                            style={{ flexBasis: isMobile ? '100%' : 'content' }}
                          />
                          <PanelDivider />
                          <Statistic
                            title='Dosage'
                            valueStyle={valueStyle}
                            value={statisticData.get(item as string)?.dose || '-'}
                            style={{ flexBasis: isMobile ? '100%' : 'content' }}
                          />
                          <PanelDivider />
                          <Statistic
                            title='Type'
                            valueStyle={valueStyle}
                            value={statisticData.get(item as string)?.type || '-'}
                            style={{ flexBasis: isMobile ? '100%' : 'content' }}
                          />
                          {isMobile && <PanelDivider last />}
                        </div>
                      </div>
                      <PageSubtitle padding={false} text='Reported Allergies' />

                      <div className={isMobile ? 'form_item_grid_container_mobile' : 'form_item_grid_container'}>
                        <div className='vertical_flex' style={{ gap: '4px' }}>
                          <Text strong>Allergies</Text>
                          <Text type='secondary'>List any known allergies.</Text>
                        </div>
                        <Form.Item name={`log_allergies_${item}`} style={{ marginBottom: 0 }}>
                          <Select
                            mode='multiple'
                            allowClear
                            style={{ width: '100%' }}
                            placeholder='Select Allergies'
                            options={KNOW_ALLERGIES.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                          />
                        </Form.Item>
                      </div>
                      {/* form item dependencies */}
                      <Form.Item dependencies={[`log_allergies_${item}`]} noStyle>
                        {({ getFieldValue }) => {
                          const value = getFieldValue(`log_allergies_${item}`)

                          if (value && (value as string[]).indexOf('Others') !== -1)
                            return (
                              <div
                                className={isMobile ? 'form_item_grid_container_mobile' : 'form_item_grid_container'}>
                                <div className='vertical_flex' style={{ gap: '4px' }}>
                                  <Text strong>Other Allergy</Text>
                                  <Text type='secondary'>Please specify the allergy that is not listed.</Text>
                                </div>
                                <Form.Item
                                  name={`log_allergies_others_${item}`}
                                  dependencies={[`log_allergies_${item}`]}
                                  rules={[{ required: true, message: 'Please input custom allergy' }]}
                                  hasFeedback
                                  style={{ marginBottom: 0 }}>
                                  <Input placeholder='Please Specify' />
                                </Form.Item>
                              </div>
                            )

                          return null
                        }}
                      </Form.Item>
                      <Divider style={{ margin: 0 }} />
                    </div>
                  )
                })}
              </>
            )}
            {/* footer */}
            <div className='form_footer_container'>
              <Text strong>Ready to Submit?</Text>
              <Text type='secondary' style={{ marginTop: '4px' }}>
                {`Review your filled form details and make sure everything is accurate. Once you're ready, click the 'Add Log' button to add the new medication log.`}
              </Text>
              <Divider style={{ margin: '16px 0' }} />
              <div className='right_flex'>
                {page !== 'edit_form' ? (
                  <>
                    <Button style={{ flexBasis: isMobile ? '100%' : 'content' }} type='primary' onClick={onAdd}>
                      Add Log
                    </Button>
                  </>
                ) : (
                  <div style={{ display: 'flex', gap: 8, width: '100%' }}>
                    <Button style={{ flexBasis: isMobile ? '100%' : 'content' }} danger onClick={onDelete}>
                      Delete
                    </Button>
                    <Button style={{ flexBasis: isMobile ? '100%' : 'content' }} type='primary' onClick={onSave}>
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </PaddingBox>
    </>
  )
}

/* ------------------------------ container fc ------------------------------ */

const TakenAddContainer: React.FC = () => {
  const { token: loginToken } = useContext(InfoCxt)
  const [page, setPage] = useState<PageType>('select_list')
  const [selectedDrugs, setSelectedDrugs] = useState<React.Key[]>([])
  const { loading, data: queryData } = useQuery(QUERY_MED_LIST, {
    context: { headers: { Authorization: loginToken } },
  })

  const formData: TakenSelectDataType[] = queryData?.allMedications || []

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {formData && (
            <>
              {page === 'select_list' && (
                <TakenSelect
                  data={formData}
                  selectedDrugs={selectedDrugs}
                  setSelectedDrugs={setSelectedDrugs}
                  setPage={setPage}
                />
              )}
              {page === 'add_form' && (
                <TakenAdd
                  data={formData}
                  selectedDrugs={selectedDrugs}
                  setSelectedDrugs={setSelectedDrugs}
                  setPage={setPage}
                />
              )}
              {page === 'edit_form' && (
                <TakenAdd
                  data={formData}
                  selectedDrugs={selectedDrugs}
                  setSelectedDrugs={setSelectedDrugs}
                  setPage={setPage}
                  page={'edit_form'}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default TakenAddContainer
