import './index.css'
import { useContext, useEffect, useState } from 'react'
import { Calendar, Col, Row } from 'antd'
import type { CalendarMode } from 'antd/es/calendar/generateCalendar'
import type { Dayjs } from 'dayjs'
import { useCookies } from 'react-cookie'
import { gql, useQuery } from '@apollo/client'
import { InfoCxt } from '../../App'
import { Loading } from '../../components/Loading/defaultLoading'
import RecentPatients from './RecentPatients/RecentPatients'

import { ContentHeader, PaddingBox, PageBreadcrumb, PageSubtitle, PageTitle, TabHeader } from '../../components/Wrapper'

interface SummaryData {
  dimension: string
  amount: number
}

function getSummaryText(dimension: string): string {
  switch (dimension) {
    case 'NEW_PATIENTS':
      return 'New Patient'
    case 'DIABETIC_PATIENTS':
      return 'Diabetic Patients'
    case 'PATIENTS_AT_HYPERGLYCEMIA_RISK':
      return 'Hyperglycemia Risk'
    case 'PATIENTS_AT_HYPOGLYCEMIA_RISK':
      return 'Hypoglycemia Risk'
    default:
      return ''
  }
}

const GET_PATIENT_SUMMARY = gql`
  query GetPatientSummary {
    physician {
      patientSummary {
        dimension
        amount
      }
    }
  }
`

export default function DashBoard() {
  // get all cookies with dependency on 'loginToken'
  const [cookies] = useCookies(['loginToken'])

  const userInfo = useContext(InfoCxt)
  let name = ''
  if (userInfo && userInfo.firstName && userInfo.title) {
    name = `${userInfo.title} ${userInfo.firstName}`
  } else if (userInfo && userInfo.firstName) {
    name = `${userInfo.firstName}`
  }

  const { loading, error, data } = useQuery(GET_PATIENT_SUMMARY, {
    context: {
      headers: {
        Authorization: cookies.loginToken ? `Bearer ${cookies.loginToken}` : '',
      },
    },
  })

  const [summary, setSummary] = useState<SummaryData[]>([])

  useEffect(() => {
    if (!loading && data && data.physician && Array.isArray(data.physician.patientSummary)) {
      setSummary(data.physician.patientSummary)
    }
  }, [loading, data])

  if (error) {
    console.error(error)
    return null
  }

  const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
    console.log(value.format('YYYY-MM-DD'), mode)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className='dash-board-page'>
      <ContentHeader white={false} border>
        <PageBreadcrumb path={['Home']} />
        <TabHeader padding={false}>
          <PageTitle text={`Hello, ${name}`} />
        </TabHeader>
      </ContentHeader>
      {/* Summary */}
      <PaddingBox>
        <PageSubtitle text='Summary' />
        <Row gutter={[8, 8]}>
          {summary.map((item) => (
            <Col xs={24} sm={12} md={6} key={item.dimension}>
              <div className='content-flex'>
                <div className='content-flex-1'>
                  <div className='text'>{getSummaryText(item.dimension)}</div>
                  <div className='num'>{item.amount}</div>
                </div>
                <div>
                  <img
                    className='new-patient-img'
                    src={`/assets/icon/icon-${item.dimension.toLowerCase()}.svg`}
                    alt='icon'
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
          <Col xs={24} md={12}>
            <PageSubtitle text='Recent Patients' />
            <RecentPatients />
          </Col>
          <Col xs={24} md={12}>
            {/* Appointments */}
            <PageSubtitle text='Appointments' />
            <Calendar fullscreen={false} onPanelChange={onPanelChange} />
          </Col>
        </Row>
      </PaddingBox>
    </div>
  )
}
