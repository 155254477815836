import '../index.css'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import {
  App as AntdApp,
  Button,
  Divider,
  Form,
  Image,
  message,
  Pagination,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { CheckOutlined, DeleteOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { PatientCtx } from '../'
import { InfoCxt } from '../../../App'
import IconExtLinkOutlined from '../../../components/CustomIcons/ExtLinkOutlined'
import CartEmpty from '../../../components/Empty/CartEmpty'
import { Loading } from '../../../components/Loading/defaultLoading'
import {
  ContentHeader,
  PaddingBox,
  PageBreadcrumb,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'
import { ResourcesProcessedDataType } from './resource'

const { Text } = Typography

type PageType = 'select_list' | 'add_form'

const QUERY_PATIENT_RESOURCE_LIST = gql`
  query ($userName: String!) {
    physician {
      queryPatient(username: $userName) {
        resourceNoRecommend {
          id
          type
          category
          subCategory
          source
          title
          externalUrl
        }
      }
    }
  }
`

const QUERY_SELECTED_RESOURCE = gql`
  query ($resourceIDList: [String]) {
    queryResourceIdList(resourceIdList: $resourceIDList) {
      id
      image
    }
  }
`

const MUTATION_RESOURCE = gql`
  mutation ($patientID: String!, $resourceIDList: [String]) {
    resourceMutations {
      recommendResource(patientId: $patientID, resourceIdList: $resourceIDList) {
        status
        message {
          title
          icon
          content
        }
      }
    }
  }
`

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<ResourcesProcessedDataType> = [
  {
    title: 'Type',
    dataIndex: 'type',
    // width: '300px',
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    // width: '300px',
    sorter: (a, b) => a.category.localeCompare(b.category),
  },
  {
    title: 'Sub Category',
    dataIndex: 'subCategory',
    // width: '300px',
    sorter: (a, b) => a.subCategory.localeCompare(b.subCategory),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    // width: '300px',
    sorter: (a, b) => a.source.localeCompare(b.source),
  },
  {
    title: 'Title',
    dataIndex: 'title',
    // width: '300px',
    sorter: (a, b) => a.title.localeCompare(b.title),
  },
]

/* ----------------------------- select list fc ----------------------------- */

type ResourceSelectProps = {
  data: ResourcesProcessedDataType[]
  selectedResources: React.Key[]
  setSelectedResources: React.Dispatch<React.SetStateAction<React.Key[]>>
  setPageAction: () => void
}

const ResourceSelect: React.FC<ResourceSelectProps> = ({
  data,
  selectedResources,
  setSelectedResources,
  setPageAction,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <>
      <ContentHeader white={false}>
        <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient', 'Select Resources']} />
        <TabHeader padding={false}>
          <PageTitle text='Select Resources' />
        </TabHeader>
      </ContentHeader>
      <PaddingBox white>
        <div className='vertical_flex' style={{ gap: '24px' }}>
          <div className='stretch_flex' style={{ gap: '24px', flexWrap: 'wrap' }}>
            <div className='vertical_flex' style={{ gap: '8px', flexBasis: isMobile ? '100%' : 'content' }}>
              <Text strong style={{ fontSize: '20px' }}>
                Assign From Selection
              </Text>
              <Text type='secondary'>Select one or more resources to assign to the patient.</Text>
            </div>
            <div style={{ flexBasis: isMobile ? '100%' : 'content' }}>
              <Button
                style={{ width: isMobile ? '100%' : 'auto' }}
                type='primary'
                icon={<CheckOutlined />}
                onClick={setPageAction}>
                Confirm Selection
              </Button>
            </div>
          </div>
          <div>
            <TableBox>
              <Table
                rowSelection={{
                  selectedRowKeys: selectedResources,
                  onChange: setSelectedResources,
                  fixed: true,
                }}
                columns={[
                  ...columns,
                  {
                    title: 'Action',
                    width: '116px',
                    render: (_, { externalUrl }) => (
                      <Space size='middle'>
                        <Tooltip title='Open URL'>
                          <Button type='primary' ghost icon={<IconExtLinkOutlined />} href={externalUrl || ''} />
                        </Tooltip>
                      </Space>
                    ),
                  },
                ]}
                dataSource={data}
                rowKey={(record) => record.id}
                pagination={{
                  position: ['none'],
                  current: currentPage,
                }}
              />
            </TableBox>
            <TablePaginationBox>
              <Pagination
                current={currentPage}
                total={data.length}
                pageSize={10}
                showLessItems={isMobile}
                showSizeChanger={false}
                onChange={(page) => setCurrentPage(page)}
              />
            </TablePaginationBox>
          </div>
        </div>
      </PaddingBox>
    </>
  )
}

/* --------------------------------- form fc -------------------------------- */

type ResourceAssignProps = ResourceSelectProps & {
  imageData?: {
    id: string
    image: string
  }[]
  page?: PageType
}

const ResourceAssign: React.FC<ResourceAssignProps> = ({
  imageData,
  data,
  selectedResources,
  setSelectedResources,
  setPageAction,
}) => {
  const { modal } = AntdApp.useApp()
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm()
  const { token: loginToken } = useContext(InfoCxt)
  const { id: patientID, userName } = useContext(PatientCtx)
  const navigate = useNavigate()

  const dataMap = new Map<string, ResourcesProcessedDataType>()
  data.map((item) => {
    dataMap.set(item.id, item)
  })
  const imageDataMap = new Map<string, { id: string; image: string }>()
  imageData?.map((item) => {
    imageDataMap.set(item.id, item)
  })

  const showDeleteConfirm = (id: string) => {
    modal.confirm({
      title: 'Confirm Deletion',
      icon: <ExclamationCircleFilled style={{ color: '#FF5500' }} />,
      content: <div style={{ paddingBottom: '16px' }}>Are you sure you want to delete this item?</div>,
      okText: 'Confirm',
      okButtonProps: { type: 'primary' },
      cancelText: 'Cancel',
      onOk() {
        setSelectedResources((value) => value.filter((a) => a !== id))
      },
    })
  }

  // assign resources
  const [assignResources, { loading: assignLoading, data: callbackData }] = useMutation(MUTATION_RESOURCE, {
    variables: { patientID: patientID, resourceIDList: selectedResources as string[] },
    context: { headers: { Authorization: loginToken } },
  })

  useEffect(() => {
    const st = callbackData?.resourceMutations?.recommendResource
    if (st?.status) {
      messageApi.success('Resources successfully assigned!')
      setTimeout(() => {
        navigate(`/patient/${userName}`)
      }, 1500)
    } else if (st?.status === false) {
      messageApi.error(`${st?.message?.title}. ${st?.message?.content}`)
    }
  }, [callbackData, navigate, userName, messageApi])

  const onAssign = () => {
    form
      .validateFields()
      .then(() => {
        // console.log(11, form.getFieldsValue())
        assignResources()
      })
      .catch(() => {
        // console.log(44, e)
      })
  }

  return (
    <>
      {contextHolder}
      <ContentHeader white={false}>
        <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient', 'Select Resources', 'Assign Resources']} />
        <TabHeader padding={false}>
          <PageTitle text='Assign Resources' />
        </TabHeader>
      </ContentHeader>
      <div className='full_page_form_container'>
        <Form name='taken_add' autoComplete='off' form={form} component={false}>
          {/* part */}
          <div className='stretch_flex'>
            <PageSubtitle text='Selected Resources' padding={false} />
            {isMobile ? (
              <Button type='primary' ghost icon={<PlusOutlined />} size='small' onClick={setPageAction} />
            ) : (
              <Button type='primary' ghost icon={<PlusOutlined />} size='small' onClick={setPageAction}>
                Select Resources
              </Button>
            )}
          </div>
          {/* item */}
          {selectedResources.length === 0 ? (
            <div style={{ width: '100%' }}>
              <CartEmpty />
            </div>
          ) : (
            <>
              {selectedResources.map((item) => {
                const one = dataMap.get(item as string)
                const oneImage = imageDataMap?.get(item as string)?.image

                return (
                  <div className='form_item' key={item}>
                    <div
                      style={{
                        width: '100%',
                        display: isMobile ? 'flex' : 'grid',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        gap: '16px',
                        gridTemplateColumns: '240px 1fr 1fr 32px',
                      }}>
                      <Image
                        width={isMobile ? '100%' : 240}
                        style={{ borderRadius: '5px', flexBasis: '100%' }}
                        src={oneImage ? `data:image/jpeg;base64,${oneImage}` : '/assets/icon/image-placeholder.png'}
                      />
                      <div className='vertical_flex' style={{ gap: '4px', flexBasis: '100%' }}>
                        <Text type='secondary'>{one?.source}</Text>
                        <Text strong style={{ fontSize: '16px' }}>
                          {one?.title}
                        </Text>
                      </div>
                      <Space align='start' wrap size={[0, 8]} style={{ flexBasis: '100%' }}>
                        <Tag color='blue'>{one?.type}</Tag>
                        <Tag color='purple'>{one?.category}</Tag>
                        <Tag color='purple'>{one?.subCategory}</Tag>
                      </Space>
                      {isMobile ? (
                        <div style={{ display: 'flex', gap: '16px', flexBasis: '100%' }}>
                          <Button
                            type='primary'
                            ghost
                            icon={<IconExtLinkOutlined />}
                            href={one?.externalUrl || ''}
                            style={{ width: 'calc(50% - 8px)' }}>
                            Open URL
                          </Button>
                          <Button
                            danger
                            ghost
                            icon={<DeleteOutlined />}
                            style={{ width: 'calc(50% - 8px)' }}
                            onClick={() => {
                              showDeleteConfirm(item as string)
                            }}>
                            Delete
                          </Button>
                        </div>
                      ) : (
                        <div className='vertical_flex' style={{ gap: '16px', flexBasis: '100%' }}>
                          <Button type='primary' ghost icon={<IconExtLinkOutlined />} href={one?.externalUrl || ''} />
                          <Button
                            danger
                            ghost
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              showDeleteConfirm(item as string)
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <Divider style={{ margin: 0 }} />
                  </div>
                )
              })}
            </>
          )}
          {/* footer */}
          <div className='form_footer_container'>
            <Text strong>Ready to Submit?</Text>
            <Text type='secondary' style={{ marginTop: '4px' }}>
              {`Review the selected resources and make sure everything is accurate. Once you're ready, click the 'Assign Resources' button to add the new medication log.`}
            </Text>
            <Divider style={{ margin: '16px 0' }} />
            <div className='right_flex'>
              <Button
                style={{ flexBasis: isMobile ? '100%' : 'content' }}
                type='primary'
                onClick={onAssign}
                loading={assignLoading}>
                Assign Resources
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}

/* ------------------------------ container fc ------------------------------ */

const ResourceAssignContainer: React.FC = () => {
  const { token: loginToken } = useContext(InfoCxt)
  const { userName } = useContext(PatientCtx)
  const [page, setPage] = useState<PageType>('select_list')
  const [selectedResources, setSelectedResources] = useState<React.Key[]>([])
  const [imageData, setImageData] = useState<{ id: string; image: string }[]>([])

  const { loading, data: queryData } = useQuery(QUERY_PATIENT_RESOURCE_LIST, {
    variables: { userName },
    context: { headers: { Authorization: loginToken } },
  })

  const formData: ResourcesProcessedDataType[] = queryData?.physician?.queryPatient?.resourceNoRecommend || []
  // if (formData) console.log(11, formData)

  // get images on need
  const [getImages, { data: queryImageData }] = useLazyQuery(QUERY_SELECTED_RESOURCE, {
    context: { headers: { Authorization: loginToken } },
  })

  useEffect(() => {
    const images: { id: string; image: string }[] = queryImageData?.queryResourceIdList || []
    if (images.length) {
      // console.log(11, images)
      setImageData((pre) => [...pre, ...images.map((a) => ({ id: a.id, image: a.image }))])
    }
  }, [queryImageData])

  // filter those already queried
  const needList = (selectedResources as string[]).filter((a) => !imageData.map((b) => b.id).includes(a))

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {formData && (
            <>
              {page === 'select_list' && (
                <ResourceSelect
                  data={formData}
                  selectedResources={selectedResources}
                  setSelectedResources={setSelectedResources}
                  setPageAction={() => {
                    getImages({
                      variables: {
                        resourceIDList: needList,
                      },
                    })
                    setPage('add_form')
                  }}
                />
              )}
              {page === 'add_form' && (
                <ResourceAssign
                  data={formData}
                  imageData={imageData}
                  selectedResources={selectedResources}
                  setSelectedResources={setSelectedResources}
                  setPageAction={() => {
                    setPage('select_list')
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default ResourceAssignContainer
