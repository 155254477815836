import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import { ConfigProvider, Menu, MenuProps } from 'antd'
import { PageProps } from '../'
import { InfoCxt } from '../../../App'
import Adherence from './adherence'
import Goals from './goals'
import Plan from './plan'
import Report from './reports'
import Resource from './resource'
import Taken from './taken'

const items: MenuProps['items'] = [
  {
    label: 'Reports',
    key: 'reports',
  },
  {
    label: 'Medications',
    key: 'medications',
    children: [
      {
        label: 'Medication Plan',
        key: 'medication_plan',
        style: { width: '180px' }, // set width for menu item
      },
      {
        label: 'Medication Taken',
        key: 'medication_taken',
      },
      {
        label: 'Medication Adherence',
        key: 'medication_adherence',
      },
    ],
  },
  {
    label: 'Lab Results',
    key: 'lab_results',
  },
  {
    label: 'Goals',
    key: 'goals',
  },
  {
    label: 'Resources',
    key: 'resources',
  },
]

const TabMenu: React.FC<PageProps & { setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  userName,
  loginToken,
  setReady,
}) => {
  const [tabKey, setTabKey] = useState('reports')
  const infoContext = useContext(InfoCxt)
  const { showComingSoon } = infoContext

  const onClick: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      if (e.key === 'lab_results') {
        showComingSoon()
      } else {
        setTabKey(e.key)
      }
    },
    [showComingSoon]
  )

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            colorPrimary: '#38C172',
            colorText: 'rgba(0, 0, 0, 0.88)',
            colorTextLightSolid: '#38C172',
            colorBgContainer: '#fff',
            controlItemBgActive: '#F9FEFB',
          },
        },
      }}>
      <div style={{ width: '100%', overflowX: 'scroll', padding: '0 8px', backgroundColor: '#ffffff' }}>
        <Menu style={{ minWidth: '500px' }} mode='horizontal' items={items} onClick={onClick} selectedKeys={[tabKey]} />
      </div>
      <div style={{ backgroundColor: '#f5f5f5' }}>
        {tabKey === 'reports' && (
          <>
            <Report userName={userName} loginToken={loginToken} setReady={setReady} />
          </>
        )}
        {tabKey === 'medication_plan' && (
          <>
            <Plan userName={userName} loginToken={loginToken} />
          </>
        )}
        {tabKey === 'medication_taken' && (
          <>
            <Taken userName={userName} loginToken={loginToken} />
          </>
        )}
        {tabKey === 'medication_adherence' && (
          <>
            <Adherence userName={userName} loginToken={loginToken} />
          </>
        )}
        {tabKey === 'goals' && (
          <>
            <Goals />
          </>
        )}
        {tabKey === 'resources' && (
          <>
            <Resource />
          </>
        )}
      </div>
    </ConfigProvider>
  )
}

export default TabMenu
