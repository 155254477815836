import React, { useState, useEffect, MouseEventHandler, useContext, useRef, useMemo } from 'react'
import { Row, Col, Space, Typography, Button, Input, Select, Alert, Modal, Divider, UploadProps, Upload } from 'antd'
import dayjs from 'dayjs'

import styles from './Settings.module.css'
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  InboxOutlined,
  LockOutlined,
  SafetyOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'

import { InfoCxt } from '../../App'
import { useCookies } from 'react-cookie'
import { Loading } from '../../components/Loading/defaultLoading'
import { isValidPassword } from '../../utils/isValidPassword'

const CURRENT_USER = gql`
  query {
    currentUser {
      id
      securityQuestionsAndAnswers {
        updatedAt
      }
    }
    allSecurityQuestions {
      id
      content
    }
  }
`

const MESSAGE_FIELDS = gql`
  fragment MessageFields on MessageType {
    title
    icon
    content
  }
`

const UPLOAD_SECURITY_ANSWERS = gql`
  ${MESSAGE_FIELDS}
  mutation UploadSecurityAnswers($securityQuestions: [SecurityQuestionInputType]!) {
    authMutations {
      uploadSecurityAnswers(securityQuestions: $securityQuestions) {
        status
        message {
          ...MessageFields
        }
      }
    }
  }
`

const CHANGE_PASSWORD_CURRENT = gql`
  ${MESSAGE_FIELDS}
  mutation changePasswordWithCurrentPassword($currentPassword: String!, $newPassword: String!) {
    authMutations {
      changePasswordWithCurrentPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
        status
        message {
          ...MessageFields
        }
        currentUser {
          firstName
        }
      }
    }
  }
`

type QuestionOptionType = { label: string; value: string }
type SecurityQuestionType = { id: string; content: string }

const { Title, Text } = Typography
const { Dragger } = Upload
export const Security = () => {
  const [cookies] = useCookies(['loginToken'])
  const { message } = useContext(InfoCxt)

  // Note: weather token or not, the query will alway be send to check token status.
  // Once query error, which means invalid token, always redirect back to /login.
  const { loading, data } = useQuery(CURRENT_USER, {
    context: { headers: { Authorization: cookies.loginToken } },
  })

  const securityQuestionsAndAnswersLastUpdated = data?.currentUser?.securityQuestionsAndAnswers?.[0]?.updatedAt
  const dateObj = dayjs.unix(securityQuestionsAndAnswersLastUpdated)
  const formattedDate = dateObj.format('D MMM YYYY')
  const securityQuestions: any = data?.allSecurityQuestions
  const [error, setError] = useState('')
  const [firstQuestionError, setFirstQuestionError] = useState(false)
  const [secondQuestionError, setSecondQuestionError] = useState(false)
  const [thirdQuestionError, setThirdQuestionError] = useState(false)
  const [isSecurityModalOpen, setIsSecurityModalOpen] = useState(false)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)

  const [question1, setQuestion1] = useState('')
  const [answer1, setAnswer1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [answer2, setAnswer2] = useState('')
  const [question3, setQuestion3] = useState('')
  const [answer3, setAnswer3] = useState('')

  const editSecurityQuestions = () => {
    setIsSecurityModalOpen(true)
  }

  const setQuestion = (value: string, index: number) => {
    switch (index) {
      case 0:
        setQuestion1(value)
        break
      case 1:
        setQuestion2(value)
        break
      case 2:
        setQuestion3(value)
        break
    }
  }

  const setAnswer = (value: string, index: number) => {
    switch (index) {
      case 0:
        setAnswer1(value)
        break
      case 1:
        setAnswer2(value)
        break
      case 2:
        setAnswer3(value)
        break
    }
  }

  const getQuestionList = (
    securityQuestions: SecurityQuestionType[],
    question1: string,
    question2: string,
    question3: string
  ) => {
    const questions: QuestionOptionType[] = []
    securityQuestions?.forEach((item: SecurityQuestionType) => {
      if (question1 !== item.content && question2 !== item.content && question3 !== item.content) {
        questions.push({ label: item.content, value: item.content })
      }
    })
    return questions
  }

  const questionList = useMemo(
    () => getQuestionList(securityQuestions, question1, question2, question3),
    [securityQuestions, question1, question2, question3]
  )

  const saveSecurityQuestions = () => {
    if (!question1 || !question2 || !question3 || !answer1 || !answer2 || !answer3) {
      setError('Password do not match, try again.')
      if (answer1 === '') {
        setFirstQuestionError(true)
      }
      if (answer2 === '') {
        setSecondQuestionError(true)
      }
      if (answer3 === '') {
        setThirdQuestionError(true)
      }

      return true
    }
    setError('')
    const requestBundle = {
      securityQuestions: [
        { question: question1, answer: answer1 },
        { question: question2, answer: answer2 },
        { question: question3, answer: answer3 },
      ],
    }
    uploadAnswers({ variables: requestBundle }).then()
  }

  const [uploadAnswers, { loading: uploadAnswersLoading }] = useMutation(UPLOAD_SECURITY_ANSWERS, {
    context: {
      headers: {
        Authorization: cookies.loginToken ? `Bearer ${cookies.loginToken}` : '',
      },
    },
    onCompleted: (data) => {
      setTimeout(() => {
        setIsSecurityModalOpen(false)
        if (data.authMutations.uploadSecurityAnswers.status) {
          message?.success('Security questions successfully updated!')
        } else {
          message?.error(data.authMutations.uploadSecurityAnswers.message.content)
        }
      }, 0)
    },
    onError: (error) => {
      setIsSecurityModalOpen(false)
      console.log(error)
    },
  })

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  // Define the mutation hook for changing the password
  const [changePassword, { loading: changePasswordLoading }] = useMutation(CHANGE_PASSWORD_CURRENT, {
    context: {
      headers: {
        Authorization: cookies.loginToken ? `Bearer ${cookies.loginToken}` : '',
      },
    },
    onCompleted: (data) => {
      if (data.authMutations.changePasswordWithCurrentPassword.status) {
        message?.success('Password successfully updated!')
        setIsPasswordModalOpen(false)
      } else {
        setError('Current password entered is incorrect.')
      }
    },
    onError: (err) => {
      setError('Password do not match, try again.')
      console.log(err)
    },
  })

  const handlePasswordChange = () => {
    // Validate inputs
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setError('Uh-oh! Some fields are still hungry for information.')
      return
    }

    if (newPassword !== confirmNewPassword) {
      setError('Password do not match, try again.')
      return
    }

    if (isValidPassword(newPassword) === false) {
      setError(
        'Passwords must have at least 8 characters and contain uppercase letters, lowercase letters, numbers, and symbols (e.g. @, #, $, %, ^).'
      )
      return
    }

    // if (currentPassword === newPassword) {
    //   setError('New password should be different from the current password.')
    //   return
    // }

    setError('') // Reset any existing error messages

    // If validations pass, call the mutation to change the password
    changePassword({
      variables: {
        currentPassword,
        newPassword,
      },
    }).then()
  }

  const buttonStyle = {
    width: '100%',
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Space direction='vertical' className={styles.settingsPageTabContent}>
      <Title level={4}>Security Settings</Title>
      <Text type='secondary'>Manage your security questions and password.</Text>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={13}>
          <Row>
            <Col xs={24} sm={3}>
              <SafetyOutlined size={20} style={{ color: '#38C172' }} />
            </Col>
            <Col xs={24} sm={21}>
              <Row>
                <Text strong>Security Questions</Text>
              </Row>
              <Row>
                <Text type='secondary'>Manage your security questions for enhanced account protection.</Text>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={6}>
          Last updated {formattedDate}
        </Col>
        <Col xs={24} sm={5}>
          <Row justify={'end'}>
            <Col xs={24} sm={12}>
              <Button onClick={editSecurityQuestions} style={buttonStyle}>
                Manage
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={13}>
          <Row>
            <Col xs={24} sm={3}>
              <LockOutlined size={20} style={{ color: '#38C172' }} />
            </Col>
            <Col xs={24} sm={21}>
              <Row>
                <Text strong>Log In Password</Text>
              </Row>
              <Row>
                <Text type='secondary'>Manage your login password to safeguard your account.</Text>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={{ span: 6, offset: 5 }}>
          <Row justify={'end'}>
            <Col xs={24} sm={10}>
              <Button onClick={() => setIsPasswordModalOpen(true)} style={buttonStyle}>
                Change
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 0, border: 'none' }} />
      <Modal
        title='Manage Security Questions'
        open={isSecurityModalOpen}
        footer={[
          <Button key='cancel' onClick={() => setIsSecurityModalOpen(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={uploadAnswersLoading} onClick={saveSecurityQuestions}>
            Save
          </Button>,
        ]}
        maskClosable={true}
        closable={false}
        onCancel={() => setIsSecurityModalOpen(false)}
        className={'settings-page-modal'}>
        <Space direction='vertical' style={{ width: '100%' }}>
          {error && <Alert message={error} type='error' showIcon />}
          <Text>Manage your security questions for enhanced account protection.</Text>
          <Text strong>Question 1</Text>
          <Select
            value={question1 ? question1 : undefined}
            onChange={(v) => setQuestion(v, 0)}
            options={questionList}
            style={{ width: '100%', marginBottom: '1%' }}
            placeholder={<Text> Select Question</Text>}
          />
          <Input
            onChange={(e) => setAnswer(e.target.value, 0)}
            placeholder='Answer'
            width='100%'
            className={firstQuestionError ? styles.inputError : ''}
          />
          <Text strong>Question 2</Text>
          <Select
            value={question2 ? question2 : undefined}
            onChange={(v) => setQuestion(v, 1)}
            options={questionList}
            style={{ width: '100%', marginBottom: '1%' }}
            placeholder={<Text> Select Question</Text>}
          />
          <Input
            onChange={(e) => setAnswer(e.target.value, 1)}
            placeholder='Answer'
            width='100%'
            className={secondQuestionError ? styles.inputError : ''}
          />
          <Text strong>Question 3</Text>
          <Select
            value={question3 ? question3 : undefined}
            onChange={(v) => setQuestion(v, 2)}
            options={questionList}
            style={{ width: '100%', marginBottom: '1%' }}
            placeholder={<Text> Select Question</Text>}
          />
          <Input
            onChange={(e) => setAnswer(e.target.value, 2)}
            placeholder='Answer'
            width='100%'
            className={thirdQuestionError ? styles.inputError : ''}
          />
        </Space>
      </Modal>
      <Modal
        title='Change Password'
        open={isPasswordModalOpen}
        footer={[
          <Button key='cancel' onClick={() => setIsPasswordModalOpen(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={handlePasswordChange} loading={changePasswordLoading}>
            Save
          </Button>,
        ]}
        maskClosable={true}
        closable={false}
        onCancel={() => setIsPasswordModalOpen(false)}
        className={'settings-page-modal'}>
        <Space direction='vertical' style={{ width: '100%' }}>
          {error && <Alert message={error} type='error' showIcon />}
          <Text>Manage your login password to safeguard your account.</Text>
          <Input.Password
            placeholder='Current Password'
            onChange={(e) => setCurrentPassword(e.target.value)}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          <Input.Password
            placeholder='New Password'
            onChange={(e) => setNewPassword(e.target.value)}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          <Input.Password
            placeholder='Confirm New Password'
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Space>
      </Modal>
    </Space>
  )
}
