import React, { CSSProperties } from 'react'
import { Spin, Typography } from 'antd'
import styles from './index.module.css'

const { Text } = Typography

type LoadingProps = {
  style?: CSSProperties
}

const Loading: React.FC<LoadingProps> = ({ style: userStyle }) => {
  return (
    <div className={styles.loadingContainer} style={userStyle}>
      <Spin size='large' />
      <Text style={{ fontSize: '14px', color: '#38C172' }}>{'Loading'}</Text>
    </div>
  )
}

export default Loading
