import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Card, Checkbox, Col, Form, Image, Input, Layout, Row, Space, Typography } from 'antd'
import { useCookies } from 'react-cookie'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { InfoCxt } from '../../App'

const { Title, Text } = Typography

const { Footer, Content } = Layout

const VERIFY_SECURITY_QUESTIONS = gql`
  mutation VerifyAnswers($username: String!, $securityQuestions: [SecurityQuestionInputType]!) {
    authMutations {
      verifySecurityAnswers(username: $username, securityQuestions: $securityQuestions) {
        status
        message {
          title
          icon
          content
        }
        code
      }
    }
  }
`

const Verification = () => {
  const { notification } = useContext(InfoCxt)
  const navigate = useNavigate()

  const [fnVerify, { data, loading }] = useMutation(VERIFY_SECURITY_QUESTIONS)

  const submit = useCallback(
    (values: { answer1: string; answer2: string }) => {
      fnVerify({
        variables: {
          username: username,
          securityQuestions: [
            { question: question1, answer: values.answer1 },
            { question: question2, answer: values.answer2 },
          ],
        },
      }).then((res) => {
        if (res?.data?.authMutations?.verifySecurityAnswers?.status === true) {
          const code = res?.data?.authMutations?.verifySecurityAnswers?.code
          navigate('/change-password', { state: { username: username, code: code } })
          return
        }
        if (res?.data?.authMutations?.verifySecurityAnswers?.message?.title === 'Account Temporarily Locked') {
          const btn = (
            <Space>
              <Button type='link' size='small' onClick={() => notification?.destroy()}>
                Dismiss
              </Button>
            </Space>
          )
          notification?.error({
            message: 'Account Temporarily Locked',
            description:
              'A request has been sent to the administrator, you will get a call within 7 to 14 working days on how to reset your password.',
            btn,
            closeIcon: null,
            duration: 3,
            icon: <LockOutlined />,
          })
          navigate('/login')
        }
      })
    },
    [fnVerify]
  )

  const { state } = useLocation()

  useEffect(() => {
    if (!state || !state.username || !state.securityQuestions) {
      navigate('/forget-password')
      return () => {
        // do nothing
      }
    }
  }, [state])

  if (!state || !state.username || !state.securityQuestions) {
    return null
  }

  const { securityQuestions, username } = state

  const startIndex = Math.floor(Math.random() * securityQuestions?.length)
  const question1 = securityQuestions[startIndex]
  const question2 = securityQuestions[(startIndex + 1) % securityQuestions?.length]

  let errorElements = null
  if (data) {
    if (data?.authMutations?.verifySecurityAnswers?.status === true) {
      errorElements = null
    } else if (data?.authMutations?.verifySecurityAnswers?.message?.title === 'Incorrect Answer') {
      errorElements = (
        <Col span={24}>
          <Alert
            showIcon
            message={`${data?.authMutations?.verifySecurityAnswers?.message?.title}. ${data?.authMutations?.verifySecurityAnswers?.message?.content}`}
            type='error'
          />
        </Col>
      )
    } else if (data?.authMutations?.verifySecurityAnswers?.message?.title === 'Account Temporarily Locked') {
      errorElements = null
    } else {
      errorElements = (
        <Col span={24}>
          <Alert showIcon message={data?.authMutations?.verifySecurityAnswers?.message?.content} type='error' />
        </Col>
      )
    }
  }

  return (
    <Layout className='login_container'>
      <Content>
        <Row>
          <Col className='row_center' span={24}>
            <Card className='card_container' styles={{body: { padding: 0 }}}>
              <Row gutter={[0, 24]} style={{ width: '100%' }}>
                <Col span={24} className='row_center'>
                  <Image preview={false} width='106px' src='/assets/icon/login-title.png' />
                </Col>

                <Col span={24} className='row_center'>
                  <Image preview={false} width='150px' src='/assets/icon/verification.png' />
                </Col>

                <Col span={24}>
                  <Title level={4} style={{ marginTop: 0 }}>
                    Security verification
                  </Title>
                  <Text>To help us verify your identity, please answer the security questions you configured.</Text>
                </Col>

                {errorElements}

                <Col span={24}>
                  <Form
                    name='basic'
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxWidth: 600 }}
                    layout='vertical'
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    onFinish={submit}
                    // onFinishFailed={() => void}
                    autoComplete='off'>
                    <Form.Item
                      label={question1}
                      name='answer1'
                      rules={[{ required: true, message: 'Please input your answer.' }]}>
                      <Input placeholder='Answer' />
                    </Form.Item>
                    <Form.Item
                      label={question2}
                      name='answer2'
                      rules={[{ required: true, message: 'Please input your answer.' }]}>
                      <Input placeholder='Answer' />
                    </Form.Item>
                    <Form.Item>
                      <Button block type='primary' htmlType='submit' loading={loading}>
                        Submit
                      </Button>
                    </Form.Item>

                    <div className='row_center'>
                      <Link style={{ color: '#38C172' }} to='/login'>
                        Cancel
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer className='footer'>© Empower Plus. All Rights Reserved.</Footer>
    </Layout>
  )
}

export default Verification
