import { theme, ThemeConfig } from 'antd'

export const PageTheme: ThemeConfig = {
  token: {
    colorPrimary: '#38C172',
    colorSuccess: '#38C172',
    colorInfo: '#38C172',
    colorText: 'rgba(33, 41, 58, 0.88)',
  },
  components: {
    Menu: {
      colorPrimary: '#FFFFFF',
      colorText: '#FFFFFF',
      colorTextLightSolid: '#FFFFFF',
      colorBgContainer: '#38C172',
      controlItemBgActive: 'rgba(33, 41, 58, 0.15)',
    },
    Layout: {
      headerBg: '#FFFFFF',
      siderBg: '#FFFFFF',
    },
  },
  algorithm: theme.defaultAlgorithm,
}
