import '../index.css'
import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import { Button, Pagination, Space, Table, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { InfoCxt } from '../../../App'
import { Loading } from '../../../components/Loading/defaultLoading'
import {
  PaddingBox,
  PageSubtitle,
  PageTitle,
  TabHeader,
  TableBox,
  TablePaginationBox,
} from '../../../components/Wrapper'
import GoalsDrawer from './goalsDrawer'

const QUERY_GOALS = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        goal {
          inProgressAssignee
          inProgressCheckList
          inProgressStartTime
          inProgress {
            id
            completionType
            description
            goalCategory
            goalImage
            goalName
            goalTitle
            goalType
            whyItIsImportant
          }
          completed {
            id
            assignee
            checkList
            startTime
            statusColor
            goal {
              id
              completionType
              description
              goalCategory
              goalImage
              goalName
              goalTitle
              goalType
              whyItIsImportant
            }
          }
        }
      }
    }
  }
`

export const getColor = (name: string) => {
  const _name = name.toLowerCase()

  if (['blue', 'purple', 'green'].includes(_name)) {
    return _name
  }

  return '#BFBFBF'
}

export const getStatusName = (name: string) => {
  const _name = name.toLowerCase()

  if (['purple', 'green'].includes(_name)) {
    return 'Completed'
  } else if (_name === 'blue') {
    return 'In Progress'
  }

  return 'Uncompleted'
}

export interface GoalType {
  id: string
  completionBonus: number
  completionCount: number
  completionType: string
  description: string
  eachCount: number
  externalLink: string
  goalCategory: string
  goalImage: string
  goalName: string
  goalTitle: string
  goalType: string
  trackingType: string
  whyItIsImportant: string
}

export interface GoalExtraType {
  assignee: string
  checkList: string[]
  startTime: string
  statusColor: string
}

export type GoalDataType = GoalType & GoalExtraType

interface RawDataType {
  inProgressAssignee: string[]
  inProgressCheckList: string[][]
  inProgressStartTime: string[]
  inProgress: GoalType[]
  completed: Array<
    GoalExtraType & {
      goal: GoalType
    }
  >
}

const processRawData = (queryData: RawDataType): Map<string, GoalDataType> => {
  const res = new Map<string, GoalDataType>()

  // inprogress
  if (queryData.inProgress?.length) {
    queryData.inProgress.forEach((v, index) => {
      if (!v.id) return
      const _value: GoalDataType = {
        ...v,
        //
        assignee: queryData.inProgressAssignee[index] || '',
        checkList: queryData.inProgressCheckList[index] || [],
        startTime: queryData.inProgressStartTime[index] || '',
        statusColor: 'blue',
      }
      res.set(v.id, _value)
    })
  }

  // completed
  if (queryData.completed?.length) {
    queryData.completed.forEach((v) => {
      if (!v.goal.id) return
      const _value: GoalDataType = {
        ...v.goal,
        //
        assignee: v.assignee || '',
        checkList: v.checkList || [],
        startTime: v.startTime || '',
        statusColor: v.statusColor,
      }
      res.set(v.goal.id, _value)
    })
  }

  return res
}

/* --------------------------- table column config -------------------------- */

const columns: ColumnsType<GoalDataType> = [
  {
    title: 'Start Date',
    dataIndex: 'startTime',
    // width: '300px',
    sorter: (a, b) => a.startTime.localeCompare(b.startTime),
    render: (value) => {
      if (value) return dayjs(value.slice(0, 10), 'YYYY-MM-DD').format('DD MMM YYYY')
      return '-'
    },
  },
  {
    title: 'Duration',
    dataIndex: 'completionType',
    // width: '300px',
    sorter: (a, b) => a.completionType.localeCompare(b.completionType),
  },
  {
    title: 'Type',
    dataIndex: 'goalType',
    // width: '300px',
    sorter: (a, b) => a.goalType.localeCompare(b.goalType),
  },
  {
    title: 'Category',
    dataIndex: 'goalCategory',
    // width: '300px',
    sorter: (a, b) => a.goalCategory.localeCompare(b.goalCategory),
  },
  {
    title: 'Title',
    dataIndex: 'goalTitle',
    // width: '300px',
    sorter: (a, b) => a.goalTitle.localeCompare(b.goalTitle),
  },
  {
    title: 'Status',
    dataIndex: 'statusColor',
    // width: '300px',
    sorter: (a, b) => a.statusColor.localeCompare(b.statusColor),
    render: (_, { statusColor }) => {
      return <Tag color={getColor(statusColor)}>{getStatusName(statusColor)}</Tag>
    },
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    // width: '300px',
    sorter: (a, b) => a.assignee.localeCompare(b.assignee),
  },
]

/* -------------------------------- react fc -------------------------------- */

const Goals: React.FC = () => {
  const { token: loginToken } = useContext(InfoCxt)
  const { patientId: userName } = useParams()
  const {
    loading,
    data: queryData,
    refetch,
  } = useQuery(QUERY_GOALS, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })
  const [drawer, setDrawer] = useState(false)
  const [goalID, setGoalID] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const goalsQueryData: RawDataType = queryData?.physician?.queryPatient?.goal || {}
  const goalsData: Map<string, GoalDataType> = goalsQueryData ? processRawData(goalsQueryData) : new Map()

  const closeDrawer = useCallback(
    (doRefetch?: boolean) => {
      setDrawer(false)
      if (doRefetch) refetch()
    },
    [setDrawer, refetch]
  )

  const onClickView = useCallback(
    (name: string) => {
      setDrawer(true)
      setGoalID(name)
    },
    [setDrawer, setGoalID]
  )

  return (
    <>
      <GoalsDrawer isOpen={drawer} closeDrawer={closeDrawer} goalID={goalID} info={goalsData} />
      {loading ? (
        <Loading />
      ) : (
        <>
          {goalsQueryData && (
            <>
              <TabHeader border>
                <PageTitle text='Goals' />
              </TabHeader>
              <PaddingBox>
                <PageSubtitle text='Assigned Goals' />
                <TableBox>
                  <Table
                    columns={[
                      ...columns,
                      {
                        title: 'Action',
                        width: '116px',
                        render: (_, { id }) => (
                          <Space size='middle'>
                            <Tooltip title='View'>
                              <Button type='primary' ghost icon={<EyeOutlined />} onClick={() => onClickView(id)} />
                            </Tooltip>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={Array.from(goalsData.values())}
                    rowKey={(record) => record.id}
                    pagination={{
                      position: ['none'],
                      current: currentPage,
                    }}
                  />
                </TableBox>
                <TablePaginationBox>
                  <Pagination
                    current={currentPage}
                    total={Array.from(goalsData.values()).length}
                    pageSize={10}
                    showLessItems={isMobile}
                    showSizeChanger={false}
                    onChange={(page) => setCurrentPage(page)}
                  />
                </TablePaginationBox>
              </PaddingBox>
            </>
          )}
        </>
      )}
    </>
  )
}

export default Goals
