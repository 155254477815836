import './index.css'
import * as React from 'react'
import { Image, Typography } from 'antd'

const { Text } = Typography

const CartEmpty = () => {
  return (
    <div className='empty_container'>
      <Image preview={false} width='150px' src='/assets/icon/cart-empty.png' />
      <Text type='secondary' style={{ fontSize: '12px' }}>
        {'Dreaming of items, cart is empty.'}
      </Text>
    </div>
  )
}

export default CartEmpty
