import './index.css'
import * as React from 'react'
import { useContext } from 'react'
import { Avatar, Badge, Button, Divider, Dropdown, Image, MenuProps, Space } from 'antd'
import { BrowserView, MobileView } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import {
  BellOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { InfoCxt } from '../../App'
import logo from '../../assets/images/logo_text_light.png'
import SearchBar from './components/searchBar'

export type PageHeaderProps = {
  collapsed: boolean
  setCollapsed: (flag: boolean) => void
  showProfile?: boolean
  setShowProfile?: (flag: boolean) => void
  removeCookie: (name: 'loginToken') => void
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  collapsed,
  setCollapsed,
  showProfile,
  setShowProfile,
  removeCookie,
}: PageHeaderProps) => {
  const infoContext = useContext(InfoCxt)
  const navigate = useNavigate()
  const { showComingSoon, avatar } = infoContext
  const notificationCount = 21

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      removeCookie('loginToken')
      setTimeout(() => {
        window.location.reload() // directly reload instead of using navigate()
      }, 1)
    } else if (key === 'profile') {
      navigate('/settings/profile')
    } else if (key === 'security') {
      navigate('/settings/security')
    } else {
      showComingSoon()
    }
  }

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: 'Profile',
    },
    {
      key: 'security',

      icon: <LockOutlined />,
      label: 'Security',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Log out',
    },
  ]

  return (
    <>
      {/* pc version */}
      <BrowserView>
        <div className='custom-header page-header'>
          <Space size={16} style={{ height: '32px', lineHeight: '32px' }}>
            <Button
              type='text'
              style={{ width: 32, height: 32, color: '#9B9FA6' }}
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <SearchBar />
          </Space>
          <Space size={16} style={{ height: '32px', lineHeight: '32px' }}>
            <Button
              type='text'
              style={{ width: 32, height: 32 }}
              icon={
                <Badge count={notificationCount} size='small' offset={[notificationCount > 9 ? 1 : 0, 0]}>
                  <BellOutlined />
                </Badge>
              }
              onClick={showComingSoon}
            />
            <Divider type='vertical' style={{ margin: 0 }} />
            <Dropdown menu={{ items, onClick }}>
              <Avatar size={32} draggable={false} src={avatar} style={{ cursor: 'pointer' }} />
            </Dropdown>
          </Space>
        </div>
      </BrowserView>
      {/* mobile version */}
      <MobileView>
        <div className='layout-header-mobile'>
          <div>
            <Image width={106} height={28} src={logo} preview={false} />
          </div>
          <div className='header-right-mobile'>
            <Button
              type='text'
              style={{ width: 24, height: 24, color: '#ffffff' }}
              icon={<UserOutlined />}
              onClick={() => {
                setCollapsed(true)
                setShowProfile?.(!showProfile)
              }}
            />
            <Button
              type='text'
              style={{ width: 24, height: 24, color: '#ffffff' }}
              icon={<MenuOutlined />}
              onClick={() => {
                setShowProfile?.(false)
                setCollapsed(!collapsed)
              }}
            />
          </div>
        </div>
      </MobileView>
    </>
  )
}
