import '../index.css'
import * as React from 'react'
import { useContext } from 'react'
import { Button, Drawer, Space, Tag, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { InfoCxt } from '../../../App'
import TableEmpty from '../../../components/Empty/TableEmpty'
import { KNOW_ALLERGIES } from './taken'

const { Text } = Typography

export const QUERY_ALLERGIES = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        patientDetail {
          medicineAllergies {
            medicine
            allergies {
              symptom
              isKnownAllergy
            }
          }
        }
      }
    }
  }
`

/* -------------------------------- drawer fc ------------------------------- */

type AllergiesDateType = {
  medicine: string
  allergies: {
    symptom: string
    isKnownAllergy: boolean
  }[]
}

type PanelDrawerAllergiesProps = {
  isOpen: boolean
  closeDrawer: () => void
}

const PanelDrawerAllergies: React.FC<PanelDrawerAllergiesProps> = ({ isOpen, closeDrawer }) => {
  const { token: loginToken } = useContext(InfoCxt)
  const { patientId: userName } = useParams()
  const navigate = useNavigate()

  const { data: queryData } = useQuery(QUERY_ALLERGIES, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })

  const info: AllergiesDateType[] = queryData?.physician?.queryPatient?.patientDetail?.medicineAllergies || []
  // if (queryData) console.log(11, info)

  return (
    <>
      <Drawer
        title='Allergies'
        placement='right'
        closable={false}
        styles={{body: { padding: 0 }}}
        onClose={closeDrawer}
        open={isOpen}
        extra={<CloseOutlined onClick={closeDrawer} className='icon_style' />}
        footer={
          <Button
            block
            onClick={() => {
              // console.log(11, 'drawer jump')
              navigate('./edit/allergies')
            }}>
            Edit
          </Button>
        }>
        {info.length ? (
          <>
            <div className='drawer_container'>
              {info.map((one) => {
                return (
                  <div className='draw_body_card' key={one.medicine}>
                    <div className='dosage_flex'>
                      <Text strong>{one.medicine}</Text>
                      <Space size={[0, 8]} wrap>
                        {one.allergies.map((item) => {
                          return (
                            <Tag
                              color={KNOW_ALLERGIES.indexOf(item.symptom) > -1 ? 'orange' : 'blue'}
                              key={item.symptom}>
                              {item.symptom}
                            </Tag>
                          )
                        })}
                      </Space>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <div className='empty_v_center_box'>
            <TableEmpty />
          </div>
        )}
      </Drawer>
    </>
  )
}

export default PanelDrawerAllergies
