import * as React from 'react'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const SvgExtLinkOutlined = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M13.7534 14.2539H2.25342C1.98008 14.2539 1.75342 14.0272 1.75342 13.7539V2.25388C1.75342 1.98054 1.98008 1.75388 2.25342 1.75388H7.88008C7.94675 1.75388 8.00675 1.80721 8.00675 1.88054V2.75388C8.00675 2.82054 7.95342 2.88055 7.88008 2.88055H2.88008V13.1272H13.1267V8.12721C13.1267 8.06055 13.1801 8.00055 13.2534 8.00055H14.1267C14.1934 8.00055 14.2534 8.05388 14.2534 8.12721V13.7539C14.2534 14.0272 14.0268 14.2539 13.7534 14.2539ZM12.0534 3.11388L11.2401 2.30054C11.2401 2.30054 11.2134 2.26055 11.2068 2.24055C11.2068 2.22055 11.2068 2.19388 11.2068 2.17388C11.2134 2.15388 11.2268 2.13388 11.2468 2.12054C11.2668 2.10721 11.2868 2.09388 11.3134 2.09388L14.1134 1.75388C14.1934 1.74721 14.2601 1.81388 14.2534 1.89388L13.9268 4.69388C13.9134 4.79388 13.7868 4.84054 13.7134 4.76721L12.8934 3.94721L8.89342 7.94721C8.84675 7.99388 8.76675 7.99388 8.72008 7.94721L8.06008 7.28721C8.01342 7.24055 8.01342 7.16054 8.06008 7.11388L12.0601 3.10721L12.0534 3.11388Z'
        fill='#38C172'
      />
    </svg>
  )
}

const IconExtLinkOutlined = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={SvgExtLinkOutlined} {...props} />
}

export default IconExtLinkOutlined
