import * as React from 'react'
import { useCallback, useContext, useEffect } from 'react'
import { Alert, Button, Card, Col, Form, Image, Input, Layout, Row, Space, Typography } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { InfoCxt } from '../../App'

const { Title, Text, Paragraph } = Typography

const { Footer, Content } = Layout

const DELETE_ACCOUNT = gql`
  mutation ($username: String!, $password: String!) {
    authMutations {
      deleteAccountWeb(username: $username, password: $password) {
        status
        message {
          title
          icon
          content
        }
      }
    }
  }
`

const DeleteAccount = () => {
  const { notification, modal } = useContext(InfoCxt)
  const [fnDelete, { data, loading }] = useMutation(DELETE_ACCOUNT)

  const submit = useCallback(
    (values: { username: string; password: string; remember: boolean }) => {
      fnDelete({ variables: { username: values.username, password: values.password } }).then((res) => {
        const title = res?.data?.authMutations?.deleteAccountWeb?.message?.title
        const message = res?.data?.authMutations?.deleteAccountWeb?.message?.content
        if (res?.data?.authMutations?.deleteAccountWeb?.status === true) {
          console.log('Account deleted')
          notification?.success({
            message: title,
            description: message,
            placement: 'topRight',
            btn: (
              <Button type='link' size='small' onClick={() => notification?.destroy()}>
                Dismiss
              </Button>
            ),
            closeIcon: null,
            style: {
              width: 400,
            },
          })
        } else {
          console.log('Account not deleted')
          if (message !== 'Incorrect username or password.') {
            notification?.error({
              message: title,
              description: message,
              placement: 'topRight',
              btn: (
                <Button type='link' size='small' onClick={() => notification?.destroy()}>
                  Dismiss
                </Button>
              ),
              closeIcon: null,
              style: {
                width: 400,
              },
            })
          }
        }
      })
    },
    [fnDelete]
  )

  const confirmDelete = useCallback(
    (values: { username: string; password: string; remember: boolean }) => {
      modal?.confirm({
        title: 'Account Deletion is Permanent',
        content: 'Are you sure you want to delete your account?',
        okText: 'Confirm',
        onOk() {
          submit(values)
        },
      })
    },
    [submit]
  ) // Dependency on submit function

  return (
    <Layout className='login_container'>
      <Content>
        <Row>
          <Col className='row_center' span={24}>
            <Card className='card_container' styles={{body: { padding: 0 }}}>
              <Row gutter={[0, 24]} style={{ width: '100%' }}>
                <Col span={24} className='row_center'>
                  <Image preview={false} width='106px' src='/assets/icon/login-title.png' />
                </Col>

                <Col span={24} className='row_center'>
                  <Image preview={false} width='150px' src='/assets/icon/login-identity-card.png' />
                </Col>

                <Col span={24}>
                  <Title level={4} style={{ marginTop: 0 }}>
                    Account deletion
                  </Title>
                  <Text>At any time or for any reason you can request to permanently delete your account.</Text>
                </Col>

                <Col span={24}>
                  <Space direction='vertical'>
                    <Text strong style={{ marginTop: 0 }}>
                      What happens when my account is deleted?
                    </Text>
                    <Paragraph>
                      <ul>
                        <li>You will no longer be able to sign in to the Empower+ mobile app.</li>
                        <li>Your data associated with Empower+ will be permanently deleted.</li>
                        <li>
                          If you are enrolled in Empower+ research studies, deletion does not withdraw you from the
                          research study. Please contact your research coordinator for more information.
                        </li>
                      </ul>
                    </Paragraph>
                    <Text>
                      Account deletion is permanent. If you would like to proceed with the request for account deletion,
                      please enter your credentials.
                    </Text>
                  </Space>
                </Col>

                {data ? (
                  !(data.authMutations.deleteAccountWeb.status === true) &&
                  data.authMutations?.deleteAccountWeb?.message?.title !== 'Attempts Exceeded' ? (
                    <Col span={24}>
                      <Alert showIcon message='Incorrect username or password.' type='error' />
                    </Col>
                  ) : null
                ) : null}

                <Col span={24}>
                  <Form
                    name='basic'
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={confirmDelete}
                    // onFinishFailed={() => void}
                    autoComplete='off'>
                    <Form.Item name='username' rules={[{ required: true, message: 'Please input your username.' }]}>
                      <Input prefix={<UserOutlined />} placeholder='Username' />
                    </Form.Item>

                    <Form.Item name='password' rules={[{ required: true, message: 'Please input your password.' }]}>
                      <Input.Password prefix={<LockOutlined />} placeholder='Password' />
                    </Form.Item>

                    <Form.Item>
                      <Button block type='primary' htmlType='submit' loading={loading}>
                        Delete Account
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer className='footer'>© Empower Plus. All Rights Reserved.</Footer>
    </Layout>
  )
}

export default DeleteAccount
