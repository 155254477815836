import dayjs from 'dayjs'
import { BarConfig, ColumnConfig } from '@ant-design/plots'

export const BloodGlucose: ColumnConfig = {
  data: [],
  color: '#38C172',
  xField: 'recordDate',
  yField: 'value',
  meta: {
    recordDate: {
      alias: 'Record Date',
      formatter: (value) => dayjs(value, 'YYYY-MM-DD').format('M/DD'),
    },
  },
  annotations: [
    {
      type: 'line',
      // type: 'region',
      start: ['min', 4.4],
      end: ['max', 4.4],
      style: { stroke: '#F4664A', strokeOpacity: 1, lineWidth: 1 },
      text: {
        content: 'Reference Range - Low (4.4)',
      },
    },
    {
      type: 'line',
      // type: 'region',
      start: ['min', 7.2],
      end: ['max', 7.2],
      style: { stroke: '#F4664A', strokeOpacity: 1, lineWidth: 1 },
      text: {
        content: 'Reference Range - High (7.2)',
      },
    },
  ],
}

export const BloodPressure: ColumnConfig = {
  data: [],
  xField: 'recordDate',
  yField: 'value',
  isGroup: true,
  seriesField: 'type',
  yAxis: {
    max: 200,
    tickInterval: 50,
  },
  legend: false,
  meta: {
    ...BloodGlucose.meta,
    value: {
      alias: 'mmHg',
      formatter: (value: number) => `${value} mmHg`,
    },
    type: {
      formatter: (value) => (value === 'lower' ? 'Dia' : 'Sys'),
    },
  },
  color: (datum): string => {
    if (datum.type === 'lower') return '#7966FF'
    return '#38C172'
  },
  annotations: [
    {
      type: 'line',
      // type: 'region',
      start: ['min', 80],
      end: ['max', 80],
      style: { stroke: '#F4664A', strokeOpacity: 1, lineWidth: 1 },
      text: {
        content: 'Reference Range - Low (80)',
      },
    },
    {
      type: 'line',
      // type: 'region',
      start: ['min', 120],
      end: ['max', 120],
      style: { stroke: '#F4664A', strokeOpacity: 1, lineWidth: 1 },
      text: {
        content: 'Reference Range - High (120)',
      },
    },
  ],
}

export const NutritionDistribution: BarConfig = {
  data: [],
  xField: 'value',
  yField: 'key',
  seriesField: 'key',
  legend: false,
  barWidthRatio: 0.5,
  color: (datum): string => {
    if (datum.key === 'carbsPercent') return '#9EB7FF'
    if (datum.key === 'proteinPercent') return '#FF9CB4'
    return '#FFCC00'
  },
  meta: {
    value: {
      formatter: (value: number) => `${value.toFixed(1)} %`,
    },
    key: {
      formatter: (value: string) => {
        const name = value.replace(/Percent/g, '')
        return name[0].toUpperCase() + name.slice(1)
      },
    },
  },
}
