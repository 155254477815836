import '../index.css'
import * as React from 'react'
import { useContext } from 'react'
import { Button, Drawer, Image, Space, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { ClockCircleOutlined, CloseOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons'
import { InfoCxt } from '../../../App'
import type { SpecialPlanDataType } from './plan'

const { Text } = Typography

/* -------------------------------- drawer fc ------------------------------- */

type PlanDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  medicationName: string
  info: Map<string, SpecialPlanDataType>
}

const PlanDrawer: React.FC<PlanDrawerProps> = ({ isOpen, closeDrawer, medicationName, info }) => {
  const { showComingSoon } = useContext(InfoCxt)

  // console.log(11, info.get(medicationName))
  const item = info.get(medicationName)

  return (
    <>
      <Drawer
        title='View'
        placement='right'
        closable={false}
        styles={{ body: { padding: 0 } }}
        onClose={closeDrawer}
        open={isOpen}
        extra={<CloseOutlined onClick={closeDrawer} className='icon_style' />}>
        <div className='drawer_container'>
          <div className='draw_body_card'>
            <div className='dosage_card_medication_name'>
              <Text>{medicationName}</Text>
              <Image preview={false} width='48px' src='/assets/icon/medication-icon.png' />
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_grid'>
              <Text strong>Dosage</Text>
              <Text strong>Type</Text>
              <Text>{item?.dose || '-'}</Text>
              <Text>{item?.type || '-'}</Text>
            </div>
          </div>
          <div className='draw_body_card'>
            {/* {item?.plans.map((plan) => (
                <div
                  key={plan.id}
                  className='dosage_flex_inner'
                  style={{
                    paddingBottom: '16px',
                    borderBottom: '1px solid var(--color-split, rgba(33, 41, 58, 0.06))',
                  }}>
                  <Space size={[0, 8]} wrap>
                    {plan.schedules.map((a) => (
                      <Tag key={a} color='green'>
                        {a.slice(0, 3)}
                      </Tag>
                    ))}
                  </Space>
                  <div className='dosage_flex_line'>
                    <ScheduleOutlined />
                    <Text>{`${plan.dosage} Unit(s), ${plan.perDay} Times a Day`}</Text>
                  </div>
                  <div className='dosage_flex_line'>
                    <ClockCircleOutlined />
                    <Text>{plan.reminderTime.map((a) => dayjs(a, 'HH:mm').format('h:mm a')).join(', ') || 'None'}</Text>
                  </div>
                  <div className='dosage_flex_line'>
                    <UserOutlined />
                    <Text>{item.creator || 'Patient'}</Text>
                  </div>
                  <Button block onClick={showComingSoon}>
                    Edit
                  </Button>
                </div>
              ))} */}
            <div className='draw_body_card_recurring_period'>
              <Text strong>Recurring Period</Text>
              {item?.plans.map((plan) => (
                <>
                  {plan.schedules.map((a) => (
                    <>
                      <Tag key={a} color='green'>
                        {a.slice(0, 3)}
                      </Tag>
                      <div className='dosage_flex_line'>
                        <ScheduleOutlined />
                        <Text>{`${plan.dosage} Unit(s)`}</Text>
                      </div>
                      <div className='dosage_flex_line recurring_period_element'>
                        <ClockCircleOutlined />
                        <div className='dosage_grid'>
                          <Text>
                            {plan.reminderTime.map((a) => dayjs(a, 'HH:mm').format('h:mm a')).join(', ') || 'None'}
                          </Text>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ))}
            </div>
          </div>
          <div className='draw_body_card'>
            <div className='dosage_grid'>
              <Text strong>Creator</Text>
              <Text strong>Custom</Text>
              <Text>{item?.creator || 'Patient'}</Text>
              <Text>{'No'}</Text>
            </div>
          </div>
          <div style={{ width: '80%', paddingLeft: '10%', paddingRight: '10%', paddingTop: '10px' }}>
            <Button block onClick={showComingSoon}>
              Edit
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default PlanDrawer
