import './index.css'
import { FC } from 'react'
import { Col, Divider, Image, Row, Typography } from 'antd'
import { BrowserView, MobileView } from 'react-device-detect'
import { AppleFilled, GoogleOutlined } from '@ant-design/icons'
import logo from '../../assets/images/logo_text_dark.png'

const { Title, Text } = Typography

export const PageFooter: FC = () => {
  return (
    <>
      {/* pc version */}
      <BrowserView>
        <div className='dash-board-page-footer'>
          <div className='dash-board-page-download'>
            <div className='dash-board-page-download-body'>
              <div className='download-body-right'>
                <div className='dash-board-page-download-body-tile'>Download Empower+ App</div>
                <div className='dash-board-page-download-body-text'>
                  Harness the power of the Empower+ app to enhance your health tracking journey.
                </div>
              </div>
              <div className='download-body-left'>
                <a href='https://apps.apple.com/sg/app/empower/id1662059011' target='_blank' rel='noopener noreferrer'>
                  <div className='download-body-left-btn' style={{ width: 140 }}>
                    <AppleFilled style={{ paddingRight: 8, color: '#21293AE0' }} />
                    <div style={{ color: '#21293AE0' }}>App Store</div>
                  </div>
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.nussgh.jarvis.health'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <div className='download-body-left-btn' style={{ width: 140 }}>
                    <GoogleOutlined style={{ paddingRight: 8, color: '#21293AE0' }} />
                    <div style={{ color: '#21293AE0' }}>Google Play</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className='foot'>
            <div>
              <Image width={106} height={28} src={logo} preview={false} />
            </div>
            <div style={{ fontSize: 14, color: 'rgba(33, 41, 58, 0.65)' }}>© Empower Plus. All Rights Reserved.</div>
          </div>
        </div>
      </BrowserView>
      {/* mobile version */}
      <MobileView>
        <div
          style={{
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            textAlign: 'center',
            backgroundColor: '#FBFBFB',
            borderTop: '1px solid var(--colorSplit, rgba(33, 41, 58, 0.06))',
          }}>
          <div>
            <Title style={{ fontSize: 16 }}>{`Download Empower+ App`}</Title>
            <Text
              type='secondary'
              style={{
                fontSize: 14,
              }}>
              {`Harness the power of the Empower+ app to enhance your health tracking journey.`}
            </Text>
          </div>
          <Row gutter={8}>
            <Col span={12}>
              <a href='https://apps.apple.com/sg/app/empower/id1662059011' target='_blank' rel='noopener noreferrer'>
                <div className='download-body-left-btn' style={{ height: 32, margin: 0 }}>
                  <AppleFilled style={{ paddingRight: 8, color: '#21293AE0' }} />
                  <div style={{ color: '#21293AE0' }}>App Store</div>
                </div>
              </a>
            </Col>
            <Col span={12}>
              <a
                href='https://play.google.com/store/apps/details?id=com.nussgh.jarvis.health'
                target='_blank'
                rel='noopener noreferrer'>
                <div className='download-body-left-btn' style={{ height: 32, margin: 0 }}>
                  <GoogleOutlined style={{ paddingRight: 8, color: '#21293AE0' }} />
                  <div style={{ color: '#21293AE0' }}>Google Play</div>
                </div>
              </a>
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '32px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: 32,
            textAlign: 'center',
            backgroundColor: '#FFFFFF',
          }}>
          <Divider style={{ margin: 0 }} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 16 }}>
            <div>
              <Image width={106} height={28} src={logo} preview={false} />
            </div>
            <div>
              <Text
                type='secondary'
                style={{
                  fontSize: 14,
                  lineHeight: '28px',
                }}>
                {`© Empower Plus. All Rights Reserved.`}
              </Text>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}
