import * as React from 'react'
import { useCallback, useContext, useEffect } from 'react'
import { Alert, Button, Card, Checkbox, Col, Form, Image, Input, Layout, Row, Space, Typography } from 'antd'
import { useCookies } from 'react-cookie'
import { useNavigate, Link } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { InfoCxt } from '../../App'

const { Title, Text } = Typography

const { Footer, Content } = Layout

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($username: String!) {
    authMutations {
      changePassword(username: $username) {
        status
        message {
          title
          icon
          content
        }
        securityQuestions
      }
    }
  }
`

const ForgetPassword = () => {
  const { notification } = useContext(InfoCxt)
  const navigate = useNavigate()

  const [fnForgetPassword, { data, loading }] = useMutation(CHANGE_PASSWORD)

  const submit = useCallback(
    (values: { username: string }) => {
      fnForgetPassword({ variables: { username: values.username } }).then((res) => {
        if (res?.data?.authMutations?.changePassword?.status === true) {
          const securityQuestions = res?.data?.authMutations?.changePassword?.securityQuestions
          navigate('/verification', {
            state: {
              username: values.username,
              securityQuestions,
            },
          })
        }
        if (res?.data?.authMutations?.changePassword?.message?.title === 'New Account Detected') {
          const btn = (
            <Space>
              <Button type='link' size='small' onClick={() => notification?.destroy()}>
                Dismiss
              </Button>
              <Button
                type='primary'
                size='small'
                onClick={() => {
                  navigate('/login')
                  notification?.destroy()
                }}>
                Log in
              </Button>
            </Space>
          )
          notification?.error({
            message: 'New Account Detected',
            description: 'To change password, you have to set-up your Security Questions during your first log in.',
            btn,
            closeIcon: null,
          })
        }
      })
    },
    [fnForgetPassword]
  )

  let errorElements = null
  if (data) {
    if (data?.authMutations?.changePassword?.status === true) {
      errorElements = null
    } else if (data?.authMutations?.changePassword?.message?.title === 'User Not Exist') {
      errorElements = (
        <Col span={24}>
          <Alert showIcon message={'No account exists for this username.'} type='error' />
        </Col>
      )
    } else if (data?.authMutations?.changePassword?.message?.title === 'New Account Detected') {
      errorElements = null
    } else {
      errorElements = (
        <Col span={24}>
          <Alert showIcon message={data?.authMutations?.changePassword?.message?.content} type='error' />
        </Col>
      )
    }
  }

  return (
    <Layout className='login_container'>
      <Content>
        <Row>
          <Col className='row_center' span={24}>
            <Card className='card_container' styles={{body: { padding: 0 }}}>
              <Row gutter={[0, 24]} style={{ width: '100%' }}>
                <Col span={24} className='row_center'>
                  <Image preview={false} width='106px' src='/assets/icon/login-title.png' />
                </Col>

                <Col span={24} className='row_center'>
                  <Image preview={false} width='150px' src='/assets/icon/forget-password.png' />
                </Col>

                <Col span={24}>
                  <Title level={4} style={{ marginTop: 0 }}>
                    Forget password
                  </Title>
                  <Text>To continue, provide your username.</Text>
                </Col>

                {errorElements}

                <Col span={24}>
                  <Form
                    name='basic'
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={submit}
                    // onFinishFailed={() => void}
                    autoComplete='off'>
                    <Form.Item name='username' rules={[{ required: true, message: 'Please input your username.' }]}>
                      <Input prefix={<UserOutlined />} placeholder='Username' />
                    </Form.Item>

                    <Form.Item>
                      <Button block type='primary' htmlType='submit' loading={loading}>
                        Continue
                      </Button>
                    </Form.Item>

                    <div className='row_center'>
                      <Link style={{ color: '#38C172' }} to='/login'>
                        Back to log in
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer className='footer'>© Empower Plus. All Rights Reserved.</Footer>
    </Layout>
  )
}

export default ForgetPassword
