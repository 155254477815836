import '../index.css'
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import type { InputRef } from 'antd'
import { App as AntdApp, Button, Divider, Form, Input, message, Select, Space, Typography, Alert } from 'antd'
import { isMobile } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'
import { DeleteOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'
import { gql, useMutation, useQuery } from '@apollo/client'
import { InfoCxt } from '../../../App'
import CartEmpty from '../../../components/Empty/CartEmpty'
import { ContentHeader, PageBreadcrumb, PageSubtitle, PageTitle, TabHeader } from '../../../components/Wrapper'
import { QUERY_ALLERGIES } from './panelDrawerAllergies'
import { KNOW_ALLERGIES } from './taken'

const { Text } = Typography

const MUTATION_ALLERGIES_UPDATE = gql`
  mutation ($username: String!, $inputs: [MedicineAllergiesInputItem]) {
    profileMutations {
      updateMedicineAllergies(medicineAllergiesInput: { patientUsername: $username, inputs: $inputs }) {
        status
        message {
          title
          icon
          content
        }
      }
    }
  }
`

type AllergiesDataType = {
  medicine: string
  allergies: {
    symptom: string
    isKnownAllergy: boolean
  }[]
}

// type AllergiesQueryDataType = {
//   title: string
//   allergies: string[]
// }

/* --------------------------------- form fc -------------------------------- */

type AllergiesFormDataType = {
  allergies: {
    title: string
    known_allergies: string[]
    custom_allergies: string[]
  }[]
}

const PanelEditAllergies: React.FC = () => {
  const { modal } = AntdApp.useApp()
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<AllergiesFormDataType>()
  const inputRef = useRef<InputRef>(null)

  const { token: loginToken } = useContext(InfoCxt)
  const { patientId: userName } = useParams()
  const navigate = useNavigate()

  const { loading, data: queryData } = useQuery(QUERY_ALLERGIES, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })

  const [customAllergies, setCustomAllergies] = useState<string[]>([])
  const [aName, setAName] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    const data: AllergiesDataType[] = queryData?.physician?.queryPatient?.patientDetail?.medicineAllergies || []
    if (data.length) {
      const values = data.map((item) => {
        const hasOthers = item.allergies.filter((a) => !a.isKnownAllergy).length > 0 ? ['Others'] : []
        return {
          title: item.medicine,
          known_allergies: [...item.allergies.filter((a) => a.isKnownAllergy).map((a) => a.symptom), ...hasOthers],
          custom_allergies: item.allergies.filter((a) => !a.isKnownAllergy).map((a) => a.symptom),
        }
      })
      form.setFieldValue('allergies', values)

      const options = data
        .map((item) => {
          return item.allergies.filter((a) => !a.isKnownAllergy).map((a) => a.symptom)
        })
        .flat(5)
      setCustomAllergies(options)
    }
  }, [queryData, form])

  const [updateAllergiesList, { data: updateData, loading: updateLoading }] = useMutation(MUTATION_ALLERGIES_UPDATE, {
    context: { headers: { Authorization: loginToken } },
  })

  useEffect(() => {
    if (updateData?.profileMutations?.updateMedicineAllergies?.status) {
      messageApi.success('Allergies successfully updated!')
      setTimeout(() => {
        navigate(`/patient/${userName}`)
      }, 1500)
    }
  }, [updateData, navigate, userName, messageApi])

  const showDeleteConfirm = (action: () => void) => {
    modal.confirm({
      title: 'Confirm Deletion',
      icon: <ExclamationCircleFilled style={{ color: '#FF5500' }} />,
      content: <div style={{ paddingBottom: '16px' }}>Are you sure you want to delete this item?</div>,
      okText: 'Confirm',
      okButtonProps: { type: 'primary' },
      cancelText: 'Cancel',
      onOk() {
        action()
      },
    })
  }

  const onANameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    setCustomAllergies((pre) => Array.from(new Set([...pre, aName])))
    setAName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const onSave = () => {
    form
      .validateFields()
      .then(() => {
        const d = form.getFieldsValue().allergies || []
        let hasCustomAllergyMissing = false

        const queryData = d.map((item) => {
          if (
            item.known_allergies.includes('Others') &&
            (!item.custom_allergies || item.custom_allergies.length === 0)
          ) {
            hasCustomAllergyMissing = true
            setError('Uh-oh! Some fields are still hungry for information.')
          }
          return {
            title: item.title,
            allergies: [
              ...item.known_allergies.filter((a) => a !== 'Others'),
              ...(item.known_allergies.includes('Others') ? item.custom_allergies : []),
            ],
          }
        })

        if (hasCustomAllergyMissing) {
          setError('Uh-oh! Some fields are still hungry for information.')
        } else {
          setError('')

          updateAllergiesList({
            variables: {
              username: userName,
              inputs: queryData,
            },
          })
        }
      })
      .catch(() => {
        // Handle form validation errors if needed
      })
  }

  return (
    <>
      {contextHolder}
      {!loading && (
        <>
          <ContentHeader white={false}>
            <PageBreadcrumb path={['Home', 'Select Patient', 'View Patient', 'Edit Allergies']} />
            <TabHeader padding={false}>
              <PageTitle text='Edit Allergies' />
              <Button
                type='primary'
                ghost
                icon={<PlusOutlined />}
                onClick={() => {
                  form.setFieldValue('allergies', [...(form.getFieldValue('allergies') || []), {}])
                }}>
                Add
              </Button>
            </TabHeader>
          </ContentHeader>
          <div className='full_page_form_container'>
            <Form name='allergies_add' autoComplete='off' form={form} component={false}>
              {/* part */}
              {error && <Alert message={error} type='error' showIcon />}
              <PageSubtitle text='Allergies List' padding={false} />
              {/* item */}
              <Form.List name='allergies'>
                {(fields, { remove }) => (
                  <>
                    <Form.Item dependencies={['allergies']} noStyle>
                      {({ getFieldValue }) => {
                        const value = getFieldValue('allergies')
                        if (value && (value as string[]).length === 0)
                          return (
                            <div style={{ width: '100%' }}>
                              <CartEmpty />
                            </div>
                          )
                        return null
                      }}
                    </Form.Item>
                    {fields.map(({ key, name, ...restField }) => (
                      <div className='form_item' key={key}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: isMobile ? 16 : 24,
                          }}>
                          <div
                            className='vertical_flex'
                            style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 40px)' }}>
                            <Text strong>Title</Text>
                            <Text type='secondary'>Provide a descriptive title for the allergy.</Text>
                          </div>
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            rules={[{ required: true, message: 'Please input custom allergy' }]}
                            hasFeedback
                            style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 40px)' }}>
                            <Input placeholder='Title' />
                          </Form.Item>
                          {!isMobile && (
                            <div style={{ flexBasis: '32px', flexShrink: 0 }}>
                              <Button
                                danger
                                ghost
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                  showDeleteConfirm(() => {
                                    remove(name)
                                  })
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: isMobile ? 16 : 24,
                          }}>
                          <div
                            className='vertical_flex'
                            style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 40px)' }}>
                            <Text strong>Allergies</Text>
                            <Text type='secondary'>List any known allergies.</Text>
                          </div>
                          <Form.Item
                            {...restField}
                            name={[name, 'known_allergies']}
                            style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 40px)' }}>
                            <Select
                              mode='multiple'
                              allowClear
                              style={{ width: '100%' }}
                              placeholder='Select Allergies'
                              options={KNOW_ALLERGIES.map((item) => ({
                                label: item,
                                value: item,
                              }))}
                            />
                          </Form.Item>
                          {!isMobile && <div style={{ flexBasis: '32px', flexShrink: 0 }} />}
                        </div>
                        {/* form item dependencies */}
                        <Form.Item {...restField} dependencies={[['allergies', name, 'known_allergies']]} noStyle>
                          {({ getFieldValue }) => {
                            const value = getFieldValue(['allergies', name, 'known_allergies'])
                            if (value && (value as string[]).indexOf('Others') !== -1)
                              return (
                                <div
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: isMobile ? 16 : 24,
                                  }}>
                                  <div
                                    className='vertical_flex'
                                    style={{ gap: '4px', flexBasis: isMobile ? '100%' : 'calc(40% - 40px)' }}>
                                    <Text strong>Other Allergy</Text>
                                    <Text type='secondary'>Please specify the allergy that is not listed.</Text>
                                  </div>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'custom_allergies']}
                                    dependencies={[['allergies', name, 'known_allergies']]}
                                    rules={[
                                      {
                                        required: true,

                                        validator: (_, value) => {
                                          const knownAllergies = form.getFieldValue([
                                            'allergies',
                                            name,
                                            'known_allergies',
                                          ])
                                          if (
                                            knownAllergies &&
                                            knownAllergies.includes('Others') &&
                                            (!value || value.length === 0)
                                          ) {
                                            setError('Uh-oh! Some fields are still hungry for information.')
                                            return Promise.reject()
                                          }
                                          setError('')
                                          return Promise.resolve()
                                        },
                                      },
                                    ]}
                                    hasFeedback
                                    style={{ marginBottom: 0, flexBasis: isMobile ? '100%' : 'calc(60% - 40px)' }}>
                                    <Select
                                      mode='multiple'
                                      allowClear
                                      style={{ width: '100%' }}
                                      placeholder='Please Specify'
                                      options={customAllergies.map((item) => ({
                                        label: item,
                                        value: item,
                                      }))}
                                      dropdownRender={(menu) => (
                                        <>
                                          {menu}
                                          <Divider style={{ margin: '8px 0' }} />
                                          <Space style={{ padding: '0 8px 4px' }}>
                                            <Input
                                              placeholder='Please enter item'
                                              ref={inputRef}
                                              value={aName}
                                              allowClear
                                              onChange={onANameChange}
                                            />
                                            <Button
                                              type='text'
                                              icon={<PlusOutlined />}
                                              onClick={addItem}
                                              disabled={aName === ''}>
                                              Add item
                                            </Button>
                                          </Space>
                                        </>
                                      )}
                                    />
                                  </Form.Item>
                                  {!isMobile && <div style={{ flexBasis: '32px', flexShrink: 0 }} />}
                                </div>
                              )
                            return null
                          }}
                        </Form.Item>
                        {isMobile && (
                          <Button
                            style={{ width: '100%' }}
                            danger
                            ghost
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              showDeleteConfirm(() => {
                                remove(name)
                              })
                            }}>
                            Delete
                          </Button>
                        )}
                        <Divider style={{ margin: 0 }} />
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
              {/* footer */}
              <div className='form_footer_container'>
                <Text strong>Ready to Submit?</Text>
                <Text type='secondary' style={{ marginTop: '4px' }}>
                  {`Review your filled form details and make sure everything is accurate. Once you're ready, click the 'Save' button to update the allergies list.`}
                </Text>
                <Divider style={{ margin: '16px 0' }} />
                <div className='right_flex'>
                  <Button
                    style={{ flexBasis: isMobile ? '100%' : 'content' }}
                    type='primary'
                    onClick={onSave}
                    loading={updateLoading}>
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </>
      )}
    </>
  )
}

export default PanelEditAllergies
