import '../index.css'
import * as React from 'react'
import { useContext } from 'react'
import { Button, Drawer, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { InfoCxt } from '../../../App'
import TableEmpty from '../../../components/Empty/TableEmpty'

const { Text } = Typography

const QUERY_ALLERGIES = gql`
  query ($username: String!) {
    physician {
      queryPatient(username: $username) {
        patientDetail {
          medicalConditions {
            title
            content
          }
        }
      }
    }
  }
`

/* -------------------------------- drawer fc ------------------------------- */

type MedicalDataType = {
  title: string
  content: string
}

type PanelDrawerMedicalProps = {
  isOpen: boolean
  closeDrawer: () => void
}

const PanelDrawerMedical: React.FC<PanelDrawerMedicalProps> = ({ isOpen, closeDrawer }) => {
  const { token: loginToken } = useContext(InfoCxt)
  const { patientId: userName } = useParams()
  const navigate = useNavigate()

  const { data: queryData } = useQuery(QUERY_ALLERGIES, {
    variables: { username: userName },
    context: { headers: { Authorization: loginToken } },
  })

  const info: MedicalDataType[] = queryData?.physician?.queryPatient?.patientDetail?.medicalConditions || []
  // if (queryData) console.log(11, info)

  return (
    <>
      <Drawer
        title='Medical Condition'
        placement='right'
        closable={false}
        styles={{body: { padding: 0 }}}
        onClose={closeDrawer}
        open={isOpen}
        extra={<CloseOutlined onClick={closeDrawer} className='icon_style' />}
        footer={
          <Button
            block
            onClick={() => {
              // console.log(11, 'drawer jump')
              navigate('./edit/medical_conditions')
            }}>
            Edit
          </Button>
        }>
        {info.length ? (
          <>
            <div className='drawer_container'>
              {info.map((one) => {
                return (
                  <div className='draw_body_card' key={JSON.stringify(one)}>
                    <div className='dosage_flex'>
                      <Text strong>{one.title}</Text>
                      <Text>{one.content}</Text>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <div className='empty_v_center_box'>
            <TableEmpty />
          </div>
        )}
      </Drawer>
    </>
  )
}

export default PanelDrawerMedical
