import React from 'react'
import { Spin } from 'antd'

export function Loading() {
  return (
    <div
      style={{
        width: '100%',
        height: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
      }}>
      <Spin tip='Loading' size='large'>
        <div style={{ margin: '50px' }}></div>
      </Spin>
    </div>
  )
}
