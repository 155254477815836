import React, { useState, useContext, useRef } from 'react'
import { Row, Col, Space, Typography, Button, Input, Select, Alert, Modal, Divider, UploadProps, Upload } from 'antd'

import styles from './Settings.module.css'
import './Settings.module.css'
import { FileImageOutlined, InboxOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'

import { InfoCxt } from '../../App'
import { useCookies } from 'react-cookie'
import { RcFile } from 'antd/es/upload'

const { Title, Text } = Typography
const { Dragger } = Upload

const EDIT_PYYSICIAN_PROFILE = gql`
  mutation UpdatePhysicianProfile($profile: PhysicianProfileInputType) {
    profileMutations {
      editPhysicianProfile(profile: $profile) {
        status
        currentUser {
          id
          avatar
          firstName
          physician {
            title
          }
        }
      }
    }
  }
`

const UPDATE_USER_AVATAR = gql`
  mutation UpdateUserAvatar($avatar: String) {
    profileMutations {
      updateUserAvatar(avatar: $avatar) {
        status
        currentUser {
          id
          avatar
        }
      }
    }
  }
`

export const Profile = () => {
  const [cookies] = useCookies(['loginToken'])
  const { firstName, title, avatar, message, modal } = useContext(InfoCxt)

  const firstNameInput = useRef('')
  const prefixInput = useRef('')
  const [error, setError] = useState('')
  const [haveError, setHaveError] = useState(false)
  const [isFirstNameModalOpen, setIsFirstNameModalOpen] = useState(false)
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false)
  const [avatarBase64, setAvatarBase64] = useState('')
  const [avatarError, setAvatarError] = useState('')
  const [fileName, setFileName] = useState('');

  const onFirstNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    firstNameInput.current = e.target.value
  }

  const [editProfile, { loading: firstNameLoading }] = useMutation(EDIT_PYYSICIAN_PROFILE, {
    context: {
      headers: {
        Authorization: cookies.loginToken ? `Bearer ${cookies.loginToken}` : '',
      },
    },
    onCompleted: (data) => {
      console.log(data)
      setIsFirstNameModalOpen(false)
      if (data.profileMutations.editPhysicianProfile.status === true) {
        message?.success('First name successfully updated!')
      } else {
        message?.error('Something went wrong. Please try again.')
      }
    },
    onError: (error) => {
      setIsFirstNameModalOpen(false)
      console.log(error)
    },
  })

  const onFirstNameOk = () => {
    if (!firstNameInput.current || !prefixInput.current) {
      setError('Uh-oh! Some fields are still hungry for information.')
      setHaveError(true)
      return true
    }
    setError('')
    setHaveError(false)
    const requestBundle = {
      profile: {
        firstName: firstNameInput.current,
        title: prefixInput.current,
      },
    }
    editProfile({ variables: requestBundle }).then()
  }

  const onFirstNameCancel = () => {
    setIsFirstNameModalOpen(false)
  }

  const editFirstName = () => {
    setIsFirstNameModalOpen(true)
  }

  const openAvatarModal = () => {
    setIsAvatarModalOpen(true)
  }

  const onAvatarOk = () => {
    if (avatarError || !avatarBase64) {
      return true
    }
    editUserAvatar({ variables: { avatar: avatarBase64 } }).then()
  }

  const onAvatarCancel = () => {
    setIsAvatarModalOpen(false)
  }
  const checkImageDimension = (file: RcFile): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', (event) => {
        const _loadedImageUrl = event.target?.result
        const fileType = file.type
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']
        if (!allowedTypes.includes(fileType)) {
          setAvatarError('Invalid file type. Please upload a valid image file.')
          resolve(false)
          return
        }
        const imageSize = file.size / 1024
        const limitKb = 500
        if (imageSize > limitKb) {
          setAvatarError(`Image size must be smaller than ${limitKb}KB!`)
          resolve(false)
          return
        }
        const image = document.createElement('img')
        image.src = _loadedImageUrl as string

        image.addEventListener('load', () => {
          const { width, height } = image
          if (width > 3000 || height > 3000) {
            setAvatarError('Image size too large, please upload an image smaller than 3000 x 3000 px.')
            resolve(false)
          } else if (width < 500 || height < 500) {
            setAvatarError('Image size too small, please upload an image larger than 500 x 500 px.')
            resolve(false)
          } else {
            setAvatarError('')
            setAvatarBase64(_loadedImageUrl as string)
            resolve(false)
          }
        })
      })
    })
  }

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    beforeUpload: checkImageDimension,
    accept: '.png, .jpg, .jpeg',
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'Download',
      showRemoveIcon: true,
     
    }
  }

  const [editUserAvatar, { loading: editAvatarLoading }] = useMutation(UPDATE_USER_AVATAR, {
    context: {
      headers: {
        Authorization: cookies.loginToken ? `Bearer ${cookies.loginToken}` : '',
      },
    },
    onCompleted: (data) => {
      setIsAvatarModalOpen(false)
      if (data.profileMutations.updateUserAvatar.status) {
        message?.success('Avatar successfully updated!')
      } else {
        message?.error('Something went wrong. Please try again.')
      }
    },
    onError: (error) => {
      setIsAvatarModalOpen(false)
      console.log('editUserAvatar error : ' + JSON.stringify(error))
    },
  })

  const openRemoveAvatarModal = () => {
    modal?.confirm({
      wrapClassName: 'settings-page-modal',
      maskClosable: true,
      title: 'Confirm Removal',
      content: 'Are you sure you want to remove the avatar?',
      okText: 'Confirm',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk: () => {
        editUserAvatar({ variables: { avatar: '' } }).then()
      },
    })
  }

  const buttonStyle = {
    width: '100%',
  }

  return (
    <Space direction='vertical' className={styles.settingsPageTabContent}>
      <Title level={5}>Edit Profile</Title>
      <Text type='secondary'>View and manage your personal profile information.</Text>
      <Row gutter={[16, 16]} align='middle'>
        <Col xs={24} sm={13}>
          <Row>
            <Col xs={24} sm={3}>
              <UserOutlined size={20} style={{ color: '#38C172' }} />
            </Col>
            <Col xs={24} sm={21}>
              <Text strong>First Name</Text>
              <br />
              <Text type='secondary'>Set a first name for your profile.</Text>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={6}>{`${title} ${firstName}`}</Col>
        <Col xs={24} sm={5}>
          <Row justify={'end'}>
            <Col xs={24} sm={10}>
              <Button onClick={editFirstName} style={buttonStyle}>
                Edit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={13}>
          <Row>
            <Col xs={24} sm={3}>
              <FileImageOutlined size={20} style={{ color: '#38C172' }} />
            </Col>
            <Col xs={24} sm={21}>
              <Text strong>Avatar</Text>
              <br />
              <Text type='secondary'>Select an avatar to personalise your account.</Text>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={6}>
          {avatar ? (
            <img src={avatar} alt='User Avatar' style={{ width: '64px', height: '64px', borderRadius: '50%' }} />
          ) : (
            'No Avatar'
          )}
        </Col>
        <Col xs={24} sm={5}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12}>
              <Button onClick={openAvatarModal} style={buttonStyle}>
                Change
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <Button onClick={openRemoveAvatarModal} style={buttonStyle}>
                Remove
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 0, border: 'none' }} />
      {/* use Modal component because we need to dynmaically render alert */}
      <Modal
        title='Edit First Name'
        open={isFirstNameModalOpen}
        footer={[
          <Button key='cancel' onClick={onFirstNameCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={firstNameLoading} onClick={onFirstNameOk}>
            Save
          </Button>,
        ]}
        maskClosable={true}
        closable={false}
        onCancel={onFirstNameCancel}
        className={'settings-page-modal'}>
        <Space direction='vertical' style={{ width: '100%' }}>
          {error && <Alert message={error} type='error' showIcon />}
          <Text>Set a first name for your profile.</Text>
          <Select
            style={{ width: '100%' }}
            placeholder={<Text>Select Prefix</Text>}
            options={[
              { value: 'Dr.', label: 'Dr.' },
              { value: 'Mr.', label: 'Mr.' },
              { value: 'Mrs.', label: 'Mrs.' },
              { value: 'Ms.', label: 'Ms.' },
            ]}
            onChange={(value) => {
              console.log(value)
              prefixInput.current = value
            }}
          />
          <Input
            placeholder='First Name'
            width={'100%'}
            onChange={onFirstNameInputChange}
            className={haveError === true ? styles.inputError : ''}
          />
        </Space>
      </Modal>
      <Modal
        title='Change Avatar'
        open={isAvatarModalOpen}
        onOk={onAvatarOk}
        onCancel={onAvatarCancel}
        okText='Save'
        footer={[
          <Button key='cancel' onClick={onAvatarCancel}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' loading={editAvatarLoading} onClick={onAvatarOk}>
            Save
          </Button>,
        ]}
        maskClosable={true}
        closable={false}
        className={'settings-page-modal'}>
        <Space direction='vertical' style={{ width: '100%' }}>
          {avatarError && <Alert message={avatarError} type='error' showIcon />}
          <Text>Upload new avatar, make sure to keep it at least 500 x 500 px.</Text>
          <Dragger {...uploadProps}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text' style={{ marginBottom: '-15px' }}>
              Click or drag file to this area to upload
            </p>
            <p className='ant-upload-hint'>Support for a single upload.</p>
          </Dragger>
        </Space>
      </Modal>
    </Space>
  )
}
